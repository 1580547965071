import {
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography
} from '@mui/material';
import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { FiChevronDown } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { ProductsListContext } from '../../contexts/ProductsListContext';
import { Filters } from '../../styles';
import { FONT_STYLE, SETTINGS } from '../../variables';
import { useTranslation } from 'react-i18next';

const ProductsFilters = ({ index, filter, activeFilters }) => {
  const { t } = useTranslation();
  const [cookie] = useCookies();
  const translations = useSelector((state) => state.translations);
  const { setPagination } = useContext(ProductsListContext);
  const filterOptionsIsTranslatable = false;
  let { id, title, options } = filter;

  if (SETTINGS.functionalities.translations) {
    //  translate filter title
    title =
      translations.find((translation) => translation.id === id)?.[
        cookie.language
      ] || t(`productList.filters.${id}`);

    //  translate filter options
    //  mutate array » objects array
    //  sort by 'title'
    if (filterOptionsIsTranslatable) {
      options = translations
        .filter((a) => options.some((b) => a.id === b))
        .map((a) => ({ id: a.id, title: a[cookie.language] }))
        .sort((a, b) => a.title.localeCompare(b.title));
    }
  }

  const { subcategorySlug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeOptions = activeFilters[id] || [];

  const getFirstFilterAvailable = index === 0 ? filter.id : null;
  const getActiveFilter = Boolean(
    Object.keys(activeFilters).find((filterName) => filterName === id)
  );

  function handleFilterChange(checked, optionSelected) {
    if (checked) {
      const paramAlreadyExists = searchParams.has(id);
      if (paramAlreadyExists) {
        const oldValue = searchParams.get(id);
        searchParams.set(id, [oldValue].concat(optionSelected));
      } else {
        searchParams.set(id, optionSelected);
      }
    } else {
      const oldValue = searchParams.get(id);
      const oldValueArray = oldValue.split(',');
      const oldValueFiltered = oldValueArray.filter(
        (e) => e !== optionSelected
      );
      if (oldValueFiltered.length > 0) {
        searchParams.set(id, oldValueFiltered);
      } else {
        searchParams.delete(id);
      }
    }

    const pageSearchParam = searchParams.get('page');
    if (pageSearchParam) {
      //  reset pagination number to 1
      setPagination((prevState) => ({
        ...prevState,
        current: 1
      }));
      //  remove 'page' param from 'url search params'
      searchParams.delete('page');
    }

    setSearchParams(searchParams);
  }

  return (
    <Filters defaultExpanded={!!getFirstFilterAvailable || getActiveFilter}>
      <AccordionSummary expandIcon={<FiChevronDown size={20} color='#000' />}>
        <Typography
          sx={{
            fontWeight: FONT_STYLE.semibold
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <FormGroup>
          {options.map(({ id, title, totalResults }, index) => (
            <FormControlLabel
              key={`filter-${id}-${index}`}
              label={`${title} (${totalResults})`}
              title={title}
              control={
                <Checkbox
                  disabled={subcategorySlug && subcategorySlug === id}
                  checked={activeOptions.includes(id)}
                  onChange={(e) => handleFilterChange(e.target.checked, id)}
                />
              }
              sx={{
                marginRight: 0,
                '.MuiCheckbox-root': {
                  paddingTop: 0.75,
                  paddingBottom: 0.75
                },
                '.MuiFormControlLabel-label': {
                  fontSize: '0.9rem',
                  lineHeight: '1.4'
                },
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            />
          ))}
        </FormGroup>
      </AccordionDetails>
    </Filters>
  );
};

export default React.memo(ProductsFilters);

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../constants/utils';
import { BadgeDiscount, BadgeDiscountOutlined } from '../../../styles';
import { THEME_COLORS, FONT_STYLE, SETTINGS } from '../../../variables';

const ProductPrices = ({
  productPrices,
  customerDiscount,
  cumulativeDiscounts
}) => {
  const { t } = useTranslation();
  const { price, onSale, saleAmount, salePrice, pvpPrice } = productPrices;

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: { xs: 3, sm: 5 },
        rowGap: 2,
        marginTop: 3
      }}
    >
      {SETTINGS.defaults.productDetail.customerDiscount &&
        !!customerDiscount &&
        !!cumulativeDiscounts && (
          <Box>
            <Typography
              sx={{
                marginBottom: 0.5,
                fontWeight: FONT_STYLE.semibold
              }}
            >
              {t('product.customerDiscount')}
            </Typography>
            <BadgeDiscountOutlined>{`${customerDiscount}%`}</BadgeDiscountOutlined>
          </Box>
        )}

      {SETTINGS.defaults.productDetail.revPrice && (
        <Box>
          <Typography
            variant='xsmall'
            sx={{
              fontWeight: FONT_STYLE.semibold,
              textTransform: 'uppercase',
              marginBottom: 0.5
            }}
          >
            {t('product.price')}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              gap: 1.5,
              fontSize: '1.3rem'
            }}
          >
            <Typography
              sx={{
                fontSize: 'inherit',
                color: onSale ? THEME_COLORS.darkGray : THEME_COLORS.primary,
                fontWeight: onSale ? FONT_STYLE.regular : FONT_STYLE.bold,
                textDecoration: onSale && 'line-through'
              }}
            >
              {formatCurrency(price)}
            </Typography>

            {onSale && (
              <>
                <Typography
                  sx={{
                    fontSize: 'inherit',
                    fontWeight: FONT_STYLE.bold,
                    color: THEME_COLORS.red
                  }}
                >
                  {formatCurrency(salePrice)}
                </Typography>
                {SETTINGS.defaults.productDetail.badgeDiscount && (
                  <BadgeDiscount>{`-${saleAmount}%`}</BadgeDiscount>
                )}
              </>
            )}
          </Box>
        </Box>
      )}

      {SETTINGS.defaults.productDetail.pvpPrice && (
        <Box>
          <Typography
            variant='xsmall'
            sx={{
              fontWeight: FONT_STYLE.semibold,
              textTransform: 'uppercase',
              marginBottom: 0.5
            }}
          >
            {t('product.pvp')}
          </Typography>

          <Typography
            sx={{
              fontSize: '1.3rem'
            }}
          >
            {formatCurrency(pvpPrice)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ProductPrices;

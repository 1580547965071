import { LoadingButton } from '@mui/lab';
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AuthenticationCodeForm = ({
  authCode,
  setAuthCode,
  handleSubmit,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <TextField
        fullWidth
        label={t('forms.authCode')}
        name='token'
        required
        value={authCode}
        onChange={({ target: { value } }) => setAuthCode(value)}
      />
      <LoadingButton
        variant='contained'
        type='submit'
        title={t('login.loginButton')}
        sx={{
          marginTop: 3,
        }}
        loading={isLoading}
      >
        {t('login.loginButton')}
      </LoadingButton>
    </Box>
  );
};

export default AuthenticationCodeForm;

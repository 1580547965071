import { LoadingButton } from '@mui/lab';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEye, FiEyeOff } from 'react-icons/fi';

const LoginForm = ({ loginData, setLoginData, handleSubmit, isLoading }) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  const handleChange = ({ target: { name, value } }) =>
    setLoginData((values) => ({ ...values, [name]: value }));

  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <TextField
        fullWidth
        label={t('forms.email')}
        type='email'
        name='email'
        required
        value={loginData.email}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        name='password'
        label={t('forms.password')}
        type={showPassword ? 'text' : 'password'}
        required
        value={loginData.password}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <FiEye size={18} /> : <FiEyeOff size={18} />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <LoadingButton
        variant='contained'
        type='submit'
        title={t('login.loginButton')}
        sx={{
          marginTop: 3,
        }}
        loading={isLoading}
      >
        {t('login.loginButton')}
      </LoadingButton>
    </Box>
  );
};

export default LoginForm;

import {
  Box,
  Container,
  Grid,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import {
  FONT_STYLE,
  SETTINGS,
  THEME_COLORS,
  TRANSITION_DURATION
} from '../../variables';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import CustomerSupport from './CustomerSupport';
import FooterMobileAccordion from './FooterMobileAccordion';
import Informations from './Informations';
import SocialMedia from './SocialMedia';

export const FooterTitles = styled(Typography)(({ theme }) => ({
  fontSize: '1.15rem',
  textAlign: { xs: 'center', md: 'initial' },
  marginBottom: theme.spacing(2),
  textTransform: 'uppercase',
  letterSpacing: '0.5px'
}));

export const FooterLink = styled(Typography)(() => ({
  display: 'block',
  color: 'inherit'
}));

const Footer = () => {
  const theme = useTheme();
  const DESKTOP_DEVICES = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      sx={{
        color: 'white',
        backgroundColor: THEME_COLORS.primary,
        borderTop: `4px solid ${THEME_COLORS.secundary}`,
        marginTop: { xs: 4, md: 6 }
      }}
    >
      <Container maxWidth='lg'>
        <Grid
          container
          spacing={{ xs: 0, md: 5 }}
          sx={{
            paddingY: { xs: 4, md: 5 }
          }}
        >
          {DESKTOP_DEVICES ? (
            <>
              {SETTINGS.defaults.footer.informations && <Informations />}
              {SETTINGS.defaults.footer.aboutUs && <AboutUs />}
              {SETTINGS.defaults.footer.socialNetworks && <SocialMedia />}
              {SETTINGS.defaults.footer.customerSupport && <CustomerSupport />}
              {SETTINGS.defaults.footer.contactUs && <ContactUs />}
            </>
          ) : (
            <Grid
              item
              xs={12}
              sm={8}
              sx={{
                margin: '0 auto'
              }}
            >
              {SETTINGS.defaults.footer.socialNetworks && <SocialMedia />}
              <FooterMobileAccordion />
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Box
            sx={{
              width: '100%',
              textAlign: 'center',
              borderTop: '1px solid rgba(255,255,255,0.5)',
              paddingY: 2.5
            }}
          >
            <Typography fontWeight={FONT_STYLE.light}>
              Baccus © 2024&nbsp;.&nbsp;
              <Typography
                component='a'
                href='https://www.bdcadigital.com/plataforma-de-ecommerce-b2b'
                title='Powered by BDCA Digital'
                target='_blank'
                sx={{
                  fontSize: 'inherit',
                  fontWeight: FONT_STYLE.bold,
                  textDecoration: 'none',
                  transition: `opacity ${TRANSITION_DURATION}ms ease`,
                  '&:hover': { opacity: '.75' }
                }}
              >
                B2B Platform by BDCA Digital
              </Typography>
            </Typography>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;

import { createSlice } from '@reduxjs/toolkit';

const productsSlice = createSlice({
  name: 'products',

  initialState: {
    filters: {
      productTypes: [],
    },
    sortBy: 'name:asc',
    products: [],
    availableFilters: [],
  },

  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    setProducts: (state, action) => {
      state.products = action.payload;
    },

    setAvailableFilters: (state, action) => {
      state.availableFilters = action.payload;
    },

    resetFilters: (state, { payload }) => {
      const { filterKeys } = payload;

      const emptyFilterObject = filterKeys.reduce((previousValue, key) => ({ ...previousValue, [key]: [] }), {});

      state.filters = emptyFilterObject;
    },

    addFilter: (state, action) => {
      const { id, type } = action.payload;
      const objectValue = state.filters?.[type] !== undefined ? state.filters?.[type].concat(id) : [id];
      const filterObject = Object.assign(state.filters, { [type]: objectValue });

      state.filters = filterObject;
    },

    removeFilter: (state, action) => {
      const { id, type } = action.payload;
      const filterArray = state.filters[type].filter((_id) => _id !== id);

      state.filters[type] = [...filterArray];
    },

    updateSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
  },
});

export const { setFilters, setProducts, setAvailableFilters, resetFilters, addFilter, removeFilter, updateSortBy } =
  productsSlice.actions;

export default productsSlice.reducer;

import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { imageOnError } from '../../../helpers';
import { FONT_STYLE, THEME_COLORS } from '../../../variables';
import ProductBadge from '../../ProductBadge';

const SummaryProductInfo = ({
  reference,
  eanUn,
  eanCx,
  slug,
  hideImage = false,
  images,
  title,
  showStockBadge = true,
  outOfStock,
  itemsPerBox,
  brand,
  params
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colorVariantTitle = params?.find(
    (param) => param?.id === 'color'
  )?.title;
  const colorVariantValue = params?.find(
    (param) => param?.id === 'color'
  )?.value;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      {!hideImage && (
        <Box
          sx={{
            marginRight: 2,
            display: ' flex',
            alignItems: ' center',
            justifyContent: 'center',
            border: `1px solid transparent`,
            borderRadius: theme.shape.borderRadius / 10,
            overflow: 'hidden',
            flexShrink: 0
          }}
          component={!!slug ? Link : null}
          to={slug}
          title={title}
        >
          <Box
            component='img'
            src={images[0]}
            onError={imageOnError}
            alt={title}
            sx={{
              width: { xs: '60px', md: '100px' },
              objectFit: 'contain',
              aspectRatio: '1'
            }}
          />
        </Box>
      )}

      <Box
        sx={{
          textAlign: 'left'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            marginBottom: 0.5
          }}
        >
          <Typography
            sx={{ fontSize: '0.95rem', fontWeight: FONT_STYLE.bold }}
            component={!!slug ? Link : null}
            to={slug}
            title={title}
          >
            {title}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'grid',
            justifyItems: 'start',
            gap: 0.5,
            marginTop: 0.75
          }}
        >
          {!!reference && (
            <Typography
              variant='xsmall'
              sx={{ color: THEME_COLORS.darkGray }}
            >{`Ref: ${reference}`}</Typography>
          )}

          {!!eanUn && (
            <Typography
              variant='xsmall'
              sx={{ color: THEME_COLORS.darkGray }}
            >{`Ean un: ${eanUn}`}</Typography>
          )}

          {!!eanCx && (
            <Typography
              variant='xsmall'
              sx={{ color: THEME_COLORS.darkGray }}
            >{`Ean cx: ${eanCx}`}</Typography>
          )}

          {!!brand && (
            <Typography
              variant='xsmall'
              sx={{ color: THEME_COLORS.darkGray }}
            >{`${t('product.brand')}: ${brand}`}</Typography>
          )}

          {!!itemsPerBox && (
            <Typography
              variant='xsmall'
              sx={{
                color: THEME_COLORS.darkGray
              }}
            >
              {t('product.packaging')}: <strong>{itemsPerBox}</strong> uni.
            </Typography>
          )}

          {!!colorVariantTitle && !!colorVariantValue && (
            <Typography
              variant='xsmall'
              sx={{ color: THEME_COLORS.darkGray }}
            >{`Cor: ${colorVariantValue}`}</Typography>
          )}

          {showStockBadge && outOfStock && (
            <ProductBadge variant='stock'>
              {t('product.outOfStock')}
            </ProductBadge>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SummaryProductInfo;

import { createSlice } from '@reduxjs/toolkit';

export type TranslationsState = {
  id?: string;
  [key: string]: string;
};

const initialState: TranslationsState[] = [
  {
    id: 'color',
    pt: 'Cor'
  }
];

const translationsSlice = createSlice({
  name: 'translations',

  initialState: initialState,

  reducers: {
    setTranslations: (state, action) => (state = action.payload)
  }
});

export const { setTranslations } = translationsSlice.actions;

export default translationsSlice.reducer;

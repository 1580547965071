import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import Logo from '../assets/company-logo.svg';
import { COMPANY_NAME } from '../variables';

const CompanyLogo = ({ link = true, width = { xs: '100px', md: '200px' } }) => {
  return (
    <Box
      component={link ? Link : undefined}
      to={link ? '/' : undefined}
      title={link ? COMPANY_NAME : undefined}
      sx={{ display: 'flex', width: width }}
    >
      <img src={Logo} alt={COMPANY_NAME} width='100%' />
    </Box>
  );
};

export default CompanyLogo;

import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCountries } from '../../../api/configs';
import { addAddress } from '../../../api/user';
import { PopupNotificationContext } from '../../../contexts/PopupNotificationContext';
import { setAddresses } from '../../../state/userSlice';
import { PAGES_SLUGS, SETTINGS } from '../../../variables';
import Modal, { ModalActions, ModalContent, ModalTitle } from '../../Modal';

const NewAddressModal = ({ isOpen, setIsOpen }) => {
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setNotificationProps } = useContext(PopupNotificationContext);

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [availableCountries, setAvailableCountries] = useState(
    SETTINGS.defaults.countries
  );

  useEffect(() => {
    if (!!SETTINGS.defaults.countries.length) return;
    getCountries().then((data) =>
      setAvailableCountries(data[language].sort((a, b) => a.localeCompare(b)))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = ({ target: { name, value } }) =>
    setFormData((values) => ({ ...values, [name]: value }));

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    addAddress(formData)
      .then((addresses) => {
        setIsLoading(false);
        dispatch(setAddresses(addresses));
        setNotificationProps({
          isOpened: true,
          type: 'success',
          message: t('myAccount.addresses.successMessage2')
        });
      })
      .catch(
        ({
          response: {
            data: { errors },
            status
          }
        }) => {
          setIsLoading(false);
          if (status === 401) {
            navigate(PAGES_SLUGS.login, { replace: true });
          } else if (status > 401) {
            const errorMessage = errors.reduce(
              (prevValue, { msg }) => prevValue + `${msg}. `,
              ''
            );
            setNotificationProps({
              isOpened: true,
              type: 'error',
              message: errorMessage
            });
          }
        }
      );
    setIsOpen(false);
  };

  return (
    <Modal isOpened={isOpen} setIsOpened={() => setIsOpen(false)}>
      <ModalTitle>{t('myAccount.addresses.newAddress')}</ModalTitle>
      <Box component='form' onSubmit={handleSubmit}>
        <ModalContent
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)'
            },
            columnGap: { sm: 2 }
          }}
        >
          <TextField
            fullWidth
            multiline
            required
            type='text'
            name='street'
            label={t('forms.address')}
            onChange={handleChange}
            value={formData.street || ''}
            error={formData.street === ''}
            helperText={formData.street === '' && t('forms.mandatoryField')}
            sx={{
              gridColumn: 'span 2',
              marginTop: 0
            }}
          />
          <TextField
            type='text'
            name='zipCode'
            label={t('forms.postalCode')}
            required
            onChange={handleChange}
            value={formData.zipCode || ''}
            error={formData.zipCode === ''}
            helperText={formData.zipCode === '' && t('forms.mandatoryField')}
          />
          <TextField
            type='text'
            name='city'
            label={t('forms.city')}
            required
            onChange={handleChange}
            value={formData.city || ''}
            error={formData.city === ''}
            helperText={formData.city === '' && t('forms.mandatoryField')}
          />
          <Autocomplete
            id='select-country'
            fullWidth
            disableClearable
            options={availableCountries}
            noOptionsText={t('app.noResults')}
            renderOption={(props, option) => (
              <Box {...props} component='li'>
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                name='country'
                label={t('forms.country')}
                error={formData.country === ''}
                helperText={
                  formData.country === '' && t('forms.mandatoryField')
                }
              />
            )}
            onChange={(event, selectedOption) =>
              setFormData((values) => ({
                ...values,
                country: selectedOption || ''
              }))
            }
          />
        </ModalContent>
        <ModalActions>
          <LoadingButton
            variant='contained'
            type='submit'
            title={t('myAccount.addresses.addAddress')}
            loading={isLoading}
            disabled={
              !formData.street ||
              !formData.zipCode ||
              !formData.city ||
              !formData.country
                ? true
                : false
            }
          >
            {t('myAccount.addresses.addAddress')}
          </LoadingButton>
        </ModalActions>
      </Box>
    </Modal>
  );
};

export default NewAddressModal;

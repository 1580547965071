import { Box, Paper, Tab, Tabs, useMediaQuery } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FONT_STYLE, SETTINGS, THEME_COLORS } from '../../../variables';
import TechnicalDetails, { TechnicalDetail } from './TechnicalDetails';
import TechnicalSheets from './TechnicalSheets';
import VideoCard from './VideoCard';

type TabsList = {
  id: string;
  active: boolean;
  title: string;
};

const ProductTabs = ({
  video,
  technicalDetails,
  technicalSheets
}: {
  video: string;
  technicalDetails: TechnicalDetail[];
  datasheet: string;
  technicalSheets: string[];
}) => {
  const { t } = useTranslation();
  const theme: Theme = useTheme();
  const XSMALL_DEVICES = useMediaQuery(theme.breakpoints.down('sm'));

  const [activeTab, setActiveTab] = useState<number>(0);
  const tabsList: TabsList[] = SETTINGS.defaults.productDetail.tabs.list.filter(
    (tab) => tab.active
  );

  return (
    <Box marginTop={{ xs: 5, md: 8 }}>
      <Tabs
        value={activeTab}
        textColor='secondary'
        variant={XSMALL_DEVICES && tabsList.length > 2 ? 'scrollable' : null}
        scrollButtons={XSMALL_DEVICES && tabsList.length > 2 && true}
        allowScrollButtonsMobile
      >
        {tabsList.map(({ id, title }, index) => (
          <Tab
            key={`productTab-${id}`}
            value={index}
            label={t(title)}
            title={t(title)}
            onClick={() => setActiveTab(index)}
            sx={{
              textTransform: 'none',
              marginRight: 0.5,
              backgroundColor:
                activeTab === index ? THEME_COLORS.primary : '#F6F8FB',
              fontWeight: FONT_STYLE.semibold,
              borderTopLeftRadius: theme.shape.borderRadius,
              borderTopRightRadius: theme.shape.borderRadius,
              letterSpacing: '0.25px'
            }}
          />
        ))}
      </Tabs>
      <Paper
        sx={{
          borderTopLeftRadius: 0,
          padding: 2
        }}
      >
        {
          {
            0: <TechnicalDetails data={technicalDetails} />,
            1: <TechnicalSheets data={technicalSheets} />,
            2: <VideoCard video={video} />
          }[activeTab]
        }
      </Paper>
    </Box>
  );
};

export default ProductTabs;

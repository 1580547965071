import { LoadingButton } from '@mui/lab';
import { Box, Button, FormControl, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { checkDeliveryCost, confirmOrder } from '../api/checkout';
import CheckoutResume from '../components/Checkout/CheckoutResume';
import Payment from '../components/Checkout/Payment/Payment';
import Shipping from '../components/Checkout/Shipping/Shipping';
import { priceCalculations } from '../constants/utils';
import { PopupNotificationContext } from '../contexts/PopupNotificationContext';
import { COMPANY_INFO, PAGES_SLUGS, SETTINGS } from '../variables';

const Checkout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setNotificationProps } = useContext(PopupNotificationContext);
  const [cart, user, customerId] = useOutletContext();

  const isLimitedCustomer = Boolean(user.role === 'limitedcustomer');
  const clientAddresses = user.selectedClient.addresses || user.addresses;
  const billingAddress = {
    street: user.companyInfo?.street,
    zipCode: user.companyInfo?.zipCode,
    city: user.companyInfo?.city,
    country: user.companyInfo?.country
  };
  const storeAddress = {
    street: COMPANY_INFO.address.street,
    zipCode: COMPANY_INFO.address.zipCode,
    city: COMPANY_INFO.address.city,
    country: COMPANY_INFO.address.country
  };
  const defaultPaymentMethod =
    SETTINGS.defaults.cart.checkout.paymentMethods.find(
      (method) => method.default
    ).value;
  const shippingMethods = SETTINGS.defaults.cart.checkout.shippingMethods;
  const defaultShippingMethod = Object.entries(shippingMethods).find(
    ([_key, value]) => value?.default
  )[0];

  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState('');
  const [orderDetails, setOrderDetails] = useState({
    paymentType: defaultPaymentMethod,
    paymentTime:
      user.selectedClient.payment_deadlines || user.sellerInfo.paymentTime,
    shipping: defaultShippingMethod,
    shipping_price: 0,
    deliveryAtAddress: '',
    deliveryAtStore: '',
    deliveryDate: '',
    notes: '',
    subtotal: cart.subtotal,
    total: 0,
    total_discount: cart.discount,
    cart: cart.products,
    cart_id: cart.id
  });

  //  IF user is blocked
  useEffect(() => {
    if (user.blockedAccount) navigate(PAGES_SLUGS.cart, { replace: true });
  }, [user.blockedAccount]);

  useEffect(() => {
    let deliveryAddress = {};

    switch (orderDetails.shipping) {
      case 'deliveryAtAddress':
        deliveryAddress = {
          deliveryAtAddress: clientAddresses[0]
        };
        break;
      case 'billingAddress':
        deliveryAddress = {
          deliveryAtAddress: billingAddress
        };
        break;
      case 'deliveryAtStore':
        deliveryAddress = {
          deliveryAtStore: storeAddress
        };
        break;
      default:
        break;
    }

    setOrderDetails((values) => ({
      ...values,
      ...deliveryAddress
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails.shipping, clientAddresses, customerId]);

  useEffect(() => {
    //  IF shipping method has 'shippingCosts = true'
    if (
      shippingMethods[orderDetails.shipping].shippingCosts &&
      orderDetails.deliveryAtAddress.country &&
      orderDetails.deliveryAtAddress.zipCode
    ) {
      //  calculate shipping costs
      checkDeliveryCost(
        orderDetails.deliveryAtAddress.country,
        orderDetails.deliveryAtAddress.zipCode,
        customerId
      )
        .then(({ deliveryCost }) => {
          setOrderDetails((values) => ({
            ...values,
            shipping_price: deliveryCost
          }));
        })
        .catch(({ response: { data, status } }) => {
          if (status === 401) navigate(PAGES_SLUGS.login, { replace: true });
          setNotificationProps({
            isOpened: true,
            type: 'error',
            message: data?.msg
          });
        });
    } else {
      setOrderDetails((values) => ({
        ...values,
        shipping_price: 0
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderDetails.shipping,
    orderDetails.deliveryAtAddress,
    orderDetails.deliveryAtStore
  ]);

  useEffect(() => {
    setOrderDetails((values) => ({
      ...values,
      notes: `Delivery date: ${values.deliveryDate} | Obs: ${notes}`
    }));
  }, [orderDetails.deliveryDate, notes]);

  //  total + shipping calculations
  useEffect(() => {
    //  when 'shipping price' is '-1' means shipping price is 'on request'
    const shippingPrice =
      orderDetails.shipping_price !== -1 ? orderDetails.shipping_price : 0;
    setOrderDetails((values) => ({
      ...values,
      total: priceCalculations(cart.total + shippingPrice)
    }));
  }, [cart.total, orderDetails.shipping_price]);

  const handleOrderConfirmation = (e) => {
    e.preventDefault();

    setIsLoading(true);

    confirmOrder(orderDetails)
      .then(() => {
        setIsLoading(false);
        window.localStorage.setItem('orderConfirmed', true);
        navigate(PAGES_SLUGS.orderConfirmation);
      })
      .catch(({ response: { data, status } }) => {
        if (status === 401) {
          navigate(PAGES_SLUGS.login, { replace: true });
        } else if (status === 400 || status > 401) {
          setIsLoading(false);
          setNotificationProps({
            isOpened: true,
            type: 'error',
            message: data?.msg?.message || data?.msg
          });
        }
      });
  };

  return (
    <Grid xs={12}>
      <Typography
        variant='h2'
        sx={{
          textAlign: 'center',
          marginBottom: { xs: 3, md: 4 }
        }}
      >
        {t('cart.steps.checkout')}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'initial' },
          alignItems: { sm: 'center', md: 'flex-start' },
          gap: { xs: 4, md: 6 }
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', sm: '80%', md: 'auto' },
            flex: 1,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <FormControl>
            {SETTINGS.defaults.cart.checkout.payments && !isLimitedCustomer && (
              <Payment
                orderDetails={orderDetails}
                setOrderDetails={setOrderDetails}
                setNotes={setNotes}
              />
            )}

            <Shipping
              role={user.role}
              clientAddresses={clientAddresses}
              orderDetails={orderDetails}
              setOrderDetails={setOrderDetails}
            />
          </FormControl>
        </Box>

        <CheckoutResume
          cart={cart}
          total={orderDetails.total}
          deliveryCost={orderDetails.shipping_price}
          isLimitedCustomer={isLimitedCustomer}
        />
      </Box>

      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: { xs: 'center', md: 'flex-end' },
          flexWrap: 'wrap',
          gap: 2
        }}
      >
        <Button
          variant='outlined'
          component={Link}
          to={PAGES_SLUGS.cart}
          title={t('checkout.goBackButton')}
        >
          {t('checkout.goBackButton')}
        </Button>

        <LoadingButton
          variant='contained'
          title={t('checkout.finalizeOrder')}
          loading={isLoading}
          onClick={handleOrderConfirmation}
          disabled={!!!orderDetails.deliveryDate}
          sx={{
            minWidth: '200px'
          }}
        >
          {t('checkout.finalizeOrder')}
        </LoadingButton>
      </Box>
    </Grid>
  );
};

export default Checkout;

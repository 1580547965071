import { Box, Button, Chip, Stack, Typography, useTheme } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { VscClose } from 'react-icons/vsc';
import { useSearchParams } from 'react-router-dom';
import { ProductsListContext } from '../../contexts/ProductsListContext';
import { FONT_STYLE, THEME_COLORS } from '../../variables';
import ProductsFilters from './ProductsFilters';

const ProductsListFilters = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { filters } = useContext(ProductsListContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsEntries = Object.entries(
    Object.fromEntries(
      [...searchParams].filter(
        //  remove 'query & page & sortBy' params
        (filter) =>
          filter[0] !== 'page' &&
          filter[0] !== 'query' &&
          filter[0] !== 'sortBy'
      )
    )
  );

  const deleteAllSearchParams = () => {
    const exceptionParams =
      searchParams.get('query') || searchParams.get('sortBy');

    if (!!exceptionParams) {
      setSearchParams(
        Object.fromEntries(
          [...searchParams].filter(
            //  remove all params except 'query || sortBy'
            (filter) => filter[0] === 'query' || filter[0] === 'sortBy'
          )
        )
      );
    } else {
      setSearchParams([]);
    }
  };

  const deleteSearchParam = (key, value) => {
    const arrayParams = searchParams.get(key).split(',');

    //  IF exists one param in this key » remove this key
    if (arrayParams.length === 1) {
      searchParams.delete(key);
    } else {
      //  ELSE only remove clicked value
      searchParams.set(
        key,
        arrayParams.filter((param) => param !== value)
      );
    }

    setSearchParams(searchParams);
  };

  return (
    <>
      {!!searchParamsEntries.length && (
        <Box
          sx={{
            backgroundColor: THEME_COLORS.lightGray,
            borderRadius: theme.shape.borderRadius / 10,
            padding: 2,
            marginBottom: 2
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              marginBottom: 1.5
            }}
          >
            <Typography variant='small' fontWeight={FONT_STYLE.bold}>
              {t('productList.activeFilters')}
            </Typography>
            <Button
              variant='link'
              title={t('productList.deleteAll')}
              onClick={() => deleteAllSearchParams()}
            >
              {t('productList.deleteAll')}
            </Button>
          </Box>
          <Stack>
            {searchParamsEntries.map(([key, value]) =>
              value
                .split(',')
                .map((paramValue) => (
                  <Chip
                    key={`activeSearchParam-${key}-${paramValue}`}
                    label={
                      filters.list
                        .find((filter) => filter.id === key)
                        ?.options.find((option) => option.id === paramValue)
                        .title
                    }
                    variant='outlined'
                    deleteIcon={<VscClose />}
                    onDelete={() => deleteSearchParam(key, paramValue)}
                  />
                ))
            )}
          </Stack>
        </Box>
      )}
      {filters.list.map((filter, index) => (
        <ProductsFilters
          key={`productsFilter-${filter.id}`}
          index={index}
          filter={filter}
          activeFilters={filters.active}
        />
      ))}
    </>
  );
};

export default ProductsListFilters;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  shoppingLists: [],
};

const shoppingListsSlice = createSlice({
  name: 'shoppingLists',

  initialState: initialState,

  reducers: {
    setShoppingLists: (state, action) => {
      state.shoppingLists = action.payload;
    },
    resetShoppingListsState: () => initialState,
  },
});

export const { setShoppingLists, resetShoppingListsState } =
  shoppingListsSlice.actions;

export default shoppingListsSlice.reducer;

import { Box, Grid, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PiEnvelopeSimpleLight, PiMapPin, PiPhone } from 'react-icons/pi';
import { COMPANY_INFO } from '../../variables';
import { FooterTitles } from './Footer';

export const ContactUsContent = styled(Typography)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '& svg': {
    flexShrink: 0
  }
}));

export const FooterContactsContent = () => {
  const { t } = useTranslation();
  const {
    address: { street, zipCode, city, country },
    mobile,
    phone,
    fax,
    email
  } = COMPANY_INFO;
  return (
    <Box
      sx={{
        display: 'grid',
        gap: 1.75
      }}
    >
      {phone.value && (
        <Box>
          <ContactUsContent
            component={'a'}
            href={`tel:${phone.value}`}
            title={phone.label}
          >
            <PiPhone size={18} />
            {phone.label}
          </ContactUsContent>
          <Typography variant='xsmall'>
            {t('app.nationalMobileNetwork')}
          </Typography>
        </Box>
      )}

      {mobile.value && (
        <Box>
          <ContactUsContent
            component={'a'}
            href={`tel:${mobile.value}`}
            title={mobile.label}
          >
            <strong>Tel. </strong>
            {mobile.label}
          </ContactUsContent>
        </Box>
      )}

      {fax.value && (
        <ContactUsContent>
          <strong>Fax. </strong>
          {fax.label}
        </ContactUsContent>
      )}

      {!!email.length && (
        <ContactUsContent component='div'>
          <PiEnvelopeSimpleLight size={18} />
          <Box
            sx={{
              display: 'grid',
              gap: 0.5
            }}
          >
            {email.map((mail) => (
              <Box
                key={`contactEmail-${mail}`}
                component={'a'}
                href={`mailto:${mail}`}
                title={mail}
              >
                {mail}
              </Box>
            ))}
          </Box>
        </ContactUsContent>
      )}

      <ContactUsContent>
        <PiMapPin size={18} />
        <span>
          {street} <br /> {zipCode}, {city}
          <br />
          {country}
        </span>
      </ContactUsContent>
    </Box>
  );
};

const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <Grid item xs={3}>
      <FooterTitles variant='h4'>{t('app.customerSupport')}</FooterTitles>
      <FooterContactsContent />
    </Grid>
  );
};

export default ContactUs;

import { Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Box } from '@mui/system';
import { Trans } from 'react-i18next';
import { THEME_COLORS } from '../../variables';

const NotificationBar = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: THEME_COLORS.gray
      }}
    >
      <Container>
        <Grid container>
          <Grid xs={12}>
            <Box
              sx={{
                paddingY: 1.25,
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center'
              }}
            >
              <Typography
                variant='small'
                sx={{
                  fontSize: { xs: '0.7rem', md: '0.85rem' }
                }}
              >
                <Trans i18nKey='notificationsBar.option1' />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default NotificationBar;

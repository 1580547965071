import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addProductsToCart } from '../../../api/checkout';
import { MyAccountTitle } from '../../../routes/AccountPage';
import { updateCart } from '../../../state/cartSlice';
import { PAGES_SLUGS } from '../../../variables';
import CreateShoppingListModal from './CreateShoppingListModal';
import DeleteShoppingListModal from './DeleteShoppingListModal';
import ShoppingListProducts from './ShoppingListProducts';

const ShoppingLists = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const { id, selectedClient, role } = user;
  const customerId = selectedClient?.id || id;
  const isLimitedCustomer = role === 'limitedcustomer';
  const shoppingLists = useSelector(
    (state) => state.shoppingLists.shoppingLists
  );

  const [index, setIndex] = useState(0);
  const [isAddShoppingListOpen, setIsAddShoppingListOpen] = useState(false);
  const [isDeleteShoppingListOpen, setIsDeleteShoppingListOpen] =
    useState(false);

  const addShoppingListProductsToCart = () => {
    addProductsToCart(shoppingLists?.[index]?.products, customerId)
      .then((cart) => {
        dispatch(updateCart(cart));
      })
      .catch(
        ({
          response: {
            status,
            data: { msg },
          },
        }) => {
          if (status === 401) {
            navigate(PAGES_SLUGS.login, { replace: true });
          } else if (status === 400 || status > 401) {
            console.error(
              `Error while adding product to cart at ProductListCard: ${msg}`
            );
          }
        }
      );
  };

  return (
    <Box
      sx={{
        flex: 3,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box marginBottom={4}>
        <MyAccountTitle>{t('myAccount.shoppingLists.title')}</MyAccountTitle>
        <Typography variant='small'>
          {t('myAccount.shoppingLists.text')}
        </Typography>
      </Box>

      {shoppingLists.length !== 0 ? (
        <Box>
          <FormControl
            sx={{
              width: { xs: '100%', md: '30%' },
            }}
          >
            <InputLabel>{t('myAccount.shoppingLists.selectedList')}</InputLabel>
            <Select
              label={t('myAccount.shoppingLists.selectedList')}
              value={index}
              onChange={(e) => setIndex(e.target.value)}
            >
              {shoppingLists.map(({ name, _id }, index) => (
                <MenuItem
                  key={`shopping-list-${_id}`}
                  value={index}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <ShoppingListProducts
            index={index}
            shoppingLists={shoppingLists}
          />
        </Box>
      ) : (
        <Typography>{t('myAccount.shoppingLists.noListsCreated')}</Typography>
      )}

      <Box
        sx={{
          display: 'flex',
          gap: { xs: 1.5, sm: 2 },
          marginTop: 2.5,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'center', sm: 'flex-start' },
        }}
      >
        {shoppingLists.length !== 0 && (
          <Button
            variant='outlined'
            title={t('myAccount.shoppingLists.removeShoppingList')}
            startIcon={<FiTrash2 size={14} />}
            onClick={() => setIsDeleteShoppingListOpen(true)}
          >
            {t('myAccount.shoppingLists.removeShoppingList')}
          </Button>
        )}

        <Button
          variant='contained'
          title={t('myAccount.shoppingLists.addShoppingList')}
          startIcon={<FiPlus size={14} />}
          onClick={() => setIsAddShoppingListOpen(true)}
        >
          {t('myAccount.shoppingLists.addShoppingList')}
        </Button>

        {shoppingLists.length !== 0 &&
          shoppingLists?.[index]?.products.length !== 0 && (
            <Box
              sx={{
                marginLeft: { sm: 'auto' },
              }}
            >
              <Button
                variant='contained'
                title={t('myAccount.shoppingLists.addProductsToCart')}
                disabled
                onClick={() => addShoppingListProductsToCart()}
                sx={{
                  display: 'none',
                }}
              >
                {t('myAccount.shoppingLists.addProductsToCart')}
              </Button>
              {!isLimitedCustomer && (
                <Typography
                  variant='xsmall'
                  sx={{
                    textAlign: { sm: 'right' },
                  }}
                >
                  {t('product.priceWithoutVat')}
                </Typography>
              )}
            </Box>
          )}
      </Box>

      {isAddShoppingListOpen && (
        <CreateShoppingListModal
          isOpen={isAddShoppingListOpen}
          setIsOpen={setIsAddShoppingListOpen}
        />
      )}

      {isDeleteShoppingListOpen && (
        <DeleteShoppingListModal
          isOpen={isDeleteShoppingListOpen}
          setIsOpen={setIsDeleteShoppingListOpen}
          setIndex={setIndex}
          shoppingLists={shoppingLists}
        />
      )}
    </Box>
  );
};

export default ShoppingLists;

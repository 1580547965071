import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
// import capitalize from 'lodash/capitalize';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getSearchedProducts } from '../api/products';
import ProductsList from '../components/ProductsList/ProductsList';
import ProductsListFilters from '../components/ProductsList/ProductsListFilters';
import ProductsListTitle from '../components/ProductsList/ProductsListTitle';
import ProductsListToolbar from '../components/ProductsList/ProductsListToolbar';
import { ProductsListContext } from '../contexts/ProductsListContext';
import { MainContainer } from '../styles';
import { PAGES_SLUGS } from '../variables';

const AllProducts = () => {
  const { t } = useTranslation();
  const {
    products,
    setProducts,
    filters,
    setFilters,
    pagination,
    setPagination,
    setIsLoading,
    getProductsTrigger,
    setGetProductsTrigger
  } = useContext(ProductsListContext);

  const theme = useTheme();
  const MOBILE_DEVICES = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const searchInput = searchParams.get('query');

  const [filtersIsAvailable, setFiltersIsAvailable] = useState(
    !!filters.list.length
  );

  useEffect(() => {
    //  reset product list
    setProducts({
      list: [],
      total: null
    });

    if (!getProductsTrigger) return;
    setIsLoading(true);

    getSearchedProducts({
      searchParams: filters.active
    })
      .then(({ products, displayedFilters, productsTotal }) => {
        //  set products & products total
        setProducts({
          list: products,
          total: productsTotal
        });

        //  IF there are filters available
        if (!!displayedFilters.length) {
          //  remove filters without options » sidebar filters
          //	remove 'collection' » sidebar filters
          //	IF 'categories' is present at url remove it » sidebar filters
          const filtersFiltered = displayedFilters.filter(
            (filter) => filter.options && filter.options.length !== 0
          );

          //  set filters
          setFilters((prevState) => ({
            ...prevState,
            list: filtersFiltered
          }));

          setFiltersIsAvailable(!!filtersFiltered.length);
        }

        //  rounds a number UP to the nearest integer
        setPagination((prevState) => ({
          ...prevState,
          total: Math.ceil(productsTotal / pagination.itemsPerPage)
        }));

        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 404) navigate(PAGES_SLUGS.notFound, { replace: true });
      });

    setGetProductsTrigger(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProductsTrigger]);

  return (
    <MainContainer>
      <Container>
        <Grid container>
          {!MOBILE_DEVICES && (
            <Grid md={3}>{filtersIsAvailable && <ProductsListFilters />}</Grid>
          )}
          <Grid
            md={filtersIsAvailable ? 8.5 : 12}
            mdOffset={filtersIsAvailable ? 0.5 : 0}
          >
            <Box sx={{ flex: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  marginBottom: { md: 3 }
                }}
              >
                <ProductsListTitle
                  title={t('search.searchResults', {
                    count: products.total,
                    term: searchInput
                  })}
                  productsTotal={products.total}
                />
                <ProductsListToolbar />
              </Box>
              <ProductsList />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default AllProducts;

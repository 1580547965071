import { Box, Grid, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import { FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { ImFacebook } from 'react-icons/im';
import { COMPANY_INFO } from '../../variables';
import { FooterTitles } from './Footer';

const SocialMedia = () => {
  const { t } = useTranslation();
  return (
    <Grid
      item
      xs={10}
      md={3}
      sx={{
        margin: { xs: '0 auto', md: '0' },
        textAlign: { xs: 'center', md: 'left' }
      }}
    >
      <FooterTitles variant='h4'>{t('app.socialNetworks')}</FooterTitles>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: { xs: 'center', md: 'initial' }
        }}
      >
        <IconContext.Provider value={{ style: { color: 'white' } }}>
          {COMPANY_INFO.social.facebook && (
            <Link
              href={COMPANY_INFO.social.facebook}
              target='_blank'
              title='Facebook'
              rel='noreferrer'
              underline='none'
              sx={{
                display: 'flex',
                marginLeft: { xs: 0.75, md: 0 },
                marginRight: 0.75,
                '&:hover': {
                  opacity: 0.75
                }
              }}
            >
              <ImFacebook size={18} />
            </Link>
          )}

          {COMPANY_INFO.social.instagram && (
            <Link
              href={COMPANY_INFO.social.instagram}
              target='_blank'
              title='Instagram'
              rel='noreferrer'
              underline='none'
              sx={{
                display: 'flex',
                marginX: 0.75,
                '&:hover': {
                  opacity: 0.75
                }
              }}
            >
              <FaInstagram size={20} />
            </Link>
          )}

          {COMPANY_INFO.social.linkedin && (
            <Link
              href={COMPANY_INFO.social.linkedin}
              target='_blank'
              title='Linkedin'
              rel='noreferrer'
              underline='none'
              sx={{
                display: 'flex',
                marginX: 0.75,
                '&:hover': {
                  opacity: 0.75
                }
              }}
            >
              <FaLinkedinIn size={20} />
            </Link>
          )}
        </IconContext.Provider>
      </Box>
    </Grid>
  );
};

export default SocialMedia;

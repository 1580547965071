import { Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ShoppingListSelectorModal from './ShoppingListSelectorModal';
import { TbShoppingBagPlus } from 'react-icons/tb';

const AddShoppingListButton = ({ productId }) => {
  const { t } = useTranslation();
  const [modalIsOpened, setModalIsOpened] = useState(false);
  const shoppingLists = useSelector(
    (state) => state.shoppingLists.shoppingLists
  );

  return (
    <>
      <Button
        variant='outlined'
        title={t('myAccount.shoppingLists.addToShoppingList')}
        onClick={() => setModalIsOpened(true)}
        startIcon={<TbShoppingBagPlus size={18} />}
      >
        {t('myAccount.shoppingLists.addToShoppingList')}
      </Button>

      {modalIsOpened && (
        <ShoppingListSelectorModal
          isOpen={modalIsOpened}
          setIsOpen={setModalIsOpened}
          shoppingLists={shoppingLists}
          productId={productId}
        />
      )}
    </>
  );
};

export default AddShoppingListButton;

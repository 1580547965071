import { Box, Pagination, PaginationItem, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { ProductCardProvider } from '../../contexts/ProductCardContext';
import { ProductsListContext } from '../../contexts/ProductsListContext';
import { TRANSITION_DURATION } from '../../variables';
import Loading from '../Loading';
import ProductCard from '../ProductCard/ProductCard';
import ProductListCard from '../ProductListCard/ProductListCard';

const ProductsList = () => {
  const { t } = useTranslation();
  const { products, pagination, layout, isLoading } =
    useContext(ProductsListContext);

  const [cookies] = useCookies(['userToken']);
  const { priceIsVisible, role } = useSelector((state) => state.user);
  const userIsLogged = !!cookies.userToken && role !== 'limitedcustomer';

  const [searchParams] = useSearchParams();
  // const searchPage = searchParams.get('query');

  if (products.total === null && isLoading) {
    return <Loading />;
  }

  return (
    <Box
      sx={{
        opacity: products.total !== null && isLoading ? '0.5' : null,
        transition: `opacity ${TRANSITION_DURATION}ms ease`
      }}
    >
      {products.total !== null &&
        (!!products.total ? (
          <>
            <ProductCardProvider>
              {layout === 'grid' ? (
                <Grid
                  container
                  spacing={3}
                  sx={{
                    padding: 0
                  }}
                >
                  {products.list.map((product, index) => (
                    <Grid
                      key={`product-card-${product.reference}-${index}`}
                      xs={12}
                      sm={6}
                      md={4}
                    >
                      <ProductCard
                        userIsLogged={userIsLogged}
                        showRevPrice={priceIsVisible}
                        product={product}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid container spacing={0}>
                  {products.list.map((product, index) => (
                    <Grid
                      key={`product-listcard-${product.reference}-${index}`}
                      xs={12}
                      sm={8}
                      smOffset={2}
                      md={12}
                      mdOffset={0}
                    >
                      <ProductListCard
                        userIsLogged={userIsLogged}
                        showRevPrice={priceIsVisible}
                        product={product}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </ProductCardProvider>

            {pagination.total > 1 && (
              <Pagination
                count={pagination.total}
                page={pagination.current}
                onChange={pagination.onChange}
                renderItem={(item) => {
                  item.page === 1
                    ? searchParams.delete('page')
                    : searchParams.set('page', item.page);
                  return (
                    <PaginationItem
                      component={Link}
                      to={{
                        pathname: '.',
                        search: searchParams.toString()
                      }}
                      {...item}
                    />
                  );
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: { xs: 3, md: 5 }
                }}
              />
            )}
          </>
        ) : (
          <Typography>{t('productList.noProductsFound')}</Typography>
        ))}
    </Box>
  );
};

export default ProductsList;

import {
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  Icon,
  Switch,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { FiGrid, FiList } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { ProductsListContext } from '../../contexts/ProductsListContext';
import { setPriceVisibility } from '../../state/userSlice';
import { SETTINGS, THEME_COLORS, TRANSITION_DURATION } from '../../variables';
import ProductsFiltersModal from './ProductsFiltersModal';
import ProductsSortBy from './ProductsSortBy';

const SetLayoutButton = styled(Icon)(({ active }) => ({
  backgroundColor: active === 'true' && THEME_COLORS.gray,
  borderRadius: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '32px',
  cursor: 'pointer',
  transition: `background-color ${TRANSITION_DURATION}ms ease`,
  '&:hover': {
    backgroundColor: THEME_COLORS.gray
  }
}));

const ProductsListToolbar = () => {
  const { t } = useTranslation();
  const { layout, setLayout, products } = useContext(ProductsListContext);

  const [cookies] = useCookies(['userToken']);
  const dispatch = useDispatch();

  const theme = useTheme();
  const TABLET_DEVICES = useMediaQuery(theme.breakpoints.down('lg'));

  const { priceIsVisible, role } = useSelector((state) => state.user);

  return (
    <>
      {!TABLET_DEVICES ? (
        <>
          {SETTINGS.functionalities.hidePrices &&
            !!cookies.userToken &&
            role !== 'limitedcustomer' && (
              <>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={!priceIsVisible} />}
                    title={t('productList.hidePrices')}
                    label={t('productList.hidePrices')}
                    slotProps={{ typography: { variant: 'small' } }}
                    onChange={() =>
                      dispatch(setPriceVisibility(!priceIsVisible))
                    }
                    sx={{ marginRight: 0 }}
                  />
                </FormGroup>
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{
                    borderColor: THEME_COLORS.gray,
                    marginX: 2
                  }}
                />
              </>
            )}

          {!!products.total && (
            <ProductsSortBy
              options={SETTINGS.defaults.productList.sortOptions}
            />
          )}

          {SETTINGS.functionalities.productListViews && (
            <>
              <Divider
                orientation='vertical'
                flexItem
                sx={{
                  borderColor: THEME_COLORS.gray,
                  marginLeft: 1,
                  marginRight: 2
                }}
              />

              <Box
                sx={{
                  display: 'flex',
                  gap: 1
                }}
              >
                <SetLayoutButton
                  title={t('productList.gridView')}
                  active={layout === 'grid' ? 'true' : 'false'}
                  onClick={() => setLayout('grid')}
                >
                  <FiGrid size={16} />
                </SetLayoutButton>

                <SetLayoutButton
                  title={t('productList.listView')}
                  active={layout === 'list' ? 'true' : 'false'}
                  onClick={() => setLayout('list')}
                >
                  <FiList size={16} />
                </SetLayoutButton>
              </Box>
            </>
          )}
        </>
      ) : (
        <Box
          sx={{
            width: { xs: '100%', md: 'initial' },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 3,
            marginTop: { xs: 2, md: 0 }
          }}
        >
          {!!products.total && (
            <>
              <ProductsFiltersModal />
              <ProductsSortBy
                options={SETTINGS.defaults.productList.sortOptions}
              />
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default ProductsListToolbar;

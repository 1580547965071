import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { getCountries } from '../../api/configs';
import { registerUser } from '../../api/login';
import { PopupNotificationContext } from '../../contexts/PopupNotificationContext';
import { PAGES_SLUGS, SETTINGS } from '../../variables';
import RegisterSuccess from './RegisterSuccess';

const UserRegisterForm = () => {
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const { setNotificationProps } = useContext(PopupNotificationContext);

  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formData, setFormData] = useState({});
  const [availableCountries, setAvailableCountries] = useState(
    SETTINGS.defaults.countries
  );
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] =
    useState(false);

  useEffect(() => {
    if (!!SETTINGS.defaults.countries.length) return;
    getCountries().then((data) =>
      setAvailableCountries(data[language].sort((a, b) => a.localeCompare(b)))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = ({ target: { name, value } }) =>
    setFormData((values) => ({ ...values, [name]: value }));

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    registerUser(formData)
      .then(() => {
        setIsLoading(false);
        setShowConfirmation(true);
        setNotificationProps({
          isOpened: true,
          type: 'success',
          message: t('newRegister.successMessage2')
        });
      })
      .catch(({ response: { status, data } }) => {
        const errorMessage = data?.msg || data?.errors?.[0]?.msg;
        if (status === 400 || status > 401) {
          setIsLoading(false);
          setNotificationProps({
            isOpened: true,
            type: 'error',
            message: errorMessage
          });
        }
      });
  };

  if (showConfirmation) {
    return <RegisterSuccess />;
  }

  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
      sx={{
        width: { xs: '80vw', md: '30vw' },
        marginTop: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Typography
        sx={{ textAlign: 'center', fontWeight: 700, marginBottom: 1 }}
      >
        {t('newRegister.title')}
      </Typography>

      <Box
        sx={{
          display: { xs: 'grid', sm: 'flex' },
          gap: { xs: 0, sm: 2 },
          width: '100%'
        }}
      >
        <TextField
          fullWidth
          label={t('forms.name')}
          type='text'
          name='name'
          required
          value={formData.name || ''}
          onChange={handleChange}
          error={formData.name === ''}
          helperText={formData.name === '' && t('forms.mandatoryField')}
        />
        <TextField
          fullWidth
          label={t('forms.contact')}
          type='text'
          name='phone'
          required
          value={formData.phone || ''}
          onChange={handleChange}
          error={formData.phone === ''}
          helperText={formData.phone === '' && t('forms.mandatoryField')}
        />
      </Box>

      <Box
        sx={{
          display: { xs: 'grid', sm: 'flex' },
          gap: { xs: 0, sm: 2 },
          width: '100%'
        }}
      >
        <TextField
          fullWidth
          label={t('forms.company')}
          type='text'
          name='company'
          required
          value={formData.company || ''}
          onChange={handleChange}
          error={formData.company === ''}
          helperText={formData.company === '' && t('forms.mandatoryField')}
        />
        <TextField
          fullWidth
          label={t('forms.vat')}
          type='text'
          name='taxNumber'
          required
          value={formData.taxNumber || ''}
          onChange={handleChange}
          error={formData.taxNumber === ''}
          helperText={formData.taxNumber === '' && t('forms.mandatoryField')}
        />
      </Box>

      <Box
        sx={{
          display: { xs: 'block', sm: 'flex' },
          gap: 2,
          width: '100%'
        }}
      >
        <TextField
          fullWidth
          label={t('forms.email')}
          type='email'
          name='email'
          required
          value={formData.email || ''}
          onChange={handleChange}
          error={formData.email === ''}
          helperText={formData.email === '' && t('forms.mandatoryField')}
        />
      </Box>

      <Box
        sx={{
          display: { xs: 'block', sm: 'flex' },
          gap: 2,
          width: '100%'
        }}
      >
        <TextField
          fullWidth
          label={t('forms.address')}
          type='text'
          name='street'
          required
          value={formData.street || ''}
          onChange={handleChange}
          error={formData.street === ''}
          helperText={formData.street === '' && t('forms.mandatoryField')}
        />
        <TextField
          fullWidth
          label={t('forms.postalCode')}
          type='text'
          name='zipCode'
          required
          value={formData.zipCode || ''}
          onChange={handleChange}
          error={formData.zipCode === ''}
          helperText={formData.zipCode === '' && t('forms.mandatoryField')}
        />
      </Box>

      <Box
        sx={{
          display: { xs: 'block', sm: 'flex' },
          gap: 2,
          width: '100%'
        }}
      >
        <TextField
          fullWidth
          label={t('forms.city')}
          type='text'
          name='city'
          required
          value={formData.city || ''}
          onChange={handleChange}
          error={formData.city === ''}
          helperText={formData.city === '' && t('forms.mandatoryField')}
        />

        <Autocomplete
          id='select-country'
          fullWidth
          disableClearable
          options={availableCountries}
          noOptionsText={t('app.noResults')}
          renderOption={(props, option) => (
            <Box {...props} component='li'>
              {option}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              name='country'
              label={t('forms.country')}
              error={formData.country === ''}
              helperText={formData.country === '' && t('forms.mandatoryField')}
            />
          )}
          onChange={(event, selectedOption) =>
            setFormData((values) => ({
              ...values,
              country: selectedOption || ''
            }))
          }
        />
      </Box>

      <Box
        sx={{
          width: '100%',
          display: { xs: 'block', sm: 'flex' },
          flexWrap: 'wrap'
        }}
      >
        <Box
          sx={{
            display: { xs: 'block', sm: 'flex' },
            gap: 2,
            width: '100%'
          }}
        >
          <TextField
            fullWidth
            required
            name='password'
            label={t('forms.password')}
            type={showPassword ? 'text' : 'password'}
            value={formData.password || ''}
            error={formData.password === ''}
            helperText={formData.password === '' && t('forms.mandatoryField')}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <FiEye size={18} />
                    ) : (
                      <FiEyeOff size={18} />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <TextField
            fullWidth
            required
            name='confirmPassword'
            label={t('forms.confirmPassword')}
            type={showConfirmationPassword ? 'text' : 'password'}
            value={formData.confirmPassword || ''}
            error={
              formData.confirmPassword === '' ||
              formData.confirmPassword !== formData.password
            }
            helperText={
              formData.confirmPassword === ''
                ? t('forms.mandatoryField')
                : formData.confirmPassword !== formData.password
                  ? t('forms.passwordsNotMatch')
                  : null
            }
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() =>
                      setShowConfirmationPassword(!showConfirmationPassword)
                    }
                  >
                    {showConfirmationPassword ? (
                      <FiEye size={18} />
                    ) : (
                      <FiEyeOff size={18} />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Typography
          sx={{
            width: '100%',
            fontSize: '0.8rem',
            marginTop: 1
          }}
        >
          {t('forms.passwordRules')}
        </Typography>
      </Box>

      <Box
        sx={{
          width: 'inherit',
          marginTop: { xs: 2, md: 1 }
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              onChange={() =>
                setTermsAndConditionsAccepted(!termsAndConditionsAccepted)
              }
            />
          }
          label={
            <Typography
              variant='small'
              sx={{
                '& a': {
                  textDecoration: 'underline'
                }
              }}
              dangerouslySetInnerHTML={{
                __html: `${t('forms.termsConsent')} <a href='/${
                  PAGES_SLUGS.institutional.termsAndConditions
                }'
                target='_blank' title='${t('pages.termsAndConditions')}'>${t(
                  'pages.termsAndConditions'
                )}
              </a>`
              }}
            />
          }
        />
      </Box>

      <Box
        sx={{
          display: 'inline-flex',
          justifyContent: { sm: 'flex-end' },
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          gap: 2
        }}
      >
        <Button
          component={Link}
          to={PAGES_SLUGS.login}
          variant='outlined'
          title={t('checkout.goBackButton')}
        >
          {t('checkout.goBackButton')}
        </Button>
        <LoadingButton
          variant='contained'
          type='submit'
          title={t('newRegister.submitButton')}
          sx={{
            marginTop: 3
          }}
          loading={isLoading}
          disabled={
            formData.confirmPassword !== formData.password ||
            !termsAndConditionsAccepted
              ? true
              : false
          }
        >
          {t('newRegister.submitButton')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default UserRegisterForm;

import { Container, Step, StepLabel, Stepper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { MainContainer } from '../styles';
import { PAGES_SLUGS } from '../variables';

const OrderSteps = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const user = useSelector((state) => state.user);
  const customerId = user.selectedClient?.id || user.id;
  const cart = useSelector((state) => state.cart);

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    switch (pathname) {
      case PAGES_SLUGS.cart:
        setActiveStep(0);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        break;
      case PAGES_SLUGS.checkout:
        setActiveStep(1);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        break;
      case PAGES_SLUGS.orderConfirmation:
        setActiveStep(2);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        break;
      default:
        break;
    }
  }, [pathname]);

  return (
    <MainContainer>
      <Container>
        <Grid container>
          <Grid xs={12}>
            {!!cart.products.length && (
              <Stepper
                alternativeLabel
                activeStep={activeStep}
              >
                {[
                  'cart.steps.cart',
                  'cart.steps.checkout',
                  'cart.steps.confirmation',
                ].map((step) => (
                  <Step key={step}>
                    <StepLabel>{t(step)}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}
            <Outlet context={[cart, user, customerId]} />
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default OrderSteps;

import { Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProductsCarousel from '../Carousel/ProductsCarousel';

const HighlightedProducts = ({ products }) => {
  const { t } = useTranslation();
  return (
    <Container sx={{ paddingY: { xs: 3, md: 5 } }}>
      <Typography
        variant='h2'
        sx={{
          fontSize: '1.7rem',
          textAlign: 'center',
        }}
      >
        {t('homepage.highlightedProducts.title')}
      </Typography>
      <Box sx={{ marginTop: 4 }}>
        <ProductsCarousel products={products} />
      </Box>
    </Container>
  );
};

export default HighlightedProducts;

import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { MyAccountTitle } from '../../../routes/AccountPage';
import AddressesList from './AddressesList';
import NewAddressModal from './NewAddressModal';
import UpdateAddressModal from './UpdateAddressModal';

const AccountAddresses = () => {
  const { t } = useTranslation();
  const addresses = useSelector((state) => state.user.addresses);

  const [newAddressModalIsOpen, setNewAddressModalIsOpen] = useState(false);
  const [updateAddressModalIsOpen, setUpdateAddressModalIsOpen] =
    useState(false);
  const [updateAddressModalData, setUpdateAddressModalData] = useState({});

  return (
    <>
      <Box
        sx={{
          marginBottom: 4,
        }}
      >
        <MyAccountTitle>{t('myAccount.addresses.title')}</MyAccountTitle>
        <Typography variant='small'>{t('myAccount.addresses.text')}</Typography>
      </Box>

      {!!addresses.length ? (
        <AddressesList
          addresses={addresses}
          setUpdateAddressModalIsOpen={setUpdateAddressModalIsOpen}
          setUpdateAddressModalData={setUpdateAddressModalData}
        />
      ) : (
        <Typography>{t('myAccount.addresses.noAddressesAvailable')}</Typography>
      )}

      <Button
        variant='contained'
        title={t('myAccount.addresses.addAddress')}
        onClick={() => setNewAddressModalIsOpen(true)}
        startIcon={<FiPlus size={14} />}
        sx={{
          marginTop: 3,
        }}
      >
        {t('myAccount.addresses.addAddress')}
      </Button>

      {newAddressModalIsOpen && (
        <NewAddressModal
          isOpen={newAddressModalIsOpen}
          setIsOpen={setNewAddressModalIsOpen}
        />
      )}

      {updateAddressModalIsOpen && (
        <UpdateAddressModal
          isOpen={updateAddressModalIsOpen}
          setIsOpen={setUpdateAddressModalIsOpen}
          data={updateAddressModalData}
        />
      )}
    </>
  );
};

export default AccountAddresses;

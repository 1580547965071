import { kebabCase } from 'lodash';
import { PAGES_SLUGS, PLACEHOLDER_IMAGE, SETTINGS } from '../variables';

function mapProduct(product) {
  const taxProductReference = '20008-unico-unica';

  const generateSlug = (slugArray) =>
    slugArray.reduce(
      (acc, item, index, array) =>
        !!item
          ? (acc += `${
              index === 0 && item.includes('/') // IF is first position and have '/'
                ? ''
                : '/'
            }${
              array.length - 1 === index // don't use 'kebabCase' at last 'item'
                ? item.split('/').at(-1) // get always the last element of array
                : kebabCase(item)
            }`)
          : acc,
      ''
    );

  //  update interface of 'related'
  const hasRelated = Boolean(
    product?.related !== undefined && product?.related.length !== 0
  );

  //  update interface of 'variants'
  const hasVariants = Boolean(
    product?.variants && product?.variants.length !== 0
  );

  return {
    // ...product,
    id: product?._id || product?.id,
    parentId: product?.parentId,
    reference: product?.reference,
    parentReference: product?.parentReference,
    eanUn: product?.eanUn,
    eanCx: product?.eanCx,
    slug: SETTINGS.defaults.productDetail.categoriesHierarchySlug
      ? generateSlug([
          product?.collection,
          // product?.category,
          // product?.productType,
          product?.slug
        ])
      : product?.slug.includes(PAGES_SLUGS.productDetail) //  exception for product cart slug
        ? product?.slug
        : `${PAGES_SLUGS.productDetail}/${product?.slug}`,
    title: product?.title,
    brand: product?.brand,
    collection: product?.collection,
    category: product?.category,
    description: product?.description,
    images: product?.images?.length ? product?.images : [PLACEHOLDER_IMAGE],
    discount: product?.discount,
    discountPrice: product?.discountPrice,
    saleAmount: product?.saleAmount || 0,
    //  unit
    sellsUnit: product?.sellsUnit || false,
    onSale: product?.onSale || false,
    pvpPrice: product?.pvpPrice || 0,
    price: product?.price || 0,
    salePrice: product?.salePrice || 0,
    stock: product?.stock || 0,
    //  box
    sellsBox: product?.sellsBox || false,
    itemsPerBox: product?.itemsPerBox || 0,
    onSaleBox: product?.onSaleBox || false,
    pvpBoxPrice: product?.pvpBoxPrice || 0,
    boxPrice: product?.boxPrice || 0,
    boxSalePrice: product?.boxSalePrice || 0,
    stockPerBox: product?.stockPerBox || 0,
    minQuantity: product?.minQuantity || 0,
    quantityMultiplier: product?.quantityMultiplier || 1,
    quantity: {
      unit: product?.quantity?.unit || 0,
      box: product?.quantity?.box || 0
    },
    freeDelivery: product?.freeDelivery || false,
    deliveryTime: product?.deliveryTime,
    video: product?.video,
    datasheet: product?.datasheet,
    technicalSheet: product?.technicalSheet,
    params: product?.params,
    date: product?.erp_update_date || product?.date,
    badge: {
      new: product?.badge?.new || false,
      campaign: product?.badge?.campaign || false
    },
    isVariant: product?.isVariant || false,
    related: hasRelated
      ? product?.related.map((related) => mapProduct(related))
      : [],
    variationFields: product?.variationFields,
    taxProduct: product?.reference === taxProductReference ? true : false,
    variants: hasVariants
      ? product?.variants?.map((variant) =>
          mapProduct({
            ...variant,
            id: product?._id || product?.id,
            variationFields: product?.variationFields
          })
        )
      : []
  };
}

function mapCategory(collection, translations) {
  const translateCategoryTitle = (category) => {
    const categoryTitle = translations?.find(
      (translation) => translation.id === category.url
    );
    return categoryTitle ? { ...categoryTitle } : category.title;
  };

  return {
    id: collection?._id,
    title: translateCategoryTitle(collection),
    url: collection.url,
    highlighted: collection.highlighted,
    image: collection.image,
    categories:
      collection?.categories?.map((category) => ({
        id: category?._id,
        title: translateCategoryTitle(category),
        url: `/${collection?.url}/${category?.url}`,
        subcategories:
          category?.subcategories?.map((subcategory) => ({
            id: subcategory?._id,
            title: translateCategoryTitle(subcategory),
            url: `/${collection?.url}/${category?.url}/${subcategory?.url}`
          })) || []
      })) || []
  };
}

function mapFilters(filters) {
  return Object.entries(filters).map((filter) => ({
    id: filter[0],
    title: filter[1].title,
    options: filter[1].values.map(({ title, totalResult }) => ({
      id: kebabCase(title),
      title: title,
      totalResults: totalResult || 0
    }))
  }));
}

export { mapCategory, mapFilters, mapProduct };

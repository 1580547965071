import { Box, Button, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { BsFiletypeXls } from 'react-icons/bs';
import StockslistFile from '../assets/STOCKLIST-20240403.xls';
import Image from '../assets/stocks-list.jpg';
import { MainContainer } from '../styles';
import { FONT_STYLE } from '../variables';

const StocksList = () => {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <Container>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <Box
              sx={{
                textAlign: 'center'
              }}
            >
              <Typography variant='h1'>{t('stockslist.title')}</Typography>
            </Box>
          </Grid>
          <Box marginY={{ xs: 3, md: 6 }}>
            <Grid container>
              <Grid xs={12} md={7}>
                <Box
                  component='img'
                  src={Image}
                  alt={t('stockslist.title')}
                  sx={{ maxWidth: '100%' }}
                />
              </Grid>
              <Grid
                xs={12}
                md={4}
                mdOffset={1}
                sx={{
                  display: 'grid',
                  alignContent: 'center',
                  justifyItems: 'flex-start',
                  gap: 1
                }}
              >
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: t('stockslist.text')
                  }}
                />
                <Button
                  component='a'
                  href={StockslistFile}
                  title={t('stockslist.downloadButton')}
                  download
                  target='_blank'
                  variant='contained'
                  color='secondary'
                  sx={{
                    fontWeight: FONT_STYLE.semibold,
                    gap: 1
                  }}
                >
                  <BsFiletypeXls size={18} />
                  {t('stockslist.downloadButton')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default StocksList;

import { Box, Typography } from '@mui/material';
import { kebabCase } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsBox } from 'react-icons/bs';
import { FiTruck } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { updateCartProducts, addProductsToCart } from '../../../api/checkout';
import { PopupNotificationContext } from '../../../contexts/PopupNotificationContext';
import { productDetailsInterface } from '../../../helpers/productDetailsInterface.js';
import { setCartState } from '../../../state/cartSlice.js';
import { BadgeDiscountOutlined } from '../../../styles';
import type { Product, ProductDetailResume } from '../../../types/product';
import {
  FONT_STYLE,
  PAGES_SLUGS,
  SETTINGS,
  THEME_COLORS
} from '../../../variables';
import {
  HighlightedSpecs as LabelWithIcon,
  ProductInformations
} from '../../ProductListCard/ProductListCard.jsx';
import Quantity from '../../Quantity/Quantity';
import StockAvailability from '../../StockAvailability';
import ProductVariants from '../ProductVariants/ProductVariants.jsx';
import ProductCheckout from './ProductCheckout.jsx';
import ProductPrices from './ProductPrices.jsx';
import ProductResume from './ProductResume.jsx';
import SizeGuide from './SizeGuide';

const ProductDetails = ({
  product,
  userIsLogged,
  customerId
}: {
  product: Product;
  userIsLogged: boolean;
  customerId: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setNotificationProps } = useContext(PopupNotificationContext);

  const cart = useSelector((state: any) => state.cart);
  const userstate = useSelector((state: any) => state.user);
  const customerDiscount =
    userstate.selectedClient.customerDiscount || userstate.customerDiscount;
  const cumulativeDiscounts =
    userstate.selectedClient.cumulativeDiscounts ||
    userstate.cumulativeDiscounts;

  const {
    reference,
    eanUn,
    eanCx,
    title,
    description,
    variants,
    brand,
    collection,
    taxes,
    technicalSheet,
    params,
    stock,
    estimatedDelivery
  } = product;

  const productDetails = productDetailsInterface(product);
  const productFit: string = params?.find((param) => param.id === 'fit')?.value;
  const [groupedVariants, setGroupedVariants] = useState<Product[]>([]);
  const hasTaxQuantities = variants.some((variant) => {
    const sizeParam = variant?.params?.find(
      (param) => param?.id === 'size'
    )?.value;
    return !!sizeParam &&
      ((Number(sizeParam) >= 47 && Number(sizeParam) <= 52) ||
        sizeParam === '4XL' ||
        sizeParam === '5XL' ||
        sizeParam === '6XL')
      ? true
      : false;
  });

  const [isLoading, setIsLoading] = useState(false);
  const [productsForCart, setProductsForCart] = useState({});
  const [updatedProductQuantity, setUpdatedProductQuantity] = useState([]);

  useEffect(() => {
    let manageVariants: Product[] = [];

    if (SETTINGS.defaults.groupedVariants) {
      //  group variants based on their 'parentId' key
      manageVariants = variants.reduce<Product[]>((acc, variant) => {
        const uniqueParentId = !acc.some(
          (a) => a.parentId === variant.parentId
        );
        if (uniqueParentId) {
          acc.push({ ...variant, variants: [variant] });
        } else {
          const parentIdIndex = acc.findIndex(
            (a) => a.parentId === variant.parentId
          );
          acc[parentIdIndex].variants.push(variant);
        }
        return acc;
      }, []);

      //  sort pattern
      const sortBy: string[] = [
        'XS',
        'S',
        'M',
        'L',
        'XL',
        'XXL',
        '3XL',
        '4XL',
        '5XL',
        '6XL'
      ];

      //  sort all variants using custom pattern (string sizes) OR subtraction (number sizes)
      manageVariants = manageVariants.map<Product>((variant) => ({
        ...variant,
        variants: variant.variants.sort((a, b) => {
          const paramsA = a.params.find((param) => param.id === 'size').value;
          const paramsB = b.params.find((param) => param.id === 'size').value;
          //  check IF string contains only numbers
          const isnum: boolean = /^\d+$/.test(paramsA);
          return isnum
            ? Number(paramsA) - Number(paramsB)
            : sortBy.indexOf(paramsA) - sortBy.indexOf(paramsB);
        })
      }));
    }

    if (cart.products.length !== 0) {
      //  pre-fill variants using same product cart quantities
      manageVariants = manageVariants.map((product) => ({
        ...product,
        variants: product.variants.map((variant) => {
          const matchedCartProduct = cart.products.find(
            (cartProduct) => cartProduct.reference === variant.reference
          );
          return {
            ...variant,
            quantity: !!matchedCartProduct
              ? { ...matchedCartProduct.quantity }
              : { ...variant.quantity }
          };
        })
      }));

      //  after getting all products added to cart » make all resume calculations
      const preFillingProductsResume = manageVariants.reduce(
        (acc, product) => [
          ...acc,
          ...product.variants.filter((variant) => variant.quantity.unit !== 0)
        ],
        []
      );
      setUpdatedProductQuantity(preFillingProductsResume);
    }

    setGroupedVariants(manageVariants);
  }, [dispatch, navigate, cart]);

  const addToCart = () => {
    let cartProducts: Product[] = [];
    const productsArray: Product[] = Object.values<ProductDetailResume>(
      productsForCart
    ).reduce((acc, product) => [...acc, ...product.products], []);

    if (!productsArray.length) {
      setNotificationProps({
        isOpened: true,
        type: 'warning',
        message: t('cart.addQuantitiesBeforeAddToCart')
      });
      return;
    }

    setIsLoading(true);

    //  cart don't have products » products manipulations avoided
    if (cart.products.length === 0) {
      cartProducts = productsArray;
    } else {
      const referencesToIgnore = variants.reduce<string[]>((acc, variant) => {
        !acc.some((parentId) => parentId === variant.parentId) &&
          acc.push(variant.parentId);
        return acc;
      }, []);
      const remainingCartProducts = cart.products.filter(
        (cartProduct) =>
          !referencesToIgnore.some(
            (reference) => reference === cartProduct.parentId
          )
      );
      cartProducts = [...productsArray, ...remainingCartProducts];
    }

    (cart.products.length === 0
      ? addProductsToCart(cartProducts, customerId)
      : updateCartProducts(cartProducts, customerId)
    )
      .then(
        ({ cart_items, discounts, total_discount, taxes, subtotal, total }) => {
          setIsLoading(false);
          dispatch(
            setCartState({
              products: cart_items,
              discount: total_discount,
              discounts: discounts,
              taxes: taxes,
              subtotal: subtotal,
              total: total
            })
          );

          setNotificationProps({
            isOpened: true,
            type: 'success',
            message: t('cart.productAddedToCartMessage')
          });
        }
      )
      .catch(({ response: { status } }) => {
        if (status === 401) {
          navigate(PAGES_SLUGS.login, { replace: true });
        } else if (status === 400 || status > 401) {
          setIsLoading(false);
          setNotificationProps({
            isOpened: true,
            type: 'error',
            message: t('app.generalErrorMessage')
          });
        }
      });
  };

  return (
    <Box sx={{ flex: 'auto' }}>
      <Box
        sx={{
          flex: 1,
          display: { xs: 'grid', sm: 'flex' },
          gap: { xs: 3, sm: 8 }
        }}
      >
        <Box sx={{ flex: 1 }}>
          {SETTINGS.defaults.productDetail.brand && !!brand && (
            <Typography marginBottom={1} sx={{ fontSize: '.8em' }}>
              {brand}
            </Typography>
          )}

          <Typography
            variant='h1'
            sx={{
              fontSize: '1.6rem',
              textTransform: 'unset'
            }}
          >
            {title}
          </Typography>

          <ProductInformations marginTop={2}>
            {!!reference && (
              <Typography variant='xsmall'>{`REF: ${reference}`}</Typography>
            )}
            {SETTINGS.defaults.productDetail.taxes && !!taxes && (
              <Typography variant='xsmall'>{`IVA: ${taxes}%`}</Typography>
            )}
            {SETTINGS.defaults.productDetail.eanUn && !!eanUn && (
              <Typography variant='xsmall'>{`EAN: ${eanUn}`}</Typography>
            )}
            {SETTINGS.defaults.productDetail.eanCx && !!eanCx && (
              <Typography variant='xsmall'>{`EAN CX: ${eanCx}`}</Typography>
            )}
          </ProductInformations>

          {SETTINGS.defaults.productDetail.itemsPerBox &&
            !!productDetails.itemsPerBox && (
              <LabelWithIcon marginTop={2}>
                <BsBox size={16} />
                {t('product.packagingCount', {
                  count: productDetails.itemsPerBox
                })}
              </LabelWithIcon>
            )}

          {SETTINGS.defaults.productDetail.description && !!description && (
            <Typography
              marginTop={2}
              sx={{ whiteSpace: 'pre-line' }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}

          {SETTINGS.defaults.productDetail.stockAvailability && (
            <Box marginTop={2}>
              <StockAvailability stock={stock} />
            </Box>
          )}

          {SETTINGS.defaults.productDetail.sizeGuideModal && !!productFit && (
            <SizeGuide productFit={kebabCase(productFit)} />
          )}

          {SETTINGS.defaults.productDetail.estimatedDelivery &&
            !!estimatedDelivery && (
              <LabelWithIcon marginTop={2}>
                <FiTruck size={18} />
                {t('product.estimatedDelivery', {
                  count: estimatedDelivery
                })}
              </LabelWithIcon>
            )}

          {userIsLogged ? (
            <>
              {SETTINGS.defaults.productDetail.customerDiscount &&
                !!customerDiscount &&
                !!cumulativeDiscounts && (
                  <Box>
                    <Typography
                      sx={{
                        marginBottom: 0.5,
                        fontWeight: FONT_STYLE.semibold
                      }}
                    >
                      {t('product.customerDiscount')}
                    </Typography>
                    <BadgeDiscountOutlined>{`${customerDiscount}%`}</BadgeDiscountOutlined>
                  </Box>
                )}

              <>
                <ProductPrices
                  productPrices={productDetails}
                  customerDiscount={customerDiscount}
                  cumulativeDiscounts={cumulativeDiscounts}
                />
                <Typography variant='xxsmall'>
                  {t('product.priceWithoutVat')}
                </Typography>
              </>
            </>
          ) : (
            <Box sx={{ marginTop: 4 }}>
              <Trans
                i18nKey='product.productPricesLogin'
                components={[
                  <Link
                    key='loginLink'
                    to={PAGES_SLUGS.login}
                    style={{
                      color: THEME_COLORS.primary,
                      fontWeight: 600
                    }}
                  />
                ]}
              />
            </Box>
          )}
        </Box>

        {SETTINGS.defaults.productDetail.rightSideResume &&
          productDetails.hasVariants &&
          !productDetails.outOfStock &&
          userIsLogged && (
            <Box sx={{ flex: 0.6 }}>
              <ProductResume
                userIsLogged={userIsLogged}
                data={{
                  totalUnits: !!productsForCart
                    ? Object.values(productsForCart).reduce(
                        (acc, { totalUnits }) => acc + totalUnits,
                        0
                      )
                    : 0,
                  totalBoxes: !!productsForCart
                    ? Object.values(productsForCart).reduce(
                        (acc, { totalBoxes }) => acc + totalBoxes,
                        0
                      )
                    : 0,
                  total: !!productsForCart
                    ? Object.values(productsForCart).reduce(
                        (acc, { total }) => acc + total,
                        0
                      )
                    : 0
                }}
                outOfStock={productDetails.outOfStock}
                technicalSheet={technicalSheet}
              />
            </Box>
          )}
      </Box>

      {productDetails.hasVariants &&
        groupedVariants.length !== 0 &&
        !productDetails.outOfStock && (
          <Box
            sx={{
              display: 'grid',
              gap: 1.5,
              marginTop: 4
            }}
          >
            <ProductVariants
              variants={
                SETTINGS.defaults.groupedVariants ? groupedVariants : variants
              }
              userIsLogged={userIsLogged}
              updatedProductQuantity={updatedProductQuantity}
              setUpdatedProductQuantity={setUpdatedProductQuantity}
              productsForCart={productsForCart}
              setProductsForCart={setProductsForCart}
            />
            {userIsLogged && (
              <>
                {!!hasTaxQuantities && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box
                      sx={{
                        width: '12px',
                        height: '12px',
                        minWidth: '12px',
                        backgroundColor: 'black'
                      }}
                    />
                    <Typography variant='xsmall'>
                      {t('product.taxProductLegend')}
                    </Typography>
                  </Box>
                )}
                <Typography variant='xxsmall'>
                  {t('product.priceWithoutVat')}
                </Typography>
              </>
            )}
          </Box>
        )}

      {userIsLogged && (
        <Box
          sx={{
            display: 'flex',
            gap: 3,
            marginTop: 3
          }}
        >
          {!productDetails.hasVariants &&
            (stock > 0 ||
              (stock <= 0 && kebabCase(collection) === 'camisas')) && (
              <Quantity
                product={updatedProductQuantity}
                setUpdatedProductQuantity={setUpdatedProductQuantity}
              />
            )}
          <ProductCheckout
            productId={product.id}
            addToCart={addToCart}
            outOfStock={productDetails.outOfStock}
            isLoading={isLoading}
          />
        </Box>
      )}
    </Box>
  );
};

export default ProductDetails;

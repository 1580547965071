import i18n from 'i18next';

export const checkStockAvailableInCart = (
  newProducts,
  cartProducts,
  setNotificationProps
) => {
  const productsAlreadyInCart = cartProducts.filter((cartProduct) =>
    newProducts.every(
      (productAdded) => cartProduct.reference === productAdded.reference
    )
  );
  const stockAvailable = [...newProducts, ...productsAlreadyInCart].reduce(
    (acc, product) =>
      acc -
      (product.sellsUnit
        ? product.quantity.unit
        : product.quantity.box * product.itemsPerBox),
    newProducts[0].stock
  );

  if (stockAvailable < 0) {
    setNotificationProps({
      isOpened: true,
      type: 'warning',
      message: i18n.t('cart.noStockAvailableMessage'),
    });
  }

  return Boolean(stockAvailable >= 0);
};

import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import kebabCase from 'lodash/kebabCase';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../../constants/utils';
import { imageOnError } from '../../../helpers';
import { BadgeDiscount } from '../../../styles';
import {
  FONT_STYLE,
  THEME_COLORS,
  TRANSITION_DURATION
} from '../../../variables';
import ImageZoomModal from '../../ImageZoomModal';
import Quantity from '../../Quantity/Quantity';

const ProductVariantsTable = ({
  variants,
  userIsLogged,
  setUpdatedProductQuantity,
  subtotalVariants
}) => {
  const { t } = useTranslation();
  const { role } = useSelector((state) => state.user);
  const translations = useSelector((state) => state.translations);
  const [cookie] = useCookies();

  const theme = useTheme();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [zoomImagePath, setZoomImagePath] = useState('');
  const variationFields = variants[0].variationFields;
  const translatedVariationFields = translations.filter((a) =>
    variationFields.some((b) => a.id === b)
  );
  const hidePriceCell = true;

  return (
    <>
      <TableContainer component={Paper}>
        <Table size='small' id='product-variants'>
          <TableHead>
            <TableRow>
              <TableCell>{t('tables.image')}</TableCell>
              {/* variation fields begin */}
              {(!!translatedVariationFields.length
                ? translatedVariationFields
                : variationFields
              ).map((variationName, index) => (
                <TableCell key={`variationField-${index}`}>
                  {variationName[cookie.language] || variationName}
                </TableCell>
              ))}
              {/* variation fields end */}
              {userIsLogged && (
                <>
                  <TableCell>{t('tables.selectQuantity')}</TableCell>
                  {role !== 'limitedcustomer' && (
                    <>
                      {!hidePriceCell && (
                        <TableCell>{t('tables.price')}*</TableCell>
                      )}
                      <TableCell>{t('tables.subtotal')}*</TableCell>
                    </>
                  )}
                </>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {variants.map((product) => {
              const {
                reference,
                parentId,
                images,
                title,
                price,
                onSale,
                saleAmount,
                salePrice,
                params,
                variants: sizeVariants
              } = product;

              return (
                <TableRow key={`product-variants-${reference}`}>
                  <TableCell>
                    <Button
                      title={t('product.seeImage')}
                      onClick={() => {
                        setZoomImagePath(images[0]);
                        setModalIsOpen(true);
                      }}
                      sx={{
                        display: 'flex',
                        minWidth: 'initial',
                        padding: 0,
                        marginX: 'auto',
                        overflow: 'hidden',
                        borderRadius: theme.shape.borderRadius / 10,
                        border: `1px solid ${THEME_COLORS.gray}`,
                        transition: `border-color ${TRANSITION_DURATION}ms ease`,
                        '&:hover': {
                          borderColor: THEME_COLORS.primary,
                          backgroundColor: 'white',
                          cursor: 'zoom-in'
                        }
                      }}
                    >
                      <Box
                        component='img'
                        alt={title}
                        src={images[0]}
                        onError={(e) => {
                          imageOnError(e);
                        }}
                        sx={{
                          maxWidth: '48px',
                          aspectRatio: '1',
                          objectFit: 'contain',
                          border: `${theme.spacing(0.5)} solid white`
                        }}
                      />
                    </Button>
                  </TableCell>
                  {/* variation fields begin */}
                  {!!variationFields.length &&
                    variationFields.map((variationName, index) => {
                      const variationValue = params.find(
                        (param) =>
                          kebabCase(param.id) === kebabCase(variationName)
                      )?.value;
                      const translatedVariationValues = translations.filter(
                        (a) => variationValue === a.id
                      );

                      return (
                        <TableCell key={`variationFieldValue-${index}`}>
                          {translatedVariationValues.length
                            ? translatedVariationValues[0][cookie.language]
                            : !!variationValue
                              ? variationValue
                              : '-'}
                        </TableCell>
                      );
                    })}
                  {/* variation fields end */}
                  {userIsLogged && (
                    <>
                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 0.25
                          }}
                        >
                          {sizeVariants.map((variant, index) => (
                            <Quantity
                              key={`sizeVariant-${index}`}
                              product={variant}
                              setUpdatedProductQuantity={
                                setUpdatedProductQuantity
                              }
                              isProductVariant
                              variant='size'
                              label={
                                variant.params.find((a) => a.id === 'size')
                                  ?.value || '-'
                              }
                            />
                          ))}
                        </Box>
                      </TableCell>
                      {role !== 'limitedcustomer' && (
                        <>
                          {!hidePriceCell && (
                            <TableCell>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  gap: 1,
                                  fontSize: '0.9rem'
                                }}
                              >
                                <Typography
                                  variant='small'
                                  sx={{
                                    textDecoration: onSale && 'line-through',
                                    fontWeight: onSale
                                      ? FONT_STYLE.regular
                                      : FONT_STYLE.bold
                                  }}
                                >
                                  {formatCurrency(price)}
                                </Typography>
                                {onSale && (
                                  <>
                                    <Typography
                                      variant='small'
                                      sx={{
                                        fontWeight: FONT_STYLE.bold,
                                        color: THEME_COLORS.primary
                                      }}
                                    >
                                      {formatCurrency(salePrice)}
                                    </Typography>
                                    <BadgeDiscount>{`-${saleAmount}%`}</BadgeDiscount>
                                  </>
                                )}
                              </Box>
                            </TableCell>
                          )}

                          <TableCell>
                            <Typography variant='small'>
                              {!!subtotalVariants[parentId || reference]
                                ? formatCurrency(
                                    subtotalVariants[parentId || reference]
                                  )
                                : '-'}
                            </Typography>
                          </TableCell>
                        </>
                      )}
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ImageZoomModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        imagePath={zoomImagePath}
      />
    </>
  );
};

export default React.memo(ProductVariantsTable);

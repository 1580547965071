import { Box, Button, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSizeGuides } from '../../../api';
import { imageOnError, objectIsEmpty } from '../../../helpers';
import { FONT_STYLE, THEME_COLORS } from '../../../variables';
import Modal, { ModalContent } from '../../Modal';
import SizeGuideTable from './SizeGuideTable';

type TabsList = {
  id: string;
  title: string;
};

const SizeGuide = ({ productFit }: { productFit: string }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const MOBILE_DEVICES = useMediaQuery(theme.breakpoints.down('md'));

  const sizeGuideIds = {
    regularFit: 'regular-fit',
    contemporary: 'contemporary',
    slim: 'slim',
    comfort: 'comfort',
    hawai: 'hawai'
  };
  const tabsList: TabsList[] = [
    { id: sizeGuideIds.regularFit, title: t('product.sizeGuideModal.regular') },
    {
      id: sizeGuideIds.contemporary,
      title: t('product.sizeGuideModal.contemporary')
    },
    { id: sizeGuideIds.slim, title: t('product.sizeGuideModal.slim') },
    { id: sizeGuideIds.comfort, title: t('product.sizeGuideModal.comfort') }
    // { id: sizeGuideIds.hawai, title: t('product.sizeGuideModal.hawai') }
  ];

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [sizeGuideTables, setSizeGuideTables] = useState<object>({});

  const defaultTab: number = tabsList.findIndex((tab) => tab.id === productFit);
  const [activeTab, setActiveTab] = useState<number>(
    defaultTab !== -1 ? defaultTab : 0
  );

  useEffect(() => {
    getSizeGuides().then((data) => setSizeGuideTables(data));
  }, []);

  return (
    <>
      <Button
        variant='link'
        title={t('product.sizeGuideButton')}
        onClick={() => setIsOpen(true)}
        sx={{
          fontWeight: FONT_STYLE.bold,
          marginTop: 2
        }}
      >
        {t('product.sizeGuideButton')}
      </Button>

      {isOpen && (
        <Modal
          isOpened={isOpen}
          setIsOpened={() => setIsOpen(false)}
          muiComponentNativeProps={{
            PaperProps: {
              sx: {
                maxWidth: '800px',
                maxHeight: '80vh',
                // CHROME
                '::-webkit-scrollbar': {
                  width: '8px'
                },
                '::-webkit-scrollbar-track': {
                  background: THEME_COLORS.lightGray
                },
                '::-webkit-scrollbar-thumb': {
                  background: THEME_COLORS.gray
                },
                // FIREFOX
                scrollbarColor: THEME_COLORS.gray
                // scrollbarWidth: 'thin'
              }
            }
          }}
        >
          <ModalContent>
            <Tabs
              value={activeTab}
              variant={
                MOBILE_DEVICES && tabsList.length > 2 ? 'scrollable' : null
              }
              scrollButtons={
                MOBILE_DEVICES && tabsList.length > 2 ? true : false
              }
              allowScrollButtonsMobile
              centered
              sx={{
                minHeight: 'auto'
              }}
            >
              {tabsList.map(({ id, title }, index) => (
                <Tab
                  key={`productTab-${id}`}
                  value={index}
                  label={t(title)}
                  title={t(title)}
                  onClick={() => setActiveTab(index)}
                  sx={{
                    fontSize: '1rem',
                    color: 'black',
                    fontWeight: activeTab === index && FONT_STYLE.bold,
                    textTransform: 'none',
                    minWidth: 'auto',
                    minHeight: 'auto',
                    paddingY: 1
                  }}
                />
              ))}
            </Tabs>
            <Box
              component='img'
              src={`${process.env.PUBLIC_URL}/sample-content/size-guide.jpg`}
              onError={imageOnError}
              alt={t('product.sizeGuideModal.title')}
              sx={{
                display: 'block',
                width: '100%',
                maxWidth: '450px',
                marginY: 3,
                marginX: 'auto'
              }}
            />
            {!objectIsEmpty(sizeGuideTables) && (
              <SizeGuideTable
                data={
                  sizeGuideTables[
                    {
                      0: sizeGuideIds.regularFit,
                      1: sizeGuideIds.contemporary,
                      2: sizeGuideIds.slim,
                      3: sizeGuideIds.comfort,
                      4: sizeGuideIds.hawai
                    }[activeTab]
                  ]
                }
              />
            )}
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default SizeGuide;

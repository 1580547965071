import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../constants/utils';
// import { BadgeDiscount } from '../../styles';
import { BadgeDiscount } from '../../styles';
import { FONT_STYLE, SETTINGS, THEME_COLORS } from '../../variables';

const ProductPrices = ({ productDetails, showRevPrice }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1
      }}
    >
      {SETTINGS.defaults.productCard.pricesFromLabel &&
        productDetails.hasVariants && (
          <Typography
            variant='xsmall'
            sx={{
              fontWeight: FONT_STYLE.semibold,
              marginBottom: 0.5
            }}
          >
            {t('product.pricesFrom')}
          </Typography>
        )}

      {SETTINGS.defaults.productCard.pvpLabel &&
        SETTINGS.defaults.productCard.pvpPrice && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            {SETTINGS.defaults.productCard.pvpLabel && (
              <Typography variant='xsmall' fontWeight={FONT_STYLE.regular}>
                {t('product.pvp')}
              </Typography>
            )}

            {SETTINGS.defaults.productCard.pvpPrice && (
              <Typography variant='xsmall' fontWeight={FONT_STYLE.regular}>
                {formatCurrency(productDetails.pvpPrice)}
              </Typography>
            )}
          </Box>
        )}

      {showRevPrice &&
        (SETTINGS.defaults.productCard.revLabel ||
          SETTINGS.defaults.productCard.revPrice) && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            {SETTINGS.defaults.productCard.revLabel && (
              <Typography variant='xsmall' fontWeight={FONT_STYLE.regular}>
                {t('product.rev')}*
              </Typography>
            )}

            {SETTINGS.defaults.productCard.revPrice && (
              <Typography
                sx={{
                  color: productDetails.onSale
                    ? THEME_COLORS.darkGray
                    : 'black',
                  fontWeight: productDetails.onSale
                    ? FONT_STYLE.regular
                    : FONT_STYLE.bold,
                  textDecoration: productDetails.onSale && 'line-through'
                }}
              >
                {formatCurrency(productDetails.price)}
              </Typography>
            )}

            {productDetails.onSale && (
              <>
                <Typography
                  sx={{
                    fontWeight: FONT_STYLE.bold,
                    color: THEME_COLORS.red
                  }}
                >
                  {formatCurrency(productDetails.salePrice)}
                </Typography>
                {SETTINGS.defaults.productCard.discountBadge && (
                  <BadgeDiscount>{`-${productDetails.saleAmount}%`}</BadgeDiscount>
                )}
              </>
            )}
          </Box>
        )}
      <Typography variant='xxsmall'>{t('product.priceWithoutVat')}</Typography>
    </Box>
  );
};

export default ProductPrices;

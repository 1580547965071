import axios from 'axios';
import { API_URL } from '../variables';
import { getHeaders } from './login';
import { mapProduct } from './mapApiInterface';

export function checkDeliveryOptions() {
  return axios
    .get(`${API_URL}/orders/delivery-methods`, getHeaders())
    .then(({ data }) => data);
}

export function addProductsToCart(newProducts, customerId) {
  return axios
    .post(
      `${API_URL}/cart/add`,
      { products: newProducts, customerId },
      getHeaders()
    )
    .then(({ data: { cart } }) => ({
      ...cart,
      cart_items: cart.cart_items.map((product) => mapProduct(product))
    }));
}

export function updateCartProducts(products, customerId) {
  return axios
    .post(`${API_URL}/cart/update`, { products, customerId }, getHeaders())
    .then(({ data: { cart } }) => ({
      ...cart,
      cart_items: cart.cart_items.map((product) => mapProduct(product))
    }));}

export function removeProductFromCart(reference, customerId) {
  return axios
    .post(`${API_URL}/cart/remove`, { reference, customerId }, getHeaders())
    .then(({ data: { cart } }) => cart);
}

export function checkDeliveryCost(country, zipCode, customerId) {
  return axios
    .post(
      `${API_URL}/orders/delivery-cost`,
      { country, zipCode, customerId },
      getHeaders()
    )
    .then(({ data }) => data);
}

export function addPromotionalCode(code, customerId) {
  return axios
    .post(`${API_URL}/vouchers/add`, { code, customerId }, getHeaders())
    .then(({ data: { cart } }) => cart);
}

export function deletePromotionalCode(code, customerId) {
  return axios
    .post(`${API_URL}/vouchers/delete`, { code, customerId }, getHeaders())
    .then(({ data: { cart } }) => cart);
}

export function confirmOrder(orderDetails) {
  return axios
    .post(`${API_URL}/orders/create`, orderDetails, getHeaders())
    .then(({ data: { order } }) => order);
}

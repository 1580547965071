import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FiShoppingCart } from 'react-icons/fi';
import { SETTINGS } from '../../../variables';
import AddShoppingListButton from '../../UserAccount/ShoppingLists/AddShoppingListButton';

const ProductCheckout = ({ productId, addToCart, outOfStock, isLoading }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2
        }}
      >
        <LoadingButton
          variant='contained'
          title={t('cart.addToCart')}
          onClick={() => addToCart()}
          disabled={outOfStock}
          loading={isLoading}
          sx={{
            minWidth: '200px',
            gap: 1
          }}
        >
          {!outOfStock ? (
            <>
              <FiShoppingCart size={16} /> {t('cart.addToCartShort')}
            </>
          ) : (
            t('product.outOfStock')
          )}
        </LoadingButton>

        {SETTINGS.functionalities.shoppingList && (
          <AddShoppingListButton productId={productId} />
        )}
      </Box>
    </>
  );
};

export default ProductCheckout;

import { Box, Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { CategoryButton } from '../../../styles';
import { THEME_COLORS } from '../../../variables';
import CategoriesMenuSubmenu from './CategoriesMenu/CategoriesMenuSubmenu';

const NavBar = () => {
  const { t } = useTranslation();

  const collections = useSelector((state) => state.categories.categories);

  const categoriesMenuRef = useRef(null);
  const navRef = useRef(null);

  const [isCategoriesMenuOpen, setIsCategoriesMenuOpen] = useState({});

  return (
    <Box
      ref={navRef}
      sx={{
        position: 'relative',
        backgroundColor: THEME_COLORS.primary
      }}
    >
      <Container>
        <Grid container>
          <Grid xs={12}>
            <Box component='nav'>
              <Box
                display='flex'
                sx={{
                  width: '100%'
                }}
              >
                {collections
                  .filter((collection) => collection.highlighted)
                  .reduce((acc, highlightedCollection, index, array) => {
                    const collectionsWithoutHighlighted = collections.filter(
                      (collection) => !collection.highlighted
                    );
                    acc.push(highlightedCollection);
                    // IF is last element
                    index === array.length - 1 &&
                      acc.push(...collectionsWithoutHighlighted);
                    return acc;
                  }, [])
                  .map(({ id, url, title, categories }) => {
                    const hasCategories = !!categories.length;
                    return (
                      <Box
                        key={`navbarMenu-${title}`}
                        ref={categoriesMenuRef}
                        onMouseEnter={() =>
                          hasCategories &&
                          setIsCategoriesMenuOpen({ [id]: true })
                        }
                        onMouseLeave={() =>
                          hasCategories && setIsCategoriesMenuOpen({})
                        }
                        sx={{
                          '&:first-of-type': {
                            marginLeft: -2.5
                          }
                        }}
                      >
                        <CategoryButton
                          component={NavLink}
                          title={t(title)}
                          to={url}
                          sx={{
                            paddingLeft: 2.5,
                            paddingRight: 2.5,
                            '&:hover': {
                              backgroundColor: THEME_COLORS.lightPrimary
                            }
                          }}
                        >
                          {t(title)}
                        </CategoryButton>
                        {hasCategories && isCategoriesMenuOpen[id] && (
                          <CategoriesMenuSubmenu
                            isCategoriesMenuOpen={isCategoriesMenuOpen[id]}
                            anchorEl={navRef.current}
                            categories={categories}
                          />
                        )}
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default NavBar;

import { kebabCase } from 'lodash';
import React, { useState } from 'react';
import { SETTINGS } from '../../variables';
import QuantitySelector from './QuantitySelector';

const Quantity = ({
  product,
  setUpdatedProductQuantity,
  isProductVariant,
  stockAvailable,
  _openFieldDisabled = false,
  variant = 'default',
  label,
  isLoading
}) => {
  const {
    quantity,
    sellsUnit,
    sellsBox,
    stock,
    stockPerBox,
    itemsPerBox,
    minQuantity,
    collection,
    quantityMultiplier: multiplier
  } = product;

  const [productQuantity, setProductQuantity] = useState(quantity);

  const isUnitQuantity = sellsUnit ? 'unit' : 'box';
  const isOutOfStock = SETTINGS.defaults.uniqueStock
    ? sellsUnit
      ? stock <= 0
      : stock < itemsPerBox
    : sellsUnit
      ? stock <= 0
      : stockPerBox < itemsPerBox;
  const quantityMultiplier = !!multiplier ? multiplier : 1;
  const stockAvailableControlled =
    stockAvailable !== undefined ? stockAvailable : stock;
  const orderWithoutStock =
    SETTINGS.defaults.orderWithoutStock && kebabCase(collection) === 'camisas';

  //  if any quantity (unit/box) cames filled » add it to default 'quantity type'
  const defaultQuantityType = Object.entries(productQuantity).filter(
    (item) => item[1] !== 0 && item[0]
  )?.[0]?.[0];

  const [quantityType, setQuantityType] = useState(
    isUnitQuantity || defaultQuantityType || SETTINGS.defaults.quantityType
  );
  const [updatedQuantity, setUpdatedQuantity] = useState(
    productQuantity[quantityType]
  );

  const decrementDisabled =
    (isProductVariant && updatedQuantity === 0) ||
    (!isProductVariant && updatedQuantity <= quantityMultiplier) ||
    (!!minQuantity && updatedQuantity <= minQuantity);

  const incrementDisabled = SETTINGS.defaults.orderWithoutStock
    ? false
    : SETTINGS.defaults.uniqueStock
      ? stockAvailableControlled <= 0 ||
        (sellsBox && stockAvailableControlled < itemsPerBox)
      : updatedQuantity >= stock || updatedQuantity * itemsPerBox > stockPerBox;

  const changeQuantityType = (e) => {
    setQuantityType(e);

    if (updatedQuantity === 0) return;

    //  switch between quantities type » 'unit' & 'box'
    setProductQuantity((prevState) => {
      Object.keys(productQuantity).forEach((item) =>
        item === e ? (prevState[item] = updatedQuantity) : (prevState[item] = 0)
      );
      return prevState;
    });

    setUpdatedProductQuantity([
      {
        ...product,
        quantity: productQuantity
      }
    ]);
  };

  const decrement = () => {
    if (updatedQuantity < quantityMultiplier) return;
    const newQuantity = updatedQuantity - quantityMultiplier;
    setUpdatedQuantity(newQuantity);
    setUpdatedProductQuantity([
      {
        ...product,
        quantity: {
          ...productQuantity,
          [quantityType]: newQuantity
        }
      }
    ]);
  };

  const increment = () => {
    const newQuantity = updatedQuantity + quantityMultiplier;
    setUpdatedQuantity(newQuantity);
    setUpdatedProductQuantity([
      {
        ...product,
        quantity: {
          ...productQuantity,
          [quantityType]: newQuantity
        }
      }
    ]);
  };

  const openFieldChange = (e) => {
    const newQuantity = Number(e);
    let quantity = 0;

    if (SETTINGS.defaults.uniqueStock) {
      if (stockAvailableControlled === 0 && newQuantity >= updatedQuantity) {
        return;
      } else if (
        sellsUnit &&
        newQuantity > stockAvailableControlled &&
        stockAvailableControlled !== 0
      ) {
        quantity = stockAvailableControlled + updatedQuantity;
        setUpdatedQuantity(quantity);
      } else if (
        sellsBox &&
        newQuantity * itemsPerBox > stockAvailableControlled &&
        newQuantity > updatedQuantity
      ) {
        quantity = Math.floor(
          (updatedQuantity * itemsPerBox + stockAvailableControlled) /
            itemsPerBox
        );
        setUpdatedQuantity(quantity);
      } else {
        quantity = newQuantity;
        setUpdatedQuantity(quantity);
      }
    } else {
      if (orderWithoutStock) {
        quantity = newQuantity;
      } else {
        if (newQuantity >= stock) {
          quantity = stock;
        } else {
          quantity = newQuantity;
        }
      }
      setUpdatedQuantity(quantity);
    }

    setUpdatedProductQuantity([
      {
        ...product,
        quantity: {
          ...productQuantity,
          [quantityType]: quantity
        }
      }
    ]);
  };

  return (
    <QuantitySelector
      quantity={updatedQuantity}
      increment={increment}
      decrement={decrement}
      incrementDisabled={incrementDisabled}
      decrementDisabled={decrementDisabled}
      openFieldDisabled={stock <= 0 && kebabCase(collection) !== 'camisas'}
      openFieldChange={openFieldChange}
      quantityType={quantityType}
      setQuantityType={changeQuantityType}
      isOutOfStock={isOutOfStock}
      minQuantity={minQuantity}
      variant={variant}
      label={label}
      stock={stock}
      isLoading={isLoading}
    />
  );
};

export default React.memo(Quantity);

import { LoadingButton } from '@mui/lab';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { recoverPassword } from '../../api/login';
import { PopupNotificationContext } from '../../contexts/PopupNotificationContext';
import { FONT_STYLE, PAGES_SLUGS } from '../../variables';

const PasswordRecovery = () => {
  const { t } = useTranslation();
  const { setNotificationProps } = useContext(PopupNotificationContext);

  const [isLoading, setIsLoading] = useState(false);
  const [recoveryEmail, setRecoveryEmail] = useState('');

  function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    if (!recoveryEmail) return;

    recoverPassword(recoveryEmail)
      .then(() => {
        setNotificationProps({
          isOpened: true,
          type: 'success',
          message: t('passwordRecovery.successMessage'),
        });
        setIsLoading(false);
      })
      .catch(
        ({
          response: {
            data: { msg },
            status,
          },
        }) => {
          if (status >= 400) {
            setNotificationProps({
              isOpened: true,
              type: 'error',
              message: msg,
            });
          }
          if (status === 500) {
            setNotificationProps({
              isOpened: true,
              type: 'error',
              message: 'O email inserido não é válido',
            });
          }
          setIsLoading(false);
        }
      );
  }

  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
      sx={{
        width: { xs: '80vw', md: '30vw' },
        marginTop: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          marginBottom: 1,
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: FONT_STYLE.bold,
            marginBottom: 1,
          }}
        >
          {t('passwordRecovery.title')}
        </Typography>
        <Typography
          variant='small'
          sx={{ textAlign: 'center' }}
        >
          {t('passwordRecovery.description')}
        </Typography>
      </Box>

      <TextField
        fullWidth
        label={t('forms.email')}
        type='email'
        required
        value={recoveryEmail}
        onChange={(e) => setRecoveryEmail(e.target.value)}
      />

      <Box
        sx={{
          display: 'inline-flex',
          justifyContent: { sm: 'flex-end' },
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        <Button
          component={Link}
          to={PAGES_SLUGS.login}
          variant='outlined'
          title={t('checkout.goBackButton')}
        >
          {t('checkout.goBackButton')}
        </Button>
        <LoadingButton
          variant='contained'
          type='submit'
          title={t('passwordRecovery.submitButton')}
          sx={{
            marginTop: 3,
          }}
          loading={isLoading}
        >
          {t('passwordRecovery.submitButton')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default PasswordRecovery;

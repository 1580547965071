import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  personalInfo: { name: '' },
  companyInfo: {},
  role: '',
  sellerInfo: {},
  selectedClient: {},
  productsHistory: [],
  addresses: [],
  orders: [],
  receipts: [],
  id: null,
  priceIsVisible: true,
  customerDiscount: 0,
  cumulativeDiscounts: false,
  isAdmin: false,
  blockedAccount: false,
  showBlockedAccountModal: false
};

const userSlice = createSlice({
  name: 'user',

  initialState: initialState,

  reducers: {
    setUser: (state, action) => (state = { ...state, ...action.payload }),

    setUserId: (state, action) => {
      state.id = action.payload;
    },

    setPersonalInfo: (state, action) => {
      state.personalInfo = action.payload;
    },

    setCompanyInfo: (state, action) => {
      state.companyInfo = action.payload;
    },

    setRole: (state, action) => {
      state.role = action.payload;
    },

    setSellerInfo: (state, action) => {
      state.sellerInfo = action.payload;
    },

    setSelectedClient: (state, { payload }) => {
      if (payload !== null) {
        const selectedClient = state.sellerInfo.clients.find(
          ({ id }) => id === payload
        );
        state.selectedClient = {
          //  add 'sellerInfo' to 'selectedCliente' object
          sellerInfo: {
            name: state.sellerInfo.name,
            email: state.sellerInfo.email,
            phone: state.sellerInfo.phone
          },
          ...selectedClient
        };
      } else {
        state.selectedClient = {};
      }
    },

    updateProductsHistory: (state, action) => {
      state.productsHistory = action.payload;
    },

    setAddresses: (state, action) => {
      state.addresses = action.payload;
    },

    setPreviousOrders: (state, action) => {
      state.orders = action.payload;
    },

    setReceipts: (state, action) => {
      state.receipts = action.payload;
    },

    setPriceVisibility: (state, action) => {
      state.priceIsVisible = action.payload;
    },

    resetUserState: () => initialState
  }
});

export const {
  setUser,
  setUserId,
  setPersonalInfo,
  setCompanyInfo,
  setRole,
  setSellerInfo,
  setSelectedClient,
  updateProductsHistory,
  setAddresses,
  setPreviousOrders,
  setReceipts,
  setPriceVisibility,
  resetUserState
} = userSlice.actions;

export default userSlice.reducer;

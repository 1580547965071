import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getReceiptInvoice, getReceipts } from '../../../api/user';
import { formatCurrency } from '../../../constants/utils';
import { PopupNotificationContext } from '../../../contexts/PopupNotificationContext';
import { MyAccountTitle } from '../../../routes/AccountPage';
import { setReceipts } from '../../../state/userSlice';
import { PAGES_SLUGS } from '../../../variables';
import Loading from '../../Loading';
import AccountFilters from '../AccountFilters';
import ReceiptsTable from './ReceiptsTable';

const AccountReceipts = () => {
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setNotificationProps } = useContext(PopupNotificationContext);

  const receipts = useSelector((state) => state.user.receipts);
  const user = useSelector((state) => state.user);

  const [entities, setEntities] = useState({ receipts: [] });
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedYear, setSelectedYear] = useState('all');
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState({});

  const customerId = user?.selectedClient?.id || user?.id;

  useEffect(() => {
    setIsLoading(true);

    getReceipts(customerId)
      .then((receipts) => {
        //  customize orders array
        receipts = receipts
          .map((receipt) => ({
            ...receipt,
            status: t(`app.${receipt.status}`)
          }))
          .reverse();
        dispatch(setReceipts(receipts));
        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) {
          navigate(PAGES_SLUGS.login, { replace: true });
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, customerId, language]);

  useEffect(() => {
    const entities = { receipts };
    setEntities({ ...entities });
  }, [receipts]);

  const detailsMode = entities.receipts;

  const shownEntities = [...detailsMode];

  const filteredByStatus = shownEntities.filter(({ status }) => {
    if (selectedStatus === 'all') return true;

    return selectedStatus === status;
  });

  const filteredByYearAndStatus = filteredByStatus.filter(({ date }) => {
    if (selectedYear === 'all') return true;

    return selectedYear === new Date(date).getFullYear();
  });

  const filteredByEverything = filteredByYearAndStatus.filter(({ reference }) =>
    reference?.includes(search)
  );

  //  receipts balances
  let invoicedAmounts = 0;
  let openAmounts = 0;
  if (!!entities.receipts.length) {
    invoicedAmounts = entities.receipts?.reduce(
      (acc, invoice) =>
        parseFloat(Math.fround(acc + invoice?.value).toFixed(2)),
      0
    );
    openAmounts = entities.receipts?.reduce(
      (acc, invoice) =>
        parseFloat(Math.fround(acc + invoice?.balance).toFixed(2)),
      0
    );
  }

  const downLoadPdfFile = (data, fileName) => {
    const anchorElement = document.createElement('a');

    document.body.appendChild(anchorElement);

    anchorElement.style.display = 'none';

    const blob = new Blob([data], {
      type: 'application/pdf'
    });

    const url = window.URL.createObjectURL(blob);

    anchorElement.href = url;
    anchorElement.download = fileName;
    anchorElement.click();

    window.URL.revokeObjectURL(url);
    anchorElement.remove();
  };

  const getInvoice = (erpId, docType, docTypeErp, reference) => {
    setIsLoadingAction({ [erpId]: true });
    getReceiptInvoice(erpId, docTypeErp)
      .then((data) => {
        downLoadPdfFile(data, `${docType}_${reference}.pdf`);
        setIsLoadingAction({ [erpId]: false });
      })
      .catch(({ response: { status } }) => {
        setIsLoadingAction({ [erpId]: false });
        if (status === 401) {
          navigate(PAGES_SLUGS.login, { replace: true });
        } else if (status === 400 || status > 401) {
          setNotificationProps({
            isOpened: true,
            type: 'error',
            message: t('app.documentNotFound')
          });
        }
      });
  };

  return (
    <Box
      sx={{
        flex: 3,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box marginBottom={4}>
        <MyAccountTitle>{t('myAccount.receipts.title')}</MyAccountTitle>
        <Typography variant='small'>{t('myAccount.receipts.text')}</Typography>
      </Box>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          {!!entities.receipts.length && (
            <Box
              sx={{
                display: 'grid',
                gap: 0.5,
                marginBottom: 4
              }}
            >
              <Typography variant='small'>
                {t('myAccount.receipts.invoicedAmounts')}
                <Typography
                  component='span'
                  sx={{
                    marginLeft: 1.5,
                    fontWeight: 600
                  }}
                >
                  {formatCurrency(invoicedAmounts)}
                </Typography>
              </Typography>
              <Typography variant='small'>
                {t('myAccount.receipts.openValues')}
                <Typography
                  component='span'
                  sx={{
                    marginLeft: 1.5,
                    fontWeight: 600
                  }}
                >
                  {formatCurrency(openAmounts)}
                </Typography>
              </Typography>
            </Box>
          )}

          {filteredByEverything.length !== 0 && (
            <>
              <AccountFilters
                type={detailsMode}
                selectedStatus={selectedStatus}
                selectedYear={selectedYear}
                setSelectedStatus={setSelectedStatus}
                setSelectedYear={setSelectedYear}
                search={search}
                setSearch={setSearch}
              />
              <ReceiptsTable
                receipts={filteredByEverything}
                getInvoice={getInvoice}
                isLoading={isLoadingAction}
              />
              {/* <ExportToExcelButton isOrders={isOrders} /> */}
            </>
          )}

          {filteredByEverything.length === 0 && (
            <Typography>{t('myAccount.receipts.noDataAvailable')}</Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default AccountReceipts;

import { Box, Modal } from '@mui/material';
import React from 'react';
import { VscClose } from 'react-icons/vsc';
import { imageOnError } from '../helpers';
import { IconButton } from '../styles';
import { TRANSITION_DURATION } from '../variables';
import { useTheme } from '@emotion/react';

const ImageZoomModal = ({ modalIsOpen, setModalIsOpen, imagePath }) => {
  const theme = useTheme();
  return (
    <Modal
      open={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
      slotProps={{
        backdrop: {
          transitionDuration: TRANSITION_DURATION,
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
        },
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <>
        <IconButton
          title='Fechar'
          onClick={() => setModalIsOpen(false)}
          startIcon={<VscClose size={32} />}
          sx={{
            position: 'absolute',
            top: theme.spacing(2),
            right: theme.spacing(2),
            color: 'white',
          }}
        />
        <Box
          component='img'
          src={imagePath}
          onError={(e) => {
            imageOnError(e);
          }}
          sx={{
            width: { xs: '80vw', md: '80vh' },
            maxWidth: '100%',
            aspectRatio: '1',
            objectFit: 'contain',
            backgroundColor: 'white',
          }}
        />
      </>
    </Modal>
  );
};

export default React.memo(ImageZoomModal);

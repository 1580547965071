import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SellerInfo = ({ data }) => {
  const { t } = useTranslation();
  const { name, email } = data;
  return (
    <Box marginTop={1}>
      <TextField
        label={t('forms.name')}
        defaultValue={name}
        disabled
        fullWidth
      />

      <TextField
        label={t('forms.email')}
        defaultValue={email}
        disabled
        fullWidth
      />
    </Box>
  );
};

export default SellerInfo;

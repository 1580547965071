import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { Link, useOutletContext } from 'react-router-dom';
import CartSummary from '../components/Cart/CartSummary';

const Cart = () => {
  const { t } = useTranslation();
  const [cart, user, customerId] = useOutletContext();

  return (
    <Grid xs={12}>
      {!!cart.products.length ? (
        <CartSummary
          cartItems={cart.products}
          cartSubtotal={cart.subtotal}
          customerId={customerId}
          cartTotalDiscount={cart.discount}
          role={user.role}
          accountIsBlocked={user.blockedAccount}
        />
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          <Typography>{t('cart.yourCartIsEmpty')}</Typography>
          <Button
            variant='contained'
            component={Link}
            to='/'
            title={t('app.backToHomepageButton')}
            sx={{ marginTop: 2 }}
          >
            {t('app.backToHomepageButton')}
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default Cart;

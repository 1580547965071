import axios from 'axios';
import { API_URL, SETTINGS } from '../variables';
import { getHeaders } from './login';
import { mapFilters, mapProduct } from './mapApiInterface';
import { kebabCase } from 'lodash';

//  GET
export function getProduct(slug) {
  return axios
    .get(
      SETTINGS.development.sampleData.productDetail
        ? `${process.env.PUBLIC_URL}/sample-content/products-list.json`
        : `${API_URL}/products/${slug}`
    )
    .then(({ data }) => {
      const returnProduct = SETTINGS.development.sampleData.productDetail
        ? data.products.find((product) => product.slug === slug)
        : data.data;
      return mapProduct(returnProduct);
    });
}

export function getProducts({ searchParams = {} }) {
  const urlSearchParams = new URLSearchParams({
    sortBy: SETTINGS.defaults.productList.sortBy,
    ...searchParams
  }).toString();
  return axios
    .get(
      SETTINGS.development.sampleData.productList
        ? `${process.env.PUBLIC_URL}/sample-content/products-list.json`
        : `${API_URL}/products?${urlSearchParams}`
    )
    .then(({ data: { products, productsTotal, filters } }) => {
      let displayedProducts = [];

      if (SETTINGS.development.sampleData.productList) {
        const collection = urlSearchParams
          .split('&')
          .find((elm) => elm.includes('collection'))
          .split('=')[1];

        displayedProducts = products
          .filter(
            (product) =>
              kebabCase(product.collection.toLowerCase()) ===
              kebabCase(collection.toLowerCase())
          )
          .map((product) => mapProduct(product));

        productsTotal = displayedProducts.length;
      } else {
        displayedProducts = products.map((product) => mapProduct(product));
      }

      const displayedFilters = mapFilters(filters);
      return { displayedFilters, displayedProducts, productsTotal };
    });
}

export function getLatestProducts() {
  return axios
    .get(
      `${API_URL}/products?sortBy=${SETTINGS.defaults.productList.sortBy}&limit=20`,
      getHeaders()
    )
    .then(({ data: { products } }) =>
      products.map((product) => mapProduct(product))
    );
}

export function getProductsOnCampaign() {
  return axios
    .get(`${API_URL}/products?sale=true`, getHeaders())
    .then(({ data: { products } }) =>
      products.map((product) => mapProduct(product))
    );
}

export function getSearchedProducts({ searchParams = {} }) {
  const urlSearchParams = new URLSearchParams({
    sortBy: SETTINGS.defaults.productList.sortBy,
    ...searchParams
  }).toString();
  return axios
    .get(`${API_URL}/search?${urlSearchParams}`, getHeaders())
    .then(
      ({ data: { filters, products: productsReceived, productsTotal } }) => {
        const products = productsReceived.map((product) => mapProduct(product));
        const displayedFilters = mapFilters(filters);
        return { displayedFilters, products, productsTotal };
      }
    );
}

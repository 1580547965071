import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import { BsWhatsapp } from 'react-icons/bs';
import { COMPANY_INFO, TRANSITION_DURATION } from '../variables';

const WhatsAppButton = styled('a')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1.5),
  backgroundColor: '#25d366',
  borderRadius: '100%',
  color: 'white',
  boxShadow: '1px 1px 7px rgba(0,0,0,0.3)',
  transition: `box-shadow ${TRANSITION_DURATION}ms ease`,
  '&:hover': {
    boxShadow: '1px 1px 10px rgba(0,0,0,0.4)',
  },
}));

const WhatsAppWidget = () => {
  const theme = useTheme();
  const MOBILE_DEVICES = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: { xs: theme.spacing(1.5), md: theme.spacing(2) },
        right: { xs: theme.spacing(1.5), md: theme.spacing(2) },
        zIndex: 1,
      }}
    >
      <WhatsAppButton
        href={`https://api.whatsapp.com/send?phone=${COMPANY_INFO.whatsappPhoneNumber}`}
        target='_blank'
        title='Precisa de ajuda? Fale connosco'
      >
        <BsWhatsapp size={MOBILE_DEVICES ? 20 : 24} />
      </WhatsAppButton>
    </Box>
  );
};

export default WhatsAppWidget;

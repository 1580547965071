import { Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateCartProducts } from '../../api/checkout';
import { setCartState } from '../../state/cartSlice';
import DiscountSelectorModal from './DiscountSelectorModal';
import SummaryTable from './SummaryTable/SummaryTable';
import { PAGES_SLUGS } from '../../variables';

const CartSummary = ({
  cartItems,
  cartTotalDiscount,
  cartSubtotal,
  customerId,
  role,
  accountIsBlocked
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [discountModalIsOpen, setDiscountModalIsOpen] = useState(false);
  const [productDiscountUpdate, setProductDiscountUpdate] = useState({});

  const updateCart = (productsArray) => {
    setIsLoading(true);
    updateCartProducts(productsArray, customerId)
      .then(
        ({ cart_items, discounts, total_discount, taxes, subtotal, total }) => {
          dispatch(
            setCartState({
              products: cart_items,
              discount: total_discount,
              discounts: discounts,
              taxes: taxes,
              subtotal: subtotal,
              total: total
            })
          );
          setIsLoading(false);
        }
      )
      .catch(({ response: { status } }) => {
        if (status === 401) navigate(PAGES_SLUGS.login, { replace: true });
      });
  };

  return (
    <>
      <Typography
        variant='h2'
        sx={{
          textAlign: 'center',
          marginBottom: { xs: 3, md: 4 }
        }}
      >
        {t('cart.steps.cart')}
      </Typography>

      <SummaryTable
        isLoading={isLoading}
        cartItems={cartItems}
        cartTotalDiscount={cartTotalDiscount}
        cartSubtotal={cartSubtotal}
        role={role}
        accountIsBlocked={accountIsBlocked}
        updateCart={updateCart}
        setProductDiscountUpdate={setProductDiscountUpdate}
        setDiscountModalIsOpen={setDiscountModalIsOpen}
      />

      {discountModalIsOpen && (
        <DiscountSelectorModal
          discountModalIsOpen={discountModalIsOpen}
          setDiscountModalIsOpen={setDiscountModalIsOpen}
          updateCart={updateCart}
          product={productDiscountUpdate}
        />
      )}
    </>
  );
};

export default CartSummary;

import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteUser, getUsers } from '../../../api/user';
import { PopupNotificationContext } from '../../../contexts/PopupNotificationContext';
import { MyAccountTitle } from '../../../routes/AccountPage';
import { IconButton } from '../../../styles';
import { PAGES_SLUGS } from '../../../variables';
import Loading from '../../Loading';
import NewUserModal from './NewUserModal';

const AccountUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setNotificationProps } = useContext(PopupNotificationContext);

  const { isAdmin } = useSelector((state) => state.user);

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [modalIsOpened, setModalIsOpened] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getUsers()
      .then((users) => {
        setUsers(users);
        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate(PAGES_SLUGS.login, { replace: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteUserRegister = (email) => {
    setIsLoadingAction(true);

    deleteUser(email)
      .then(({ users }) => {
        setUsers(users);
        setIsLoadingAction(false);
        setNotificationProps({
          isOpened: true,
          type: 'success',
          message: t('myAccount.users.userDeletedMessage')
        });
      })
      .catch(({ response: { status, data } }) => {
        if (status === 401) {
          navigate(PAGES_SLUGS.login, { replace: true });
        } else if (status === 400 || status > 401) {
          setIsLoadingAction(false);
          setNotificationProps({
            isOpened: true,
            type: 'error',
            message: data?.msg || data?.errors?.[0]?.msg
          });
        }
      });
  };

  return (
    <Box>
      <Box marginBottom={4}>
        <MyAccountTitle>{t('myAccount.users.title')}</MyAccountTitle>
        <Typography variant='small'>{t('myAccount.users.text')}</Typography>
      </Box>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          {!!users.length ? (
            <TableContainer component={Paper}>
              <Table size='small' id='users-list'>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('tables.creationDate')}</TableCell>
                    <TableCell>{t('tables.email')}</TableCell>
                    <TableCell sx={{ width: '100px' }} />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {users.map(({ createdAt, email }) => {
                    const createdAtDate = new Date(
                      createdAt
                    ).toLocaleDateString('pt-PT');
                    return (
                      <TableRow key={`usersList-${email}`}>
                        <TableCell>{createdAtDate}</TableCell>
                        <TableCell>{email}</TableCell>
                        <TableCell>
                          <IconButton
                            title={t('tables.actions.remove')}
                            onClick={() => deleteUserRegister(email)}
                            disabled={isLoadingAction}
                          >
                            <FiTrash2 size={16} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>{t('myAccount.users.noUsersAvailable')}</Typography>
          )}

          {!!isAdmin && (
            <>
              <Button
                variant='outlined'
                title={t('myAccount.users.addNewUser')}
                onClick={() => setModalIsOpened(true)}
                endIcon={<FiPlus size={14} />}
                sx={{
                  marginTop: 3
                }}
              >
                {t('myAccount.users.addNewUser')}
              </Button>

              <NewUserModal
                modalIsOpened={modalIsOpened}
                setModalIsOpened={setModalIsOpened}
                setNotificationProps={setNotificationProps}
                setUsers={setUsers}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default AccountUsers;

import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getCategories } from './api/index.js';
import { mapCategory } from './api/mapApiInterface.js';
import Cookies from './components/Cookies/Cookies';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import WhatsAppWidget from './components/WhatsAppWidget.jsx';
import { PopupNotificationProvider } from './contexts/PopupNotificationContext.tsx';
import './i18n.js';
import { setCategories } from './state/categoriesSlice.js';
import { PAGES_SLUGS, SETTINGS } from './variables';
import BlockedAccountModal from './components/BlockedAccountModal.jsx';
import { setUser } from './state/userSlice.js';
require('dayjs/locale/pt');
require('dayjs/locale/es');

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const loginPages = location.pathname.includes(PAGES_SLUGS.login);
  const {
    t,
    i18n: { language }
  } = useTranslation();

  //  set locale at 'dayjs' package
  dayjs.locale(language);

  const { id, selectedClient, showBlockedAccountModal } = useSelector(
    (state) => state.user
  );
  const storedTranslations = useSelector((state) => state.translations);

  const [isOpen, setIsOpen] = useState(false);
  const hideBlockedAccountModal = () =>
    dispatch(
      setUser({
        showBlockedAccountModal: false
      })
    );

  useEffect(() => {
    if (showBlockedAccountModal === false) return;
    setIsOpen(true);
  }, [showBlockedAccountModal]);

  useEffect(() => window.scrollTo({ top: 0, behavior: 'smooth' }), [location]);

  /*
  useEffect(() => {
    SETTINGS.functionalities.translations &&
      getTranslations()
        .then((translations) => {
          const arraysAreEqual = arraysEqual(translations, storedTranslations);

          //  if arrays aren't equal
          if (arraysAreEqual === false) {
            dispatch(setTranslations(translations));
          }
        })
        .catch(({ response: { status } }) => {
          if (status === 404) navigate(PAGES_SLUGS.notFound, { replace: true });
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  */

  useEffect(
    () => {
      getCategories()
        .then((receivedCollections) => {
          let colletions = [];

          if (
            SETTINGS.functionalities.translations &&
            SETTINGS.defaults.translateCategories
          ) {
            const categoriesTranslations = storedTranslations
              //  get all 'category' translations
              .filter((translation) => translation.id.includes('category.'))
              //  remove 'category.' string from 'id'
              .map((translation) => ({
                ...translation,
                id: translation.id.slice(translation.id.indexOf('.') + 1)
              }));

            colletions = receivedCollections.map((collection) =>
              mapCategory(collection, categoriesTranslations)
            );
          } else {
            colletions = receivedCollections.map((collection) =>
              mapCategory(collection)
            );
          }

          dispatch(setCategories(colletions));
        })
        .catch(({ response: { status } }) => {
          if (status === 404) navigate(PAGES_SLUGS.notFound, { replace: true });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, selectedClient, storedTranslations]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
      <CssBaseline>
        <div className='app'>
          <PopupNotificationProvider>
            {!loginPages && <Header />}
            <Outlet />
            {!loginPages && <Footer />}
            <Cookies />
            {SETTINGS.functionalities.whatsappWidget && <WhatsAppWidget />}
            {SETTINGS.functionalities.blockedAccountModal && (
              <BlockedAccountModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                hideBlockedAccountModal={hideBlockedAccountModal}
                t={t}
              />
            )}
          </PopupNotificationProvider>
        </div>
      </CssBaseline>
    </LocalizationProvider>
  );
};

export default App;

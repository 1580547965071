import styled from '@emotion/styled/macro';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  useTheme
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FiShoppingCart } from 'react-icons/fi';
import { TbShoppingCartPlus } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { ProductCardContext } from '../../contexts/ProductCardContext';
import { imageOnError } from '../../helpers';
import { productDetailsInterface } from '../../helpers/productDetailsInterface';
import { IconButton } from '../../styles';
import {
  FONT_STYLE,
  PLACEHOLDER_IMAGE,
  SETTINGS,
  THEME_COLORS,
  TRANSITION_DURATION
} from '../../variables';
import ProductBadge from '../ProductBadge';
import StockAvailability from '../StockAvailability';
import ProductPrices from './ProductPrices';

const Title = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  transition: `color ${TRANSITION_DURATION}ms ease`,
  fontWeight: FONT_STYLE.bold
}));

const VariantsButton = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  left: 0,
  bottom: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  opacity: 0,
  transition: `opacity ${TRANSITION_DURATION}ms ease`
}));

const ProductCardGrid = styled(Card)(({ theme }) => ({
  // minWidth: '130px',
  // maxWidth: '300px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  textDecoration: 'none',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  border: 'none',
  '&:hover': {
    [Title]: {
      color: THEME_COLORS.primary
    },
    [VariantsButton]: {
      opacity: 1
    }
  }
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  // border: `1px solid ${THEME_COLORS.gray}`,
  // padding: theme.spacing(2)
  transition: `opacity ${TRANSITION_DURATION}ms ease`,
  '&:hover': {
    opacity: 0.85
  }
}));

const Image = styled(CardMedia)(() => ({
  maxWidth: '100%',
  objectFit: 'contain',
  aspectRatio: '1'
}));

const Reference = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  color: THEME_COLORS.darkGray
}));

const Brand = styled(Typography)(({ theme }) => ({
  fontSize: '.75rem',
  marginBottom: theme.spacing(1)
}));

const Content = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  padding: `${theme.spacing(1.5)} 0 0 0 !important`
}));

const ContentBottomSide = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(1.5)
}));

const ProductCard = ({ product, userIsLogged, showRevPrice }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    addToCart,
    productsForCart,
    setProductVariants,
    setIsVariationsModalOpen
  } = useContext(ProductCardContext);

  const { slug, title, brand, images, variants, reference } = product;
  const productDetails = productDetailsInterface(product);

  return (
    <ProductCardGrid className='product-card'>
      <ImageContainer component={Link} title={title} to={slug}>
        {SETTINGS.defaults.productCard.outOfStockBadge &&
          productDetails.outOfStock && (
            <ProductBadge
              variant='stock'
              sx={{
                position: 'absolute',
                top: theme.spacing(1),
                left: theme.spacing(1)
              }}
            >
              {t('product.outOfStock')}
            </ProductBadge>
          )}
        <Image
          component='img'
          image={images.length !== 0 ? images?.[0] : PLACEHOLDER_IMAGE}
          onError={imageOnError}
          alt={title}
        />
        {SETTINGS.defaults.productCard.quickAddToCartButton && (
          <VariantsButton>
            <IconButton
              title={t('cart.addToCart')}
              variant='contained'
              sx={{
                minWidth: 'initial'
              }}
              onClick={() =>
                SETTINGS.functionalities.variantsModal
                  ? (setProductVariants(variants),
                    setIsVariationsModalOpen(true))
                  : addToCart(productsForCart[reference].products)
              }
            >
              <TbShoppingCartPlus size={18} />
            </IconButton>
          </VariantsButton>
        )}
      </ImageContainer>

      <Content>
        <Box>
          {SETTINGS.defaults.productCard.brand && !!brand && (
            <Brand>{brand}</Brand>
          )}
          <Title component={Link} title={title} to={slug}>
            {title}
          </Title>
          {SETTINGS.defaults.productCard.reference && (
            <Reference variant='xsmall'>{`Ref: ${reference}`}</Reference>
          )}
        </Box>

        <ContentBottomSide>
          {userIsLogged && (
            <>
              <ProductPrices
                productDetails={productDetails}
                showRevPrice={showRevPrice}
              />

              {SETTINGS.defaults.productCard.addToCartButton && (
                <IconButton
                  title={
                    !productDetails.outOfStock
                      ? t('cart.addToCart')
                      : t('product.outOfStock')
                  }
                  variant='contained'
                  disabled={productDetails.outOfStock}
                  onClick={() =>
                    SETTINGS.functionalities.variantsModal
                      ? (setProductVariants(variants),
                        setIsVariationsModalOpen(true))
                      : addToCart(productsForCart[reference].products)
                  }
                  sx={{
                    width: '100%',
                    gap: 1
                  }}
                >
                  {!productDetails.outOfStock ? (
                    <>
                      <FiShoppingCart size={16} /> {t('cart.addToCartShort')}
                    </>
                  ) : (
                    t('product.outOfStock')
                  )}
                </IconButton>
              )}
            </>
          )}

          {SETTINGS.defaults.productCard.stockAvailability && (
            <StockAvailability stock={productDetails.stock} />
          )}
        </ContentBottomSide>
      </Content>
    </ProductCardGrid>
  );
};

export default ProductCard;

import { kebabCase } from 'lodash';
import { SETTINGS } from '../variables';

/*
|--------------------------------
| get product details with all validations
|--------------------------------
*/
export const productDetailsInterface = (product) => {
  let {
    saleAmount,
    variants,
    pvpPrice,
    price,
    onSale,
    salePrice,
    stock,
    collection
  } = product;

  const hasVariants = Boolean(variants.length);
  const unitProduct = variants.find(({ sellsUnit }) => sellsUnit);
  const boxProduct = variants.find(({ sellsBox }) => sellsBox);
  let variantsSortedAscByPrice = [];
  let variantsStock = undefined;

  if (hasVariants) {
    variantsSortedAscByPrice = [...variants].sort((a, b) =>
      a.onSale ? a.salePrice - b.salePrice : a.price - b.price
    )[0];

    const variantsWithoutStockArray = variants.filter(
      (variant) => variant.stock <= 0
    );

    variantsStock =
      variantsWithoutStockArray.length === variants.length ? 0 : 1;
  }

  return SETTINGS.functionalities.onlyUnitBoxProducts
    ? {
        hasVariants: hasVariants,
        hasUnit: Boolean(unitProduct),
        hasBox: Boolean(boxProduct),
        pvpPrice: !!unitProduct ? unitProduct.pvpPrice : boxProduct.pvpPrice,
        price: !!unitProduct ? unitProduct.price : boxProduct.price,
        onSale: !!unitProduct ? unitProduct.onSale : boxProduct.onSale,
        salePrice: !!unitProduct ? unitProduct.salePrice : boxProduct.salePrice,
        saleAmount: !!unitProduct
          ? unitProduct.saleAmount
          : boxProduct.saleAmount,
        stock: stock,
        outOfStock: Boolean(stock <= 0),
        itemsPerBox: !!boxProduct ? boxProduct.itemsPerBox : 0
      }
    : {
        hasVariants: hasVariants,
        pvpPrice: hasVariants ? variantsSortedAscByPrice.pvpPrice : pvpPrice,
        price: hasVariants ? variantsSortedAscByPrice.price : price,
        onSale: hasVariants ? variantsSortedAscByPrice.onSale : onSale,
        salePrice: hasVariants ? variantsSortedAscByPrice.salePrice : salePrice,
        saleAmount: saleAmount,
        stock: hasVariants ? variantsStock : stock,
        outOfStock:
          kebabCase(collection) !== 'camisas' &&
          Boolean((hasVariants ? variantsStock : stock) <= 0)
      };
};

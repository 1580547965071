import { Box, Button, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { PAGES_SLUGS } from '../../variables';
import Modal, { ModalTitle } from '../Modal';

const Cookies = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [cookies, setCookie, _removeCookie] = useCookies();
  const [isModalOpen, setIsModalOpen] = useState(!!!cookies.cookiesConsent);

  const createCookieConsent = () => {
    //  expires in 60 days
    const expiresInSeconds = parseInt(60) * 24 * 60 * 60;

    //  set user token
    setCookie('cookiesConsent', 'accepted', {
      path: '/',
      maxAge: expiresInSeconds,
      secure: true,
      sameSite: true,
    });
  };

  return (
    <Modal
      // begin custom component props
      isOpened={isModalOpen}
      setIsOpened={setIsModalOpen}
      hideCloseButton={true}
      // end custom component props
      muiComponentNativeProps={{
        disableEnforceFocus: true,
        hideBackdrop: true,
        disableScrollLock: true,
        PaperProps: {
          sx: {
            maxWidth: '100%',
            margin: 0,
            padding: 3,
            boxShadow: '0 0 3px 1px rgba(0,0,0,0.1)',
          },
        },
        sx: {
          top: 'initial',
          bottom: theme.spacing(2),
          maxWidth: `${theme.breakpoints.values.lg}px`,
          paddingLeft: { xs: 2, md: 3 },
          paddingRight: { xs: 2, md: 3 },
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: { xs: 2, sm: 6 },
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: { xs: 'column', sm: 'row' },
          textAlign: { xs: 'center', sm: 'left' },
        }}
      >
        <Box>
          <ModalTitle>{t('cookies.title')}</ModalTitle>
          <Typography
            dangerouslySetInnerHTML={{
              __html: `${t('cookies.text')} <a
              href='/${PAGES_SLUGS.institutional.privacyPolicy}'
              target='_blank'
                title='${t('pages.privacyPolicy')}'
              style='text-decoration: underline'
            >
                ${t('pages.privacyPolicy')}
            </a>`,
            }}
          />
        </Box>
        <Button
          variant='contained'
          title={t('cookies.button')}
          onClick={() => {
            createCookieConsent();
            setIsModalOpen(false);
          }}
        >
          {t('cookies.button')}
        </Button>
      </Box>
    </Modal>
  );
};

export default Cookies;

import { Box, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateUser } from '../../../api/user';
import { PopupNotificationContext } from '../../../contexts/PopupNotificationContext';
import {
  compareTwoObjects,
  objectIsEmpty
} from '../../../helpers';
import { MyAccountTitle } from '../../../routes/AccountPage';
import { setPersonalInfo } from '../../../state/userSlice';
import { PAGES_SLUGS } from '../../../variables';
import CompanyInfo from './CompanyInfo';
import PersonalInfo from './PersonalInfo';
import SellerInfo from './SellerInfo';

const AccountInfo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setNotificationProps } = useContext(PopupNotificationContext);

  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);

  const userState = useSelector((state) => state.user);
  const { personalInfo, companyInfo, sellerInfo } =
    userState.role === 'seller' ? userState.selectedClient : userState;

  const [userData, setUserData] = useState({ ...personalInfo });
  const [userDataChanged, setUserDataChanged] = useState(false);

  useEffect(() => {
    setUserData({ ...personalInfo });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.selectedClient?.id]);

  useEffect(() => {
    if (!personalInfo && !userData) return;

    const userDataDifferences = compareTwoObjects(personalInfo, userData);
    const userDataHasDifferences = !objectIsEmpty(userDataDifferences);

    if (userDataHasDifferences) {
      if (!userDataChanged) setUserDataChanged(true);
    } else {
      if (userDataChanged) setUserDataChanged(false);
    }
  }, [userData, personalInfo, userDataChanged]);

  function handleChange(e) {
    const { name, value } = e.target;
    setUserData((values) => ({ ...values, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (!userData) return;

    setIsLoading(true);

    updateUser(userData)
      .then(({ user: { personalInfo } }) => {
        dispatch(setPersonalInfo(personalInfo));
        setNotificationProps({
          isOpened: true,
          type: 'success',
          message: t('myAccount.personalData.successMessage')
        });
        setIsLoading(false);
      })
      .catch(
        ({
          response: {
            status,
            data: { errors }
          }
        }) => {
          if (status === 401) {
            navigate(PAGES_SLUGS.login, { replace: true });
          } else if (status === 422) {
            setUserData((values) => ({ ...values, [errors?.[0]?.param]: '' }));
            setNotificationProps({
              isOpened: true,
              type: 'error',
              message: errors?.[0]?.msg
            });
          }
          setIsLoading(false);
        }
      );
  }

  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
      sx={{
        flex: 3,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {!!companyInfo && (
        <Box>
          <MyAccountTitle>
            {t('myAccount.personalData.companyInformations')}
          </MyAccountTitle>
          <Typography variant='small'>
            {t('myAccount.personalData.companyInformationsInfo')}
          </Typography>
          <Box marginTop={1}>
            <CompanyInfo data={companyInfo} />
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
          gridColumnGap: theme.spacing(2),
          gridRowGap: theme.spacing(4),
          marginTop: 4
        }}
      >
        {!!userData && (
          <Box>
            <MyAccountTitle>
              {t('myAccount.personalData.contactInformations')}
            </MyAccountTitle>
            <Typography variant='small'>
              {t('myAccount.personalData.contactInfo')}
            </Typography>
            <PersonalInfo
              handleChange={handleChange}
              data={userData}
              userRole={userState.role}
              isLoading={isLoading}
              userDataChanged={userDataChanged}
            />
          </Box>
        )}
        {!!sellerInfo && userState.role !== 'limitedcustomer' && (
          <Box>
            <MyAccountTitle>
              {t('myAccount.personalData.sellerInformations')}
            </MyAccountTitle>
            <Typography variant='small'>
              {t('myAccount.personalData.sellerInfo')}
            </Typography>
            <SellerInfo data={sellerInfo} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AccountInfo;

import { Button, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { IconContext } from 'react-icons';
import { AiOutlineWarning } from 'react-icons/ai';
import { THEME_COLORS } from '../variables';
import Modal, { ModalActions, ModalContent } from './Modal';

const BlockedAccountModal = ({
  isOpen,
  setIsOpen,
  hideBlockedAccountModal,
  t
}) => {
  return (
    <Modal
      isOpened={isOpen}
      setIsOpened={setIsOpen}
      hideCloseButton
      muiComponentNativeProps={{
        disableEscapeKeyDown: true,
        onClose: (_e, reason) => {
          if (reason && reason === 'backdropClick') return;
        },
        PaperProps: {
          sx: {
            maxWidth: '500px'
          }
        }
      }}
    >
      <ModalContent
        sx={{
          paddingTop: 0,
          textAlign: 'center'
        }}
      >
        <IconContext.Provider value={{ style: { color: THEME_COLORS.orange } }}>
          <AiOutlineWarning size={50} />
        </IconContext.Provider>
        <Typography marginTop={1}>
          <Trans
            i18nKey='app.blockedAccountText'
            values={{ email: '<strong>dcd@dcd.pt</strong>' }}
          />
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          title={t('app.closeButton')}
          variant='contained'
          color='secondary'
          onClick={() => {
            hideBlockedAccountModal();
            setIsOpen(false);
          }}
        >
          {t('app.closeButton')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default BlockedAccountModal;

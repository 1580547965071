import { Box, Popper, Typography, styled } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VscChevronRight } from 'react-icons/vsc';
import { NavLink } from 'react-router-dom';
import type { Category } from '../../../../types/category';
import {
  FONT_STYLE,
  THEME_COLORS,
  TRANSITION_DURATION
} from '../../../../variables';

const CategoriesContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'categoriestree'
})<{ categoriestree: Category[] | [] }>(({ theme, categoriestree }) => ({
  minWidth: categoriestree.length > 30 ? '36vw' : '25vw',
  height: '100%',
  position: 'absolute',
  top: '0',
  left: '100%',
  backgroundColor: 'white',
  boxShadow: '0px 3px 5px 1px rgba(0,0,0,0.1)',
  padding: `${theme.spacing(2.5)} ${theme.spacing(4)}`,
  fontSize: '0.9rem',
  color: 'black'
}));

const CollectionTitle = styled(Typography)(() => ({
  fontSize: '1.25rem',
  fontWeight: FONT_STYLE.bold,
  color: THEME_COLORS.primary,
  textTransform: 'uppercase'
}));

const CategoriesGrid = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'categoriestree'
})<{ categoriestree: Category[] | [] }>(({ theme, categoriestree }) => ({
  display: 'grid',
  gridTemplateColumns:
    categoriestree.length > 30
      ? 'auto auto auto'
      : categoriestree.length > 15
        ? 'auto auto'
        : 'auto',
  gap: theme.spacing(1.25),
  marginTop: theme.spacing(2)
}));

const CategoriesButton = styled(NavLink, {
  shouldForwardProp: (prop) => prop !== 'active'
})<{
  active: number;
}>(({ theme, active }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2.5),
  fontSize: '0.9rem',
  color: 'black',
  fontWeight: FONT_STYLE.semibold,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  padding: `${theme.spacing(1.1)} ${theme.spacing(2.5)}`,
  backgroundColor: active === 1 ? THEME_COLORS.gray : 'white',
  transition: `backgroundColor ${TRANSITION_DURATION}ms ease`,
  [`&:hover`]: {
    backgroundColor: THEME_COLORS.gray
  }
}));

const CategoriesMenuSubmenu = ({
  isCategoriesMenuOpen,
  anchorEl,
  categories
}: {
  isCategoriesMenuOpen: boolean;
  anchorEl: HTMLElement;
  categories: Category[];
}) => {
  const [categoriesTree, setCategoriesTree] = useState<Category[] | []>([]);
  const [activeCategory, setActiveCategory] = useState<string>('');

  const {
    i18n: { language }
  } = useTranslation();

  return (
    <Popper
      open={isCategoriesMenuOpen}
      anchorEl={anchorEl}
      disablePortal
      keepMounted
      sx={{
        boxShadow: '0px 3px 5px 1px rgba(0,0,0,0.1)',
        inset: '100% auto auto auto !important',
        transform: 'none !important'
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {/* categories */}
        {categories.map((category) => (
          <Box key={`category-${category?.id}`}>
            <CategoriesButton
              to={category?.url}
              title={category?.title?.[language] || category?.title}
              onMouseEnter={() => {
                setCategoriesTree(category?.subcategories);
                setActiveCategory(category?.id);
              }}
              active={activeCategory === category?.id ? 1 : 0}
            >
              {category?.title?.[language] || category?.title}
              {!!category?.subcategories.length && (
                <VscChevronRight size={14} />
              )}
            </CategoriesButton>
            {/* subcategories */}
            {!!category?.subcategories.length &&
              activeCategory === category?.id && (
                <CategoriesContainer categoriestree={categoriesTree}>
                  <CollectionTitle>{category?.title}</CollectionTitle>
                  <CategoriesGrid categoriestree={categoriesTree}>
                    {categoriesTree.map((subcategory: Category) => (
                      <Typography
                        component={NavLink}
                        key={`subcategory-${subcategory?.id}`}
                        to={subcategory?.url}
                        title={
                          subcategory?.title?.[language] || subcategory?.title
                        }
                        variant='link'
                      >
                        {subcategory?.title?.[language] || subcategory?.title}
                      </Typography>
                    ))}
                  </CategoriesGrid>
                </CategoriesContainer>
              )}
          </Box>
        ))}
      </Box>
    </Popper>
  );
};

export default CategoriesMenuSubmenu;

import {
  Autocomplete,
  Box,
  Container,
  Divider,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCart, getShoppingLists, setCustomer } from '../../api/user';
import { PopupNotificationContext } from '../../contexts/PopupNotificationContext';
import { setCartState } from '../../state/cartSlice';
import { setShoppingLists } from '../../state/shoppingListsSlice';
import { setSelectedClient } from '../../state/userSlice';
import { FONT_STYLE, PAGES_SLUGS, THEME_COLORS } from '../../variables';

const ClientSelectorSelect = styled(Autocomplete)(() => ({
  [`.MuiInputBase-root`]: {
    fontSize: '0.9em'
  },
  [`.MuiAutocomplete-option`]: {
    fontSize: '0.9em'
  }
}));

const ClientSelector = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setNotificationProps } = useContext(PopupNotificationContext);

  const {
    selectedClient,
    sellerInfo: { clients }
  } = useSelector((state) => state.user);
  const { cart_id, id: selectedClientId } = selectedClient;
  const [clientId, setClientId] = useState(selectedClientId);

  useEffect(() => {
    selectedClientId !== clientId &&
      setCustomer({ customerId: selectedClientId })
        .then(() => {
          setClientId(selectedClientId);

          //  get cart items
          return getCart(cart_id)
            .then(
              ({
                _id,
                cart_items,
                voucher,
                total_discount,
                discounts,
                taxes,
                subtotal,
                total
              }) => {
                dispatch(
                  setCartState({
                    id: _id,
                    products: cart_items,
                    promotionalCodes: voucher,
                    discount: total_discount,
                    discounts: discounts,
                    taxes: taxes,
                    subtotal: subtotal,
                    total: total
                  })
                );

                //  get shipping lists
                return getShoppingLists()
                  .then((lists) => {
                    dispatch(setShoppingLists(lists));

                    setNotificationProps({
                      isOpened: true,
                      type: 'success',
                      message: 'Cliente alterado com sucesso'
                    });
                  })
                  .catch(({ response: { status } }) => {
                    if (status === 401)
                      navigate(PAGES_SLUGS.login, { replace: true });
                  });
              }
            )
            .catch(({ response: { status } }) => {
              if (status === 401)
                navigate(PAGES_SLUGS.login, { replace: true });
            });
        })
        .catch(({ response: { status } }) => {
          if (status === 401) {
            navigate(PAGES_SLUGS.login, { replace: true });
          } else if (status === 422)
            setNotificationProps({
              isOpened: true,
              type: 'error',
              message: 'Houve um problema na alteração do cliente'
            });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, cart_id, dispatch]);

  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: THEME_COLORS.lightGray,
        paddingY: 1.5
      }}
    >
      <Container>
        <Box
          sx={{
            display: 'flex',
            gap: { xs: 0.5, md: 2 },
            alignItems: 'center',
            justifyContent: 'end',
            flexDirection: { xs: 'column', md: 'row' }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              color: THEME_COLORS.darkGray
            }}
          >
            <Typography variant='small'>{t('app.yourProfile')}:</Typography>
            <Typography variant='small' sx={{ fontWeight: FONT_STYLE.bold }}>
              {t('app.seller')}
            </Typography>
          </Box>

          {!!clients.length && (
            <>
              <Divider
                orientation='vertical'
                flexItem
                sx={{ borderColor: THEME_COLORS.darkGray }}
              />

              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                  color: THEME_COLORS.darkGray
                }}
              >
                <Typography variant='small'>{t('app.yourClient')}:</Typography>

                <ClientSelectorSelect
                  id='client-selection'
                  size='small'
                  disablePortal
                  options={clients}
                  disableClearable={true}
                  value={selectedClient}
                  onChange={(event, newValue) =>
                    dispatch(setSelectedClient(newValue?.id))
                  }
                  getOptionLabel={(option) => option?.companyInfo?.name}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  noOptionsText={'Cliente não encontrado'}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={params?.id}
                      sx={{
                        marginTop: 0
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <Box
                      {...props}
                      component='li'
                      key={`client-option-${option.id}`}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Typography fontSize='0.95rem'>
                        {option?.companyInfo?.name}
                      </Typography>
                      {option?.user_role === 'LimitedCustomer' && (
                        <Typography variant='xsmall'>
                          ({option?.personalInfo?.email})
                        </Typography>
                      )}
                    </Box>
                  )}
                  sx={{
                    width: 200
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default ClientSelector;

import {LoadingButton} from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  MenuItem,
  TextField,
  Typography, useTheme
} from '@mui/material';
import {debounce} from 'lodash';
import {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IconContext} from 'react-icons';
import {BsCheckCircle} from 'react-icons/bs';
import {FiTrash2} from 'react-icons/fi';
import {useNavigate} from 'react-router-dom';
import {sendBudgetRequest} from '../../api';
import {getSearchedProducts} from '../../api/products';
import {PopupNotificationContext} from '../../contexts/PopupNotificationContext';
import {IconButton} from '../../styles';
import {
  FONT_STYLE,
  PAGES_SLUGS,
  SETTINGS,
  THEME_COLORS,
} from '../../variables';
import {Cookies} from "react-cookie";
import {getUserByToken} from "../../api/user";
import { imageOnError } from '../../helpers';

const BudgetRequestForm = () => {
  const authentication = new Cookies().get('userToken');
  const [user, setUser] = useState(null);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {setNotificationProps} = useContext(PopupNotificationContext);
  const [isLoading, setIsLoading] = useState(false);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [budgetRequestDetails, setBudgetRequestDetails] = useState({
    products: [{}],
  });
  const theme = useTheme();

  const [shownProducts, setShownProducts] = useState([]);

  const getOptionsDebounced = debounce(async (queryParam) => {
    await getSearchedProducts({
      searchParams: {query: queryParam, limit: 5},
    })
      .then(({products}) =>
        setShownProducts(
          products.map((product) => ({
            title: `${product.reference} - ${product.title}`,
            productObject: product,
          }))
        )
      )
      .catch(({response: {status}}) => {
        if (status === 401) navigate(PAGES_SLUGS.login);
      });
  }, 250);

  const handleChange = ({target: {id, value}}) => {
    setBudgetRequestDetails((values) => ({...values, [id]: value}));
  };

  function handleConfirmOrder(e) {
    e.preventDefault();

    setIsLoading(true);

    sendBudgetRequest({
      ...budgetRequestDetails,
      //  remove 'fake' products
      products: budgetRequestDetails.products.filter((product) => product.id),
    })
      .then(() => {
        setIsLoading(false);
        setShowConfirmation(true);
      })
      .catch(
        ({
           response: {
             data: {msg},
             status,
           },
         }) => {
          if (status === 401) {
            navigate(PAGES_SLUGS.login);
          } else if (status === 400 || status > 401) {
            setIsLoading(false);
            setNotificationProps({
              isOpened: true,
              type: 'error',
              message: msg,
            });
          }
        }
      );
  }
  useEffect(() => {
    if(authentication) {
      setIsLoading(true);
      setTimeout(() => {
        getUserByToken(authentication.replace('Bearer ', ''))
          .then((data) => {
            setUser(data);
            setIsLoading(false)
          })
      }, 250);
    }
    return () => {
      setUser(null);
    };
  }, [authentication]);
  if(authentication && user != null){
    budgetRequestDetails.name = user.name;
    budgetRequestDetails.email = user.email;
    budgetRequestDetails.phoneNumber = user.phone;
    budgetRequestDetails.mobileNumber = user.phone;
    budgetRequestDetails.companyName = user.company.name;
    budgetRequestDetails.city = user.company.city;
    budgetRequestDetails.street = user.company.street;
    budgetRequestDetails.taxNumber = user.company.nif;
    budgetRequestDetails.postalCode = user.company.zip_code;
  }
  if (showConfirmation) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: {xs: 3, md: 4},
        }}
      >
        <IconContext.Provider
          value={{
            style: {color: THEME_COLORS.lightGreen},
          }}
        >
          <BsCheckCircle size={50}/>
        </IconContext.Provider>
        <Typography
          sx={{
            marginTop: 2,
            fontWeight: 600,
          }}
        >
          {t('budgetRequest.successMessage')}
        </Typography>
      </Box>
    );
  }
  if (!authentication) {
    return (
      <>
        <Box
          component='form'
          onSubmit={handleConfirmOrder}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              width: '100%',
            }}
          >
            <TextField
              id='name'
              label={t('forms.name')}
              required
              fullWidth
              onChange={handleChange}
              value={budgetRequestDetails.name || ''}
              error={budgetRequestDetails.name === ''}
              helperText={
                budgetRequestDetails.name === '' && t('forms.mandatoryField')
              }
            />
            <TextField
              id='companyName'
              label={t('forms.company')}
              required
              fullWidth
              onChange={handleChange}
              value={budgetRequestDetails.companyName || ''}
              error={budgetRequestDetails.companyName === ''}
              helperText={
                budgetRequestDetails.companyName === '' &&
                t('forms.mandatoryField')
              }
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              width: '100%',
            }}
          >
            <TextField
              id='taxNumber'
              label={t('forms.vat')}
              type='number'
              required
              fullWidth
              onChange={handleChange}
              value={budgetRequestDetails.taxNumber || ''}
              error={budgetRequestDetails.taxNumber === ''}
              helperText={
                budgetRequestDetails.taxNumber === '' && t('forms.mandatoryField')
              }
            />
            <TextField
              id='email'
              label={t('forms.email')}
              type='email'
              required
              fullWidth
              onChange={handleChange}
              value={budgetRequestDetails.email || ''}
              error={budgetRequestDetails.email === ''}
              helperText={
                budgetRequestDetails.email === '' && t('forms.mandatoryField')
              }
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              width: '100%',
            }}
          >
            <TextField
              id='phoneNumber'
              label={t('forms.telephone')}
              type='tel'
              fullWidth
              onChange={handleChange}
              value={budgetRequestDetails.phoneNumber || ''}
            />
            <TextField
              id='mobileNumber'
              label={t('forms.mobilePhone')}
              type='tel'
              required
              fullWidth
              onChange={handleChange}
              value={budgetRequestDetails.mobileNumber || ''}
              error={budgetRequestDetails.mobileNumber === ''}
              helperText={
                budgetRequestDetails.mobileNumber === '' &&
                t('forms.mandatoryField')
              }
            />
          </Box>
          <TextField
            id='street'
            label={t('forms.address')}
            required
            fullWidth
            onChange={handleChange}
            value={budgetRequestDetails.street || ''}
            error={budgetRequestDetails.street === ''}
            helperText={
              budgetRequestDetails.street === '' && t('forms.mandatoryField')
            }
          />
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              width: '100%',
            }}
          >
            <TextField
              id='postalCode'
              label={t('forms.postalCode')}
              required
              fullWidth
              onChange={handleChange}
              value={budgetRequestDetails.postalCode || ''}
              error={budgetRequestDetails.postalCode === ''}
              helperText={
                budgetRequestDetails.postalCode === '' &&
                t('forms.mandatoryField')
              }
            />
            <TextField
              id='city'
              label={t('forms.city')}
              required
              fullWidth
              onChange={handleChange}
              value={budgetRequestDetails.city || ''}
              error={budgetRequestDetails.city === ''}
              helperText={
                budgetRequestDetails.city === '' && t('forms.mandatoryField')
              }
            />
          </Box>
          <Box
            sx={{
              marginTop: 3,
            }}
          >
            <Typography fontWeight={600}>
              {t('budgetRequest.addProductsToRequest')}
            </Typography>
            <Typography
              variant='xsmall'
              marginTop={0.5}
            >
              {t('budgetRequest.addProductsToRequestDescription')}
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gap: 2.5,
                marginTop: 3,
                '.MuiTextField-root': {
                  marginTop: 0,
                },
              }}
            >
              {budgetRequestDetails.products.map((product, index) => (
                <Box
                  key={`productList-${index}`}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >

                  <Autocomplete
                    disableClearable
                    freeSolo
                    filterOptions={(options) => options}
                    inputValue={
                      product.reference
                        ? `${product.reference}${
                          product.title ? ' - ' + product.title : ''
                        }`
                        : ''
                    }
                    onInputChange={(e, newInputValue) => {
                      getOptionsDebounced(newInputValue);
                      setBudgetRequestDetails({
                        ...budgetRequestDetails,
                        products: budgetRequestDetails.products.map(
                          (element, elementIndex) =>
                            elementIndex === index
                              ? {
                                reference: newInputValue,
                              }
                              : element
                        ),
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={product.reference}
                        label={t('forms.referenceAndDesignation')}
                        sx={{color: 'black', marginTop: 0}}
                      />
                    )}
                    options={shownProducts}
                    getOptionLabel={(option) => option?.title || ''}
                    renderOption={(params, {title, productObject}) => (
                      <MenuItem
                        {...params}
                        component={Button}
                        title={productObject}
                        onClick={() => {
                          setBudgetRequestDetails({
                            ...budgetRequestDetails,
                            products: budgetRequestDetails.products.map(
                              (element, elementIndex) =>
                                elementIndex === index
                                  ? {
                                    ...productObject,
                                    quantity: {
                                      ...productObject.quantity,
                                      [SETTINGS.defaults.quantityType]: 1,
                                    },
                                  }
                                  : element
                            ),
                          });
                          //  reset search suggestions
                          setShownProducts([]);
                        }}
                        sx={{
                          width: '100%',
                          fontSize: '0.95rem',
                        }}
                      >

                        <Box
                          component='img'
                          src={productObject.images[0]}
                          alt={productObject.title}
                          onError={(e) => {
                            imageOnError(e);
                          }}
                          sx={{
                            aspectRatio: '1',
                            objectFit: 'contain',
                            width: '35px',
                            padding: theme.spacing(0.3),
                            backgroundColor: 'white',
                            borderRadius: theme.shape.borderRadius / 10,
                            border: `1px solid ${THEME_COLORS.gray}`
                          }}
                        />

                        <Box>
                          <Typography fontWeight={FONT_STYLE.semibold} paddingLeft='2px'> {title}</Typography>
                        </Box>
                      </MenuItem>
                    )}
                    sx={{
                      flexBasis: '85%',
                    }}
                  />
                  <TextField
                    id='product-quantity'
                    label={t('forms.quantity')}
                    required
                    disabled={!!!product.id}
                    value={
                      product.quantity?.[SETTINGS.defaults.quantityType] || ''
                    }
                    onChange={(e) =>
                      setBudgetRequestDetails({
                        ...budgetRequestDetails,
                        products: budgetRequestDetails.products.map(
                          (element, elementIndex) =>
                            elementIndex === index
                              ? {
                                ...element,
                                quantity: {
                                  ...element.quantity,
                                  [SETTINGS.defaults.quantityType]: Number(
                                    e.target.value
                                  ),
                                },
                              }
                              : element
                        ),
                      })
                    }
                    onBlur={(e) => {
                      if (Number(e.target.value) === 0) {
                        setBudgetRequestDetails({
                          ...budgetRequestDetails,
                          products: budgetRequestDetails.products.map(
                            (element, elementIndex) =>
                              elementIndex === index
                                ? {
                                  ...element,
                                  quantity: {
                                    ...element.quantity,
                                    [SETTINGS.defaults.quantityType]: 1,
                                  },
                                }
                                : element
                          ),
                        });
                      }
                    }}
                    sx={{
                      textAlign: 'center',
                      flexBasis: '15%',
                    }}
                  />
                  <TextField
                    id='product-size'
                    label={t('forms.size')}
                    required
                    disabled={!!!product.id}
                    inputProps={{maxLength:3}}
                    onChange={(e) =>
                      setBudgetRequestDetails({
                        ...budgetRequestDetails,
                        products: budgetRequestDetails.products.map(
                          (element, elementIndex) =>
                            elementIndex === index
                              ? {
                                ...element,
                                size: {
                                  ...element.size,
                                  [SETTINGS.defaults.quantityType]: e.target.value,
                                },
                              }
                              : element
                        ),
                      })
                    }
                    onBlur={(e) => {
                      if (Number(e.target.value) === 0) {
                        setBudgetRequestDetails({
                          ...budgetRequestDetails,
                          products: budgetRequestDetails.products.map(
                            (element, elementIndex) =>
                              elementIndex === index
                                ? {
                                  ...element,
                                  size: {
                                    ...element.size,
                                    [SETTINGS.defaults.quantityType]: '',
                                  },
                                }
                                : element
                          ),
                        });
                      }
                    }}
                    sx={{
                      textAlign: 'center',
                      flexBasis: '15%',
                    }}
                  />
                  <IconButton
                    title='Remover'
                    disabled={index === 0 ? true : false}
                    onClick={() =>
                      setBudgetRequestDetails({
                        ...budgetRequestDetails,
                        products: budgetRequestDetails.products.filter(
                          (elm, elementIndex) => elementIndex !== index
                        ),
                      })
                    }
                  >
                    <FiTrash2 size={16}/>
                  </IconButton>
                </Box>
              ))}
            </Box>
            <Button
              title={t('forms.addProduct')}
              variant='sizeSmall'
              disabled={budgetRequestDetails.products.length === 4}
              onClick={() =>
                setBudgetRequestDetails({
                  ...budgetRequestDetails,
                  products: [...budgetRequestDetails.products, {}],
                })
              }
              sx={{
                fontWeight: FONT_STYLE.medium,
                color: THEME_COLORS.primary,
                marginTop: 1,
              }}
            >
              {t(`forms.addProduct`)}
            </Button>
          </Box>
          {/*
                    <TextField title={t('forms.uploadFile')}
                               type="file"
                               fullWidth
                    />
                    */}
          <TextField
            id='notes'
            label={t('forms.observations')}
            multiline
            rows={3}
            fullWidth
            onChange={handleChange}
            value={budgetRequestDetails.notes || ''}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 3,
              marginTop: 3,
            }}
          >
            <LoadingButton
              variant='contained'
              type='submit'
              title={t('forms.submitRequest')}
              loading={isLoading}
              disabled={!!!budgetRequestDetails.products?.[0].id}
            >
              {t('forms.submitRequest')}
            </LoadingButton>
          </Box>
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Box
          component='form'
          onSubmit={handleConfirmOrder}
        >
          <Box
            sx={{
              marginTop: 3,
            }}
          >
            <Typography fontWeight={600}>
              {t('budgetRequest.addProductsToRequest')}
            </Typography>
            <Typography
              variant='xsmall'
              marginTop={0.5}
            >
              {t('budgetRequest.addProductsToRequestDescription')}
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gap: 2.5,
                marginTop: 3,
                '.MuiTextField-root': {
                  marginTop: 0,
                },
              }}
            >
              {budgetRequestDetails.products.map((product, index) => (
                <Box
                  key={`productList-${index}`}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Autocomplete
                    disableClearable
                    freeSolo
                    filterOptions={(options) => options}
                    inputValue={
                      product.reference
                        ? `${product.reference}${
                          product.title ? ' - ' + product.title : ''
                        }`
                        : ''
                    }
                    onInputChange={(e, newInputValue) => {
                      getOptionsDebounced(newInputValue);
                      setBudgetRequestDetails({
                        ...budgetRequestDetails,
                        products: budgetRequestDetails.products.map(
                          (element, elementIndex) =>
                            elementIndex === index
                              ? {
                                reference: newInputValue,
                              }
                              : element
                        ),
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={product.reference}
                        label={t('forms.referenceAndDesignation')}
                        sx={{color: 'black', marginTop: 0}}
                      />
                    )}
                    options={shownProducts}
                    getOptionLabel={(option) => option?.title || ''}
                    renderOption={(params, {title, productObject}) => (
                      <MenuItem
                        {...params}
                        component={Button}
                        title={productObject}
                        onClick={() => {
                          setBudgetRequestDetails({
                            ...budgetRequestDetails,
                            products: budgetRequestDetails.products.map(
                              (element, elementIndex) =>
                                elementIndex === index
                                  ? {
                                    ...productObject,
                                    quantity: {
                                      ...productObject.quantity,
                                      [SETTINGS.defaults.quantityType]: 1,
                                    },
                                  }
                                  : element
                            ),
                          });
                          //  reset search suggestions
                          setShownProducts([]);
                        }}
                        sx={{
                          width: '100%',
                          fontSize: '0.95rem',
                        }}
                      >

                        <Box
                          component='img'
                          src={productObject.images[0]}
                          alt={productObject.title}
                          onError={(e) => {
                            imageOnError(e);
                          }}
                          sx={{
                            aspectRatio: '1',
                            objectFit: 'contain',
                            width: '35px',
                            padding: theme.spacing(0.3),
                            backgroundColor: 'white',
                            borderRadius: theme.shape.borderRadius / 10,
                            border: `1px solid ${THEME_COLORS.gray}`
                          }}
                        />

                        <Box>
                          <Typography fontWeight={FONT_STYLE.semibold} paddingLeft='2px'> {title}</Typography>
                        </Box>
                      </MenuItem>
                    )}
                    sx={{
                      flexBasis: '85%',
                    }}
                  />
                  <TextField
                    id='product-quantity'
                    label={t('forms.quantity')}
                    required
                    disabled={!!!product.id}
                    value={
                      product.quantity?.[SETTINGS.defaults.quantityType] || ''
                    }
                    onChange={(e) =>
                      setBudgetRequestDetails({
                        ...budgetRequestDetails,
                        products: budgetRequestDetails.products.map(
                          (element, elementIndex) =>
                            elementIndex === index
                              ? {
                                ...element,
                                quantity: {
                                  ...element.quantity,
                                  [SETTINGS.defaults.quantityType]: Number(
                                    e.target.value
                                  ),
                                },
                              }
                              : element
                        ),
                      })
                    }
                    onBlur={(e) => {
                      if (Number(e.target.value) === 0) {
                        setBudgetRequestDetails({
                          ...budgetRequestDetails,
                          products: budgetRequestDetails.products.map(
                            (element, elementIndex) =>
                              elementIndex === index
                                ? {
                                  ...element,
                                  quantity: {
                                    ...element.quantity,
                                    [SETTINGS.defaults.quantityType]: 1,
                                  },
                                }
                                : element
                          ),
                        });
                      }
                    }}
                    sx={{
                      textAlign: 'center',
                      flexBasis: '15%',
                    }}
                  />
                  <TextField
                    id='product-size'
                    label={t('forms.size')}
                    required
                    disabled={!!!product.id}
                    inputProps={{maxLength:3}}
                    onChange={(e) =>
                      setBudgetRequestDetails({
                        ...budgetRequestDetails,
                        products: budgetRequestDetails.products.map(
                          (element, elementIndex) =>
                            elementIndex === index
                              ? {
                                ...element,
                                size: {
                                  ...element.size,
                                  [SETTINGS.defaults.quantityType]: e.target.value,
                                },
                              }
                              : element
                        ),
                      })
                    }
                    onBlur={(e) => {
                      if (Number(e.target.value) === 0) {
                        setBudgetRequestDetails({
                          ...budgetRequestDetails,
                          products: budgetRequestDetails.products.map(
                            (element, elementIndex) =>
                              elementIndex === index
                                ? {
                                  ...element,
                                  size: {
                                    ...element.size,
                                    [SETTINGS.defaults.quantityType]: '',
                                  },
                                }
                                : element
                          ),
                        });
                      }
                    }}
                    sx={{
                      textAlign: 'center',
                      flexBasis: '15%',
                    }}
                  />
                  <IconButton
                    title='Remover'
                    disabled={index === 0 ? true : false}
                    onClick={() =>
                      setBudgetRequestDetails({
                        ...budgetRequestDetails,
                        products: budgetRequestDetails.products.filter(
                          (elm, elementIndex) => elementIndex !== index
                        ),
                      })
                    }
                  >
                    <FiTrash2 size={16}/>
                  </IconButton>
                </Box>
              ))}
            </Box>
            <Button
              title={t('forms.addProduct')}
              variant='sizeSmall'
              disabled={budgetRequestDetails.products.length === 4}
              onClick={() =>
                setBudgetRequestDetails({
                  ...budgetRequestDetails,
                  products: [...budgetRequestDetails.products, {}],
                })
              }
              sx={{
                fontWeight: FONT_STYLE.medium,
                color: THEME_COLORS.primary,
                marginTop: 1,
              }}
            >
              {t(`forms.addProduct`)}
            </Button>
          </Box>

          <TextField
            id='notes'
            label={t('forms.observations')}
            multiline
            rows={3}
            fullWidth
            onChange={handleChange}
            value={budgetRequestDetails.notes || ''}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 3,
              marginTop: 3,
            }}
          >
            <LoadingButton
              variant='contained'
              type='submit'
              title={t('forms.submitRequest')}
              loading={isLoading}
              disabled={!!!budgetRequestDetails.products?.[0].id}
            >
              {t('forms.submitRequest')}
            </LoadingButton>
          </Box>
        </Box>
      </>
    );
  }
};

export default BudgetRequestForm;

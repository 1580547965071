import {
  Container,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import AccountNavBar from '../components/UserAccount/AccountNavBar';
import { MainContainer } from '../styles';
import { FONT_STYLE } from '../variables';

export const MyAccountTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  fontWeight: FONT_STYLE.bold,
  textTransform: 'uppercase',
  marginBottom: theme.spacing(1)
}));

const AccountPage = () => {
  const theme = useTheme();
  const SMALL_DEVICES = useMediaQuery(theme.breakpoints.down('md'));

  const userRole = useSelector((state) => state.user.role);

  return (
    <MainContainer>
      <Container>
        <Grid container>
          <Grid md={3} lg={2}>
            <AccountNavBar SMALL_DEVICES={SMALL_DEVICES} userRole={userRole} />
          </Grid>
          <Grid xs={12} md={9} lg={9.5} lgOffset={0.5}>
            <Outlet context={[SMALL_DEVICES]} />
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default AccountPage;

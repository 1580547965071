import {
  Box,
  Button,
  OutlinedInput,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { FONT_STYLE, THEME_COLORS } from '../../variables';

const QuantityInput = styled(OutlinedInput)(
  ({ hasproducttypes, openfielddisabled, stock, variant }) => ({
    fontSize: '0.85rem',
    width:
      hasproducttypes === 'true'
        ? '68px'
        : variant === 'size'
          ? '28px'
          : '38px',
    height: variant === 'size' ? '20px' : '34px',
    pointerEvents: openfielddisabled === 'true' ? 'none' : 'auto',
    borderRadius: 0,
    borderBottom:
      variant === 'size' &&
      (stock <= 0
        ? `3px solid ${THEME_COLORS.red}`
        : stock >= 1 && stock <= 2
          ? `3px solid ${THEME_COLORS.orange}`
          : `3px solid ${THEME_COLORS.green}`),

    '.MuiInputBase-input': {
      maxWidth: hasproducttypes === 'true' ? 'calc(100% - 30px)' : '100%',
      textAlign: ' center',
      padding: '0 !important'
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important'
    }
  })
);

const QuantitySelectorButton = styled(Button)(() => ({
  fontSize: '1.25rem',
  fontWeight: FONT_STYLE.semibold,
  minWidth: '20px',
  minHeight: '20px',
  padding: 0
}));

const QuantityInputContainer = styled(Box)(
  ({ theme, isoutofstock, variant }) => ({
    position: 'relative',
    overflow: 'hidden',
    border: `1px solid ${THEME_COLORS.gray}`,
    borderRadius: variant === 'default' ? theme.shape.borderRadius : 0,
    borderBottom:
      isoutofstock === 'true' && variant === 'default'
        ? `2px solid ${THEME_COLORS.red}`
        : null
  })
);

const QuantityTypeContainer = styled(Box)(() => ({
  minWidth: '30px',
  height: '100%',
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  borderLeft: `1px solid ${THEME_COLORS.gray}`
}));

const QuantityTypeButton = styled(Button)(({ selected }) => ({
  padding: 0,
  minWidth: 0,
  height: 'inherit',
  fontSize: '9px',
  fontWeight: selected ? 700 : 500,
  borderRadius: 0,
  '&:disabled, &': {
    backgroundColor: selected ? THEME_COLORS.gray : THEME_COLORS.lightGray,
    color: 'black'
  }
}));

const QuantitySelector = ({
  quantity,
  increment,
  decrement,
  incrementDisabled,
  decrementDisabled,
  openFieldDisabled,
  openFieldChange,
  hasProductTypes = false,
  quantityType,
  setQuantityType,
  isOutOfStock,
  minQuantity,
  variant,
  label,
  stock,
  _isLoading
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isTaxQuantity =
    (Number(label) >= 47 && Number(label) <= 52) ||
    label === '4XL' ||
    label === '5XL' ||
    label === '6XL';

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 1
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 0.5
        }}
      >
        {variant !== 'size' && (
          <QuantitySelectorButton
            title={t('product.decreaseQuantity')}
            disabled={decrementDisabled}
            onClick={() => decrement()}
          >
            -
          </QuantitySelectorButton>
        )}

        <QuantityInputContainer
          isoutofstock={isOutOfStock ? 'true' : 'false'}
          variant={variant}
        >
          {variant === 'size' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '0.8rem',
                fontWeight: FONT_STYLE.semibold,
                paddingX: 0.25,
                height: '20px',
                color: isTaxQuantity ? 'white' : 'black',
                backgroundColor: isTaxQuantity ? 'black' : THEME_COLORS.gray
              }}
            >
              {label}
            </Box>
          )}
          <QuantityInput
            id='quantity-selector'
            inputProps={{
              maxLength: 4
            }}
            required
            disabled={openFieldDisabled}
            value={quantity}
            onKeyDown={(event) =>
              //  only accept 'Backspace' AND numbers between '0-9'
              event.code !== 'Backspace' &&
              !(Number(event.key) >= 0 && Number(event.key) <= 9) &&
              event.preventDefault()
            }
            onChange={({ target: { value } }) =>
              Number(value) !== quantity && openFieldChange(value)
            }
            hasproducttypes={hasProductTypes ? 'true' : 'false'}
            openfielddisabled={openFieldDisabled ? 'true' : 'false'}
            stock={stock}
            variant={variant}
          />
          {hasProductTypes && (
            <QuantityTypeContainer>
              <QuantityTypeButton
                title={t('product.unit')}
                disabled={quantityType === 'unit'}
                selected={quantityType === 'unit'}
                onClick={() => setQuantityType('unit')}
                sx={{
                  borderTopRightRadius: theme.shape.borderRadius
                }}
              >
                UN
              </QuantityTypeButton>
              <QuantityTypeButton
                title={t('product.box')}
                disabled={quantityType === 'box'}
                selected={quantityType === 'box'}
                onClick={() => setQuantityType('box')}
                sx={{
                  borderBottomRightRadius: theme.shape.borderRadius
                }}
              >
                CX
              </QuantityTypeButton>
            </QuantityTypeContainer>
          )}
        </QuantityInputContainer>

        {variant !== 'size' && (
          <QuantitySelectorButton
            disabled={incrementDisabled}
            title={t('product.increaseQuantity')}
            onClick={() => increment()}
          >
            +
          </QuantitySelectorButton>
        )}
      </Box>
      {!!minQuantity && (
        <Typography variant='xxsmall'>
          <Trans i18nKey='product.minQuantity' values={{ days: minQuantity }} />
        </Typography>
      )}
    </Box>
  );
};

export default QuantitySelector;

import { Box, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getBrands } from '../api';
import BrandsCard from '../components/BrandsCard';
import { MainContainer } from '../styles';

const BrandsPage = () => {
  const { t } = useTranslation();
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    getBrands().then((brands) => {
      setBrands(brands);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainContainer>
      <Container>
        <Typography
          marginBottom={2}
          variant='h1'
          sx={{ textAlign: 'center' }}
        >
          {t('brandPage.title')}
        </Typography>

        <Typography
          marginBottom={2}
          sx={{ whiteSpace: 'pre-line', textAlign: 'center' }}
        >
          {t('brandPage.text')}{' '}
        </Typography>

        <Box sx={{ paddingY: { xs: 2.5, md: 4 } }}>
          <Grid container>
            {brands.map((brand) => (
              <Grid
                key={`brand-${brand._id}`}
                xs={6}
                sm={3}
                md={2}
              >
                <BrandsCard brand={brand} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </MainContainer>
  );
};

export default BrandsPage;

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../constants/utils';
import { FONT_STYLE, THEME_COLORS } from '../variables';
import Quantity from './Quantity/Quantity';

const QuantitiesQuickAdd = ({
  variants,
  setUpdatedProductQuantity,
  subtotalUnit,
  subtotalBox,
  stockAvailable,
}) => {
  const { t } = useTranslation();
  return (
    <TableContainer
      component={Paper}
      variant='unstyled'
    >
      <Table
        size='small'
        id='quantity-variants'
      >
        <TableHead>
          <TableRow>
            <TableCell>{t('tables.selectQuantity')}</TableCell>
            <TableCell>{t('tables.price')}</TableCell>
            <TableCell>{t('tables.subtotal')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {variants.map((variant, index) => (
            <TableRow key={`quantity-variants-${index}`}>
              <TableCell
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 1.5,
                  fontSize: '0.8rem',
                }}
              >
                {variant.sellsUnit === true
                  ? t('product.unit')
                  : t('product.box')}
                <Quantity
                  product={variant}
                  setUpdatedProductQuantity={setUpdatedProductQuantity}
                  stockAvailable={
                    stockAvailable !== 'undefined'
                      ? stockAvailable
                      : variant.stock
                  }
                  isProductVariant
                />
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 'inherit',
                      color: variant.onSale ? THEME_COLORS.darkGray : 'black',
                      textDecoration: variant.onSale && 'line-through',
                    }}
                  >
                    {formatCurrency(variant.price)}/un
                  </Typography>

                  {variant.onSale && (
                    <Typography
                      sx={{
                        fontSize: 'inherit',
                        color: THEME_COLORS.red,
                        fontWeight: variant.onSale && FONT_STYLE.bold,
                      }}
                    >
                      {formatCurrency(variant.salePrice)}/un
                    </Typography>
                  )}
                </Box>
              </TableCell>
              <TableCell>
                {variant.sellsUnit === true
                  ? formatCurrency(subtotalUnit)
                  : formatCurrency(subtotalBox)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(QuantitiesQuickAdd);

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  styled,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronDown } from 'react-icons/fi';
import { Link as RouterLink } from 'react-router-dom';
import { COMPANY_INFO, FONT_STYLE, FOOTER_MENUS } from '../../variables';
import { FooterContactsContent } from './ContactUs';

const StyledAccordion = styled(Accordion)(() => ({
  color: 'white',
  borderBottom: '1px solid rgba(255,255,255,0.5)',
  '&:last-of-type': {
    borderBottom: 0
  }
}));

const StyledLabel = styled(Typography)(() => ({
  fontWeight: FONT_STYLE.bold,
  textAlign: { xs: 'center', md: 'initial' },
  textTransform: 'uppercase',
  letterSpacing: '0.5px'
}));

const StyledLink = styled(Typography)(({ theme }) => ({
  display: 'block',
  color: 'inherit',
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5)
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  paddingLeft: 0,
  paddingRight: 0,
  '.MuiAccordionSummary-expandIconWrapper': {
    color: 'inherit'
  }
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(3)}`
}));

const FooterMobileAccordion = () => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (e, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const data = [
    {
      id: 'aboutUs',
      title: COMPANY_INFO.name,
      isContacts: false,
      content: FOOTER_MENUS.menu1
    },
    {
      id: 'customerSupport',
      title: t('app.informations'),
      isContacts: false,
      content: FOOTER_MENUS.menu2
    },
    {
      id: 'contactUs',
      title: t('app.contactUs'),
      isContacts: true,
      content: <FooterContactsContent />
    }
  ];

  return (
    <Box sx={{ marginTop: 2.5 }}>
      {data.map((dataItem, index) => (
        <StyledAccordion
          key={`footerMobile-${index}`}
          disableGutters
          expanded={expanded === dataItem.id}
          onChange={handleChange(dataItem.id)}
        >
          <StyledAccordionSummary expandIcon={<FiChevronDown />}>
            <StyledLabel>{dataItem.title}</StyledLabel>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            {!dataItem.isContacts
              ? dataItem.content.map((menuItem, index) => (
                  <StyledLink
                    key={`footer-menu-${menuItem.title}-${index}`}
                    component={menuItem.internalLink ? RouterLink : 'a'}
                    to={menuItem.internalLink ? menuItem.url : null}
                    href={!menuItem.internalLink ? menuItem.url : null}
                    target={!menuItem.internalLink ? menuItem.target : null}
                    title={t(menuItem.title)}
                    variant='link'
                    marginBottom={0.5}
                  >
                    {t(menuItem.title)}
                  </StyledLink>
                ))
              : dataItem.content}
          </StyledAccordionDetails>
        </StyledAccordion>
      ))}
    </Box>
  );
};

export default FooterMobileAccordion;

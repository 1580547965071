import { Box, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import BudgetRequestForm from '../components/BudgetRequest/BudgetRequestForm';
import { MainContainer } from '../styles';

const BudgetRequest = () => {
  const { t } = useTranslation();
  return (
    <MainContainer>
      <Container>
        <Grid container>
          <Grid xs={12}>
            <Box
              sx={{
                textAlign: 'center'
              }}
            >
              <Typography variant='h1'>{t('budgetRequest.title')}</Typography>
              <Typography marginTop={2}>
                {t('budgetRequest.description')}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={6} mdOffset={3}>
            <BudgetRequestForm />
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default BudgetRequest;

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import NewAddressModal from '../../UserAccount/AccountAddresses/NewAddressModal';

const DeliveryAtAddress = ({
  role,
  clientAddresses,
  orderDetails,
  setOrderDetails,
}) => {
  const { t } = useTranslation();
  const [isAddAddressOpen, setIsAddAddressOpen] = useState(false);

  return (
    <>
      <FormControl
        margin='normal'
        sx={{ marginY: 2 }}
      >
        <InputLabel>{t('checkout.selectAddress')}</InputLabel>

        <Select
          label={t('checkout.selectAddress')}
          value={orderDetails.deliveryAtAddress.street}
          onChange={(e, { props: { index } }) =>
            setOrderDetails((values) => ({
              ...values,
              deliveryAtAddress: clientAddresses[index],
            }))
          }
        >
          {clientAddresses.map(({ street }, index) => (
            <MenuItem
              key={`${street}-${index}`}
              index={index}
              value={street}
            >
              {street}
            </MenuItem>
          ))}
        </Select>

        {role !== 'seller' && (
          <Button
            variant='outlined'
            value='addNewAddress'
            title={t('checkout.addAddress')}
            onClick={() => setIsAddAddressOpen(true)}
            endIcon={<FiPlus size={14} />}
            sx={{
              alignSelf: ' flex-start',
              marginTop: 2,
            }}
          >
            {t('checkout.addAddress')}
          </Button>
        )}
      </FormControl>

      {isAddAddressOpen && (
        <NewAddressModal
          isOpen={isAddAddressOpen}
          setIsOpen={setIsAddAddressOpen}
        />
      )}
    </>
  );
};

export default DeliveryAtAddress;

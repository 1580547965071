import { kebabCase } from 'lodash';

export * from './checkStockAvailableInCart';
export * from './compare';
export * from './defaultImage';
export * from './hooks/index';
export * from './objectIsEmpty';
export * from './slugify';
export * from './splitString';

export const generateRandomStringAndNumbers = (length) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const generateRandomPrice = () => {
  const precision = 100; // 2 decimals
  const randomPrice =
    Math.floor(
      Math.random() * (75 * precision - 1 * precision) + 1 * precision
    ) /
    (1 * precision);
  return randomPrice;
};

export const postalCodePatternIsValid = (postalCode) => {
  const postalCodePatternFormat = /[0-9]{4}-[0-9]{3}$/;
  const postalCodePatternIsValid = postalCodePatternFormat.test(postalCode);
  return postalCodePatternIsValid;
};

export const getCookie = (name) => {
  let value = `; ${document.cookie}`;
  let parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export const generateBreadcrumbsPath = (categories, slugParamsArray) => {
  return slugParamsArray
    .filter((param) => !!param)
    .reduce((acc, slug, index) => {
      index === 0 //  collectionSlug
        ? acc.push(
            categories.find((cat) => kebabCase(cat.title) === kebabCase(slug))
          )
        : index === 1 //  categorySlug
          ? acc.push(
              acc?.[0]?.categories.find(
                (cat) => kebabCase(cat.title) === kebabCase(slug)
              )
            )
          : //  subcategorySlug
            acc.push(
              acc?.[1]?.subcategories.find(
                (cat) => kebabCase(cat.title) === kebabCase(slug)
              )
            );
      return acc;
    }, [])
    .reduce(
      (acc, cat, index) => [
        ...acc,
        {
          title: cat?.title,
          slug: index === 0 ? `/${cat?.url}` : cat?.url
        }
      ],
      [
        {
          title: 'Homepage',
          slug: '/'
        }
      ]
    );
};

import { ThemeProvider } from '@mui/material';
import React from 'react';
import { Cookies, CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes
} from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import Login from './components/Login/Login';
import PasswordRecovery from './components/Login/PasswordRecovery';
import UserRegisterForm from './components/Login/UserRegisterForm';
import AccountAccess from './components/UserAccount/AccountAccess/AccountAccess';
import AccountAddresses from './components/UserAccount/AccountAddresses/AccountAddresses';
import AccountInfo from './components/UserAccount/AccountInfo/AccountInfo';
import AccountPurchases from './components/UserAccount/AccountPurchases/AccountPurchases';
import AccountPurchasesDetail from './components/UserAccount/AccountPurchases/AccountPurchasesDetail';
import AccountReceipts from './components/UserAccount/AccountReceipts/AccountReceipts';
import AccountUsers from './components/UserAccount/AccountUsers/AccountUsers';
import ProductsHistory from './components/UserAccount/ProductsHistory/ProductsHistory';
import ShoppingLists from './components/UserAccount/ShoppingLists/ShoppingLists';
import { ProductsListProvider } from './contexts/ProductsListContext';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AccountPage from './routes/AccountPage';
import BrandPage from './routes/BrandPage';
import BrandsPage from './routes/BrandsPage';
import BudgetRequest from './routes/BudgetRequest';
import Cart from './routes/Cart';
import Checkout from './routes/Checkout';
import ContactsPage from './routes/Contacts';
import HomePage from './routes/HomePage';
import InstitucionalPages from './routes/InstitucionalPages';
import LatestProducts from './routes/LatestProducts';
import LoginPage from './routes/LoginPage';
import NotFoundPage from './routes/NotFoundPage';
import OrderConfirmation from './routes/OrderConfirmation';
import OrderSteps from './routes/OrderSteps';
import ProductPage from './routes/ProductPage';
import ProductsPage from './routes/ProductsPage';
import SearchPage from './routes/SearchPage';
import StocksList from './routes/StocksList';
import store from './state/store';
import { theme } from './styles';
import { PAGES_SLUGS, SETTINGS } from './variables';

//  used to control when 'order confirmation' page is showed or not
window.localStorage.setItem('orderConfirmed', false);

const ProtectedRoute = ({ children }) => {
  const authentication = new Cookies().get('userToken');

  if (!authentication) {
    return <Navigate to={PAGES_SLUGS.login} replace />;
  }

  return children ? children : <Outlet />;
};

const OrderConfirmationControl = () => {
  const orderIsConfirmed = window.localStorage.getItem('orderConfirmed');
  return orderIsConfirmed === 'true' ? (
    <OrderConfirmation />
  ) : (
    <Navigate to='/' />
  );
};

let persistor = persistStore(store);
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Routes>
                <Route path='/' element={<App />}>
                  <Route index element={<HomePage />} />
                  {/* LOGIN ROUTES */}
                  <Route path={PAGES_SLUGS.login} element={<LoginPage />}>
                    <Route index element={<Login />} />
                    <Route
                      path={PAGES_SLUGS.recoverPassword}
                      element={<PasswordRecovery />}
                    />
                    <Route
                      path={PAGES_SLUGS.createUser}
                      element={<UserRegisterForm />}
                    />
                  </Route>
                  <Route
                    path={PAGES_SLUGS.search}
                    element={
                      <ProductsListProvider>
                        <SearchPage />
                      </ProductsListProvider>
                    }
                  />
                  <Route path={PAGES_SLUGS.news} element={<LatestProducts />} />
                  <Route
                    path={PAGES_SLUGS.budgetRequest}
                    element={<BudgetRequest />}
                  />
                  <Route
                    path={PAGES_SLUGS.contacts}
                    element={<ContactsPage />}
                  />
                  <Route element={<ProtectedRoute />}>
                    <Route
                      // path={PAGES_SLUGS.cart}
                      element={<OrderSteps />}
                    >
                      <Route index path={PAGES_SLUGS.cart} element={<Cart />} />
                      <Route
                        path={PAGES_SLUGS.checkout}
                        element={<Checkout />}
                      />
                      <Route
                        path={PAGES_SLUGS.orderConfirmation}
                        element={<OrderConfirmationControl />}
                      />
                    </Route>
                    <Route path={PAGES_SLUGS.account} element={<AccountPage />}>
                      <Route
                        path={PAGES_SLUGS.myAccount.info}
                        element={<AccountInfo />}
                      />
                      <Route
                        path={PAGES_SLUGS.myAccount.accessData}
                        element={<AccountAccess />}
                      />
                      <Route
                        path={PAGES_SLUGS.myAccount.addresses}
                        element={<AccountAddresses />}
                      />
                      <Route path={PAGES_SLUGS.myAccount.orders}>
                        <Route index element={<AccountPurchases />} />
                        <Route
                          path={`:orderReference`}
                          element={<AccountPurchasesDetail />}
                        />
                      </Route>
                      {SETTINGS.functionalities.manageUsers && (
                        <Route
                          path={PAGES_SLUGS.myAccount.users}
                          element={<AccountUsers />}
                        />
                      )}
                      <Route
                        path={PAGES_SLUGS.myAccount.shoppingList}
                        element={<ShoppingLists />}
                      />
                      {SETTINGS.functionalities.accountReceipts && (
                        <Route
                          path={PAGES_SLUGS.myAccount.receipts}
                          element={<AccountReceipts />}
                        />
                      )}
                      <Route
                        path={PAGES_SLUGS.myAccount.productsHistory}
                        element={<ProductsHistory />}
                      />
                    </Route>
                    <Route
                      path={PAGES_SLUGS.custom.pressKit}
                      element={<InstitucionalPages />}
                    />
                  </Route>
                  <Route
                    path={PAGES_SLUGS.custom.customize}
                    element={<InstitucionalPages />}
                  />
                  {/* <Route
                    path={PAGES_SLUGS.allProducts}
                    element={
                      <ProductsListProvider>
                        <AllProducts />
                      </ProductsListProvider>
                    }
                  /> */}
                  {SETTINGS.defaults.productDetail.categoriesHierarchySlug ? (
                    [
                      ':collectionSlug'
                      // ':collectionSlug/:categorySlug'
                      // ':collectionSlug/:categorySlug/:subcategorySlug',
                    ].map((path, index, array) => (
                      <Route key={`productsPage-${index}`} path={path}>
                        <Route
                          index
                          element={
                            <ProductsListProvider>
                              <ProductsPage />
                            </ProductsListProvider>
                          }
                        />
                        {array.length - 1 === index && (
                          <Route
                            path={`:productSlug`}
                            element={<ProductPage />}
                          />
                        )}
                      </Route>
                    ))
                  ) : (
                    <>
                      <Route
                        path=':collectionSlug/:categorySlug?/:subcategorySlug?'
                        element={
                          <ProductsListProvider>
                            <ProductsPage />
                          </ProductsListProvider>
                        }
                      />
                      <Route
                        path='produto/:productSlug'
                        element={<ProductPage />}
                      />
                    </>
                  )}
                  {/* CUSTOM PAGES */}
                  <Route
                    path={PAGES_SLUGS.custom.stocksList}
                    element={<StocksList />}
                  />
                  <Route
                    path={PAGES_SLUGS.custom.brands}
                    element={<BrandsPage />}
                  />
                  <Route
                    path={`${PAGES_SLUGS.custom.brands}/:brandSlug`}
                    element={<BrandPage />}
                  />
                  {/* INSTITUTIONAL PAGES */}
                  {[
                    PAGES_SLUGS.institutional.aboutUs,
                    PAGES_SLUGS.institutional.termsAndConditions,
                    PAGES_SLUGS.institutional.privacyPolicy,
                    PAGES_SLUGS.institutional.alternativeDisputeResolution,
                    PAGES_SLUGS.institutional.faqs,
                  ].map((path, index) => (
                    <Route
                      key={`institutionalPage-${index}`}
                      path={path}
                      element={<InstitucionalPages />}
                    />
                  ))}
                  <Route
                    path={PAGES_SLUGS.notFound}
                    element={<NotFoundPage />}
                  />
                  <Route
                    path='*'
                    element={<Navigate replace to={PAGES_SLUGS.notFound} />}
                  />
                </Route>
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

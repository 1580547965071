function formatCurrency(number) {
  return `€${number
    ?.toLocaleString('pt-PT', {
      style: 'currency',
      currency: 'EUR',
    })
    .slice(0, -2)}`;
}

const priceCalculations = (calculations) =>
  parseFloat(Math.fround(calculations).toFixed(2));

function isValidUrl(categoryUrl, subcategoryUrl, productTypeUrls, categories) {
  const validCategory = categories.find(({ url }) => url === categoryUrl);

  const validSubcategory = categories.subcategories.find(
    ({ url }) => url === subcategoryUrl
  );

  if (!subcategoryUrl || !productTypeUrls) {
    return Boolean(validCategory);
  }

  return (
    validCategory &&
    validSubcategory &&
    productTypeUrls.every((productTypeUrl) =>
      validSubcategory.productTypes.some(({ url }) => url === productTypeUrl)
    )
  );
}

function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export { formatCurrency, isObjectEmpty, isValidUrl, priceCalculations };

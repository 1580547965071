import { LoadingButton } from '@mui/lab';
import { Box, Button, MenuItem, Select, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { addProductToShoppingList } from '../../../api/user';
import { PopupNotificationContext } from '../../../contexts/PopupNotificationContext';
import { setShoppingLists } from '../../../state/shoppingListsSlice';
import { PAGES_SLUGS } from '../../../variables';
import Modal, { ModalActions, ModalContent, ModalTitle } from '../../Modal';

const ShoppingListSelectorModal = ({
  isOpen,
  setIsOpen,
  shoppingLists,
  productId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setNotificationProps } = useContext(PopupNotificationContext);

  const [isLoading, setIsLoading] = useState(false);
  const [selectShoppingList, setSelectShoppingList] = useState(
    shoppingLists?.[0]?._id
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    //  check IF product added already exists in this list
    const productAlreadyExists = shoppingLists
      .filter((list) => list._id === selectShoppingList)?.[0]
      ?.products?.find((product) => product?._id === productId);

    //  IF product exists » show message and return
    if (productAlreadyExists) {
      setNotificationProps({
        isOpened: true,
        type: 'warning',
        message: t('myAccount.shoppingLists.productAlreadyExistsMessage'),
      });
      setIsLoading(false);
      setIsOpen(false);
      return;
    }

    addProductToShoppingList(selectShoppingList, productId)
      .then((lists) => {
        setIsLoading(false);
        setIsOpen(false);
        dispatch(setShoppingLists(lists));
        setNotificationProps({
          isOpened: true,
          type: 'success',
          message: t(
            'myAccount.shoppingLists.productAddedToShoppingListMessage'
          ),
        });
      })
      .catch(
        ({
          response: {
            status,
            data: { msg },
          },
        }) => {
          setIsLoading(false);
          if (status === 401) {
            navigate(PAGES_SLUGS.login, { replace: true });
          } else if (status === 400 || status > 401) {
            setNotificationProps({
              isOpened: true,
              type: 'error',
              message: msg,
            });
          }
        }
      );
  };

  return (
    <Modal
      isOpened={isOpen}
      setIsOpened={() => setIsOpen(false)}
      muiComponentNativeProps={{
        PaperProps: {
          sx: {
            maxWidth: '400px',
          },
        },
      }}
    >
      <ModalTitle>{t('myAccount.shoppingLists.selectShoppingList')}</ModalTitle>

      {selectShoppingList ? (
        <Box
          component='form'
          onSubmit={handleSubmit}
        >
          <ModalContent>
            <Select
              fullWidth
              value={selectShoppingList}
              onChange={(e) => setSelectShoppingList(e.target.value)}
            >
              {shoppingLists.map(({ name, _id }) => (
                <MenuItem
                  key={_id}
                  value={_id}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </ModalContent>
          <ModalActions>
            <LoadingButton
              variant='contained'
              type='submit'
              title={t('myAccount.shoppingLists.addButton')}
              loading={isLoading}
            >
              {t('myAccount.shoppingLists.addButton')}
            </LoadingButton>
          </ModalActions>
        </Box>
      ) : (
        <>
          <ModalContent>
            <Typography variant='small'>
              {t('myAccount.shoppingLists.noListsCreated')}
            </Typography>
          </ModalContent>
          <ModalActions>
            <Button
              variant='contained'
              component={Link}
              to={`${PAGES_SLUGS.account}/${PAGES_SLUGS.myAccount.shoppingList}`}
              title={t('myAccount.shoppingLists.createShoppingList')}
            >
              {t('myAccount.shoppingLists.createShoppingList')}
            </Button>
          </ModalActions>
        </>
      )}
    </Modal>
  );
};

export default ShoppingListSelectorModal;

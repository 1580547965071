import axios from 'axios';
import { API_URL } from '../variables';
import { getHeaders } from './login';

//  GET

export function getCountries() {
  return (
    axios
      // .get(`${API_URL}/configs/countries`, getHeaders())
      .get(`${process.env.PUBLIC_URL}/coutries.json`, getHeaders())
      .then(({ data: { countries } }) => countries)
  );
}

export function getTranslations() {
  return axios
    .get(`${API_URL}/translations`, getHeaders())
    .then(({ data: { translations } }) => translations);
}

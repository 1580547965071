import { Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { MainContainer } from '../styles';

const TermsPage = ({ props, language }) => {
  const [pageContent, setPageContent] = useState(props);
  const { title, description } = pageContent;

  useEffect(() => setPageContent(props), [props]);

  return (
    <MainContainer>
      <Container>
        <Grid container>
          {title?.[language] && (
            <Grid xs={12}>
              <Typography
                variant='h1'
                sx={{ textAlign: 'center', marginBottom: 3 }}
              >
                {title?.[language]}
              </Typography>
            </Grid>
          )}
          {description?.[language] && (
            <Grid container>
              <Grid xs={12} md={10} mdOffset={1} lg={8} lgOffset={2}>
                <Typography
                  dangerouslySetInnerHTML={{ __html: description?.[language] }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default TermsPage;

import { Box, Button, Divider, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { login, verifyLoginToken } from '../../api/login';
import { getCart, getShoppingLists, setCustomer } from '../../api/user';
import { PopupNotificationContext } from '../../contexts/PopupNotificationContext';
import { setCartState } from '../../state/cartSlice';
import { setShoppingLists } from '../../state/shoppingListsSlice';
import { setSelectedClient, setUser } from '../../state/userSlice';
import { FONT_STYLE, _PAGES_SLUGS, SETTINGS } from '../../variables';
import AuthenticationCodeForm from './AuthenticationCodeForm';
import LoginForm from './LoginForm';

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setNotificationProps } = useContext(PopupNotificationContext);
  const [_cookies, setCookie] = useCookies(['userToken']);

  const [isLoading, setIsLoading] = useState(false);
  const [authCodeStep, setAuthCodeStep] = useState(false);
  const [authCode, setAuthCode] = useState('');
  const [loginData, setLoginData] = useState({
    email: '',
    password: ''
  });

  const loginStep = (postMethod) => {
    return postMethod
      .then(
        SETTINGS.functionalities.twoFactorAuthentication && !authCodeStep
          ? ({ success }) => {
              if (success) setAuthCodeStep(true);
              setIsLoading(false);
            }
          : ({ token, user, expiresIn }) => {
              const expiresInSeconds =
                parseInt(expiresIn.charAt(0)) * 24 * 60 * 60;
              let firstCustomerAvailable = null;
              const userRole = user.user_role.toLowerCase();
              const isCustomer = userRole === 'customer';
              const isSeller = userRole === 'seller';

              //  get user token
              setCookie('userToken', token, {
                path: '/',
                maxAge: expiresInSeconds,
                secure: true,
                sameSite: true
              });

              dispatch(
                setUser({
                  id: user.id,
                  isAdmin: user.isAdmin,
                  role: userRole,
                  companyInfo: user.companyInfo,
                  personalInfo: user.personalInfo,
                  sellerInfo: {
                    name: isCustomer ? user.sellerName : user.personalInfo.name,
                    email: isCustomer
                      ? user.sellerEmail
                      : user.personalInfo.email,
                    phone: isCustomer
                      ? user.sellerPhone
                      : user.personalInfo.phone,
                    paymentTime: user.payment_deadlines,
                    clients: isSeller ? user.customers : []
                  },
                  addresses: user.addresses,
                  customerDiscount: user?.customerDiscount,
                  cumulativeDiscounts: user?.cumulativeDiscounts,
                  blockedAccount: Boolean(user?.blockedAccount),
                  showBlockedAccountModal: Boolean(user?.blockedAccount)
                })
              );

              if (isSeller) {
                firstCustomerAvailable = user?.customers?.[0];
                if (!!firstCustomerAvailable) {
                  dispatch(setSelectedClient(firstCustomerAvailable?.id));
                  //  set first customer as an active » BE control
                  setCustomer({ customerId: firstCustomerAvailable?.id });
                }
              }

              const cartId = isSeller
                ? firstCustomerAvailable?.cart_id
                : user?.cart_id;

              //  get cart items
              !!cartId &&
                getCart(cartId).then(
                  ({
                    _id,
                    cart_items,
                    voucher,
                    taxes,
                    discounts,
                    total_discount,
                    subtotal,
                    total
                  }) => {
                    dispatch(
                      setCartState({
                        id: _id,
                        products: cart_items,
                        promotionalCodes: voucher,
                        discount: total_discount,
                        discounts: discounts,
                        taxes: taxes,
                        subtotal: subtotal,
                        total: total
                      })
                    );

                    //  get shipping lists
                    return getShoppingLists().then((lists) => {
                      dispatch(setShoppingLists(lists));
                      setIsLoading(false);
                    });
                  }
                );

              return navigate(window.history.length > 3 ? -1 : '/', {
                replace: true
              });
            }
      )
      .catch(({ response: { data } }) => {
        setIsLoading(false);
        setNotificationProps({
          isOpened: true,
          type: 'error',
          message: data?.msg || data?.errors?.[0]?.msg
        });
      });
  };

  const handleSubmit = {
    login: (e) => {
      e.preventDefault();

      setIsLoading(true);

      loginStep(login(loginData));
    },
    authCode: (e) => {
      e.preventDefault();

      setIsLoading(true);

      loginStep(
        verifyLoginToken({
          email: loginData.email,
          token: authCode
        })
      );
    }
  };

  return (
    <Box
      sx={{
        width: { xs: '80vw', md: '30vw' },
        marginTop: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2
      }}
    >
      {SETTINGS.functionalities.twoFactorAuthentication && authCodeStep ? (
        <>
          <Typography sx={{ textAlign: 'center', fontWeight: FONT_STYLE.bold }}>
            {t('login.authCodetitle')}
          </Typography>
          <Typography variant='small' sx={{ textAlign: 'center' }}>
            {t('login.authCodeSubtitle')}
          </Typography>

          <AuthenticationCodeForm
            authCode={authCode}
            setAuthCode={setAuthCode}
            handleSubmit={handleSubmit.authCode}
            isLoading={isLoading}
          />
        </>
      ) : (
        <>
          <Typography sx={{ textAlign: 'center', fontWeight: FONT_STYLE.bold }}>
            {t('login.title')}
          </Typography>

          <LoginForm
            loginData={loginData}
            setLoginData={setLoginData}
            handleSubmit={handleSubmit.login}
            isLoading={isLoading}
          />

          <Typography
            component={Link}
            variant='link'
            to='recuperar-palavra-passe'
            title={t('app.forgotPassword')}
            sx={{ fontSize: '0.9rem', textAlign: 'center' }}
          >
            {t('login.forgotPassword')}
          </Typography>

          <Divider sx={{ width: '100%', marginY: 2 }} />

          <Typography sx={{ textAlign: 'center' }}>
            {t('login.newRegister')}
          </Typography>

          <Button
            component={Link}
            to='registo-utilizador'
            variant='outlined'
            title={t('login.newRegisterButton')}
          >
            {t('login.newRegisterButton')}
          </Button>
        </>
      )}
    </Box>
  );
};

export default Login;

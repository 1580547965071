import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { getPage } from '../api';
import { mapProduct } from '../api/mapApiInterface';
import Brands from '../components/HomePage/Brands';
import DealsSection from '../components/HomePage/DealsSection';
import HighlightedCategories from '../components/HomePage/HighlightedCategories';
import HighlightedProducts from '../components/HomePage/HighlightedProducts';
import MainBanner from '../components/HomePage/MainBanner';
import SecondaryBanner from '../components/HomePage/SecondaryBanner';
import { MainContainer } from '../styles';
import { PAGES_SLUGS, SETTINGS } from '../variables';
import ThreeHighlightedBanners from '../components/HomePage/ThreeHighlightedBanners';

const HomePage = () => {
  const navigate = useNavigate();
  const [cookie] = useCookies();

  const [mainBanner, setMainBanner] = useState([]);
  const [secundaryBanners, _setSecundaryBanners] = useState([]);
  const [threeHighlightedBanners, setThreeHighlightedBanners] = useState([]);
  const [highlightedProducts, setHighlightedProducts] = useState([]);
  const [highlightedCategories, setHighlightedCategories] = useState([]);
  const [highlightedBrands, setHighlightedBrands] = useState([]);

  useEffect(() => {
    getPage('homepage')
      .then((page) => {
        !!page.mainBanner && setMainBanner(page.mainBanner);
        !!page.campaigns && setThreeHighlightedBanners(page.campaigns);
        !!page.products &&
          setHighlightedProducts(
            page.products.map((product) => mapProduct(product))
          );
        !!page.highlights && setHighlightedCategories(page.highlights);
        !!page.brands &&
          setHighlightedBrands(page.brands.filter((brand) => brand.status));
      })
      .catch(({ response: { status } }) => {
        if (status === 404) navigate(PAGES_SLUGS.notFound, { replace: true });
      });
  }, [navigate]);

  return (
    <MainContainer paddingY='0 !important'>
      {SETTINGS.defaults.homepage.mainBanner && !!mainBanner.length && (
        <MainBanner data={mainBanner} language={cookie.language} />
      )}

      {SETTINGS.defaults.homepage.highlightedProducts &&
        !!highlightedProducts.length && (
          <HighlightedProducts products={highlightedProducts} />
        )}

      {SETTINGS.defaults.homepage.highlightedBrands &&
        !!highlightedBrands.length && (
          <Brands data={highlightedBrands} carousel />
        )}

      {SETTINGS.defaults.homepage.highlightedCategories &&
        !!highlightedCategories.length && (
          <HighlightedCategories
            categories={highlightedCategories}
            language={cookie.language}
          />
        )}

      {SETTINGS.defaults.homepage.secundaryBanners &&
        !!secundaryBanners.length && (
          <SecondaryBanner data={secundaryBanners} language={cookie.language} />
        )}

      {SETTINGS.defaults.homepage.threeHighlightedBanners &&
        !!threeHighlightedBanners.length && (
          <ThreeHighlightedBanners
            data={threeHighlightedBanners}
            language={cookie.language}
          />
        )}

      {SETTINGS.defaults.homepage.dealsSection && <DealsSection />}
    </MainContainer>
  );
};

export default HomePage;

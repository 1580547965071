import {
  Box,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FONT_STYLE, SETTINGS } from '../../../variables';

const Payment = ({ orderDetails, setOrderDetails, setNotes }) => {
  const { t } = useTranslation();
  const activePaymentMethods =
    SETTINGS.defaults.cart.checkout.paymentMethods.filter(
      (payment) => payment.active
    );

  return (
    <>
      <Box
        sx={{
          marginBottom: 1
        }}
      >
        <Typography
          variant='h3'
          sx={{
            fontWeight: FONT_STYLE.semibold
          }}
        >
          {t('checkout.payment')}
        </Typography>
        <Typography
          variant='small'
          sx={{
            marginTop: 1
          }}
        >
          {t('checkout.paymentText')}
        </Typography>
      </Box>

      <RadioGroup
        name='payment-methods'
        value={orderDetails.paymentType}
        onChange={({ target: { value } }) =>
          setOrderDetails((values) => ({
            ...values,
            paymentType: value
          }))
        }
        sx={{
          gap: 1
        }}
      >
        {activePaymentMethods.map((method, index) => (
          <Box
            key={`payment-method-${index}`}
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <FormControlLabel
              title={t(method.title)}
              label={t(method.title)}
              control={<Radio />}
              value={method.value}
            />
            {method.value === orderDetails.paymentType &&
              method?.paymentTime && (
                <TextField
                  label={t('checkout.paymentTerm')}
                  defaultValue={orderDetails.paymentTime}
                  disabled
                  sx={{
                    marginTop: 2
                  }}
                />
              )}
          </Box>
        ))}
      </RadioGroup>

      {SETTINGS.defaults.cart.checkout.observations && (
        <TextField
          name='checkoutObservations'
          label={t('checkout.observations')}
          multiline
          rows={3}
          defaultValue=''
          onChange={({ target: { value } }) => setNotes(value)}
        />
      )}

      <Divider sx={{ marginY: { xs: 3, md: 4 } }} />
    </>
  );
};

export default Payment;

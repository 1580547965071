import { LoadingButton } from '@mui/lab';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changeLogin } from '../../../api/login';
import { PopupNotificationContext } from '../../../contexts/PopupNotificationContext';
import { PAGES_SLUGS } from '../../../variables';

const AccessForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setNotificationProps } = useContext(PopupNotificationContext);
  const personalInfo = useSelector((state) => state.user.personalInfo);

  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: personalInfo.email,
  });
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] =
    useState(false);

  function handleChange(e) {
    const { name, value } = e.target;

    setUserData((values) => ({ ...values, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    if (!userData.email || !userData.oldPassword || !userData.newPassword) {
      setIsLoading(false);
      return;
    }

    changeLogin(userData)
      .then(() => {
        setNotificationProps({
          isOpened: true,
          type: 'success',
          message: t('myAccount.accessData.successMessage'),
        });
      })
      .catch(({ response: { data, status } }) => {
        if (status === 401) {
          navigate(PAGES_SLUGS.login, { replace: true });
        }
        //  current password is incorrect
        if (status === 403) {
          setNotificationProps({
            message: t('myAccount.accessData.wrongPassword'),
          });
        }
        //  new password isnt a valid value
        if (status === 422) {
          setNotificationProps({
            message: data?.errors?.[0]?.msg,
          });
        }
        if (status >= 500) {
          setNotificationProps({
            message: data?.msg,
          });
        }

        setNotificationProps({
          isOpened: true,
          type: 'error',
        });
      })
      .then(() => {
        setIsLoading(false);
        setUserData({
          email: personalInfo.email,
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        });
      });
  }

  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
      sx={{
        width: { xs: '100%', lg: '50%' },
      }}
    >
      <TextField
        type='email'
        name='email'
        label={t('forms.email')}
        value={userData.email || ''}
        fullWidth
        required
        disabled
      />

      <TextField
        fullWidth
        required
        name='oldPassword'
        label={t('forms.currentPassword')}
        type={showOldPassword ? 'text' : 'password'}
        value={userData.oldPassword || ''}
        error={userData.oldPassword === ''}
        helperText={userData.oldPassword === '' && t('forms.mandatoryField')}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={() => setShowOldPassword(!showOldPassword)}>
                {showOldPassword ? <FiEye size={18} /> : <FiEyeOff size={18} />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        fullWidth
        required
        name='newPassword'
        label={t('forms.newPassword')}
        type={showNewPassword ? 'text' : 'password'}
        value={userData.newPassword || ''}
        error={userData.newPassword === ''}
        helperText={userData.newPassword === '' && t('forms.mandatoryField')}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>
                {showNewPassword ? <FiEye size={18} /> : <FiEyeOff size={18} />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        fullWidth
        required
        name='confirmPassword'
        label={t('forms.confirmNewPassword')}
        type={showConfirmationPassword ? 'text' : 'password'}
        value={userData.confirmPassword || ''}
        error={
          userData.confirmPassword === '' ||
          userData.confirmPassword !== userData.newPassword
        }
        helperText={
          userData.confirmPassword === ''
            ? t('forms.mandatoryField')
            : userData.confirmPassword !== userData.newPassword
            ? t('forms.passwordsNotMatch')
            : null
        }
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                onClick={() =>
                  setShowConfirmationPassword(!showConfirmationPassword)
                }
              >
                {showConfirmationPassword ? (
                  <FiEye size={18} />
                ) : (
                  <FiEyeOff size={18} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Typography
        sx={{
          width: '100%',
          fontSize: '0.8rem',
          marginTop: 1,
        }}
      >
        {t('forms.passwordRules')}
      </Typography>

      <LoadingButton
        variant='contained'
        type='submit'
        title={t('forms.saveChangesButton')}
        disabled={
          !userData.oldPassword ||
          !userData.newPassword ||
          !userData.confirmPassword ||
          userData.confirmPassword !== userData.newPassword
            ? true
            : false
        }
        sx={{
          display: 'flex',
          marginTop: 3,
        }}
        loading={isLoading}
      >
        {t('forms.saveChangesButton')}
      </LoadingButton>
    </Box>
  );
};

export default AccessForm;

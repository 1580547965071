import { Box, Tab, Tabs, styled, tabsClasses } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { ACCOUNT_LINKS, FONT_STYLE } from '../../variables';
import { useTranslation } from 'react-i18next';

const StyledMyAccountTabs = styled(Tabs)(() => ({
  [`& .${tabsClasses.indicator}`]: {
    left: 0,
  },
}));

const StyledSidebarTab = styled(Tab)(({ theme }) => ({
  width: '100%',
  maxWidth: '100%',
  minHeight: 'initial',
  fontSize: '0.9rem',
  fontWeight: FONT_STYLE.bold,
  textTransform: 'none',
  marginRight: 'auto',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  '&.Mui-selected': {
    fontWeight: FONT_STYLE.bold,
  },
}));

const AccountNavBar = ({ SMALL_DEVICES, userRole }) => {
  const { t } = useTranslation();
  const menuItems = ACCOUNT_LINKS?.[userRole]?.filter(
    (link) => link.myAccountMenu
  );

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const currentLocation = splitLocation[2];

  return (
    <Box sx={{ marginBottom: { xs: 4, md: 0 } }}>
      {menuItems && (
        <StyledMyAccountTabs
          orientation={SMALL_DEVICES ? 'horizontal' : 'vertical'}
          variant={SMALL_DEVICES ? 'scrollable' : 'standard'}
          value={currentLocation}
          scrollButtons
          allowScrollButtonsMobile
        >
          {menuItems.map(({ title, url }) => (
            <StyledSidebarTab
              key={`myAccountNavbar-${url}`}
              value={url}
              label={t(title)}
              title={t(title)}
              component={Link}
              to={url}
              sx={{
                alignItems: SMALL_DEVICES ? 'center' : 'start',
              }}
            />
          ))}
        </StyledMyAccountTabs>
      )}
    </Box>
  );
};

export default AccountNavBar;

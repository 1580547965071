import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiCalendar } from 'react-icons/fi';
import { FONT_STYLE, SETTINGS } from '../../../variables';
import DeliveryAtAddress from './DeliveryAtAddress';

const Shipping = ({ role, clientAddresses, orderDetails, setOrderDetails }) => {
  const { t } = useTranslation();
  dayjs.extend(isoWeek);

  const isWeekend = (date) => {
    const day = date.isoWeekday();
    return day === 6 || day === 7;
  };

  const chooseNextDay = () => {
    const nextDay = dayjs().add(1, 'day');
    const beginOfNextWeek = nextDay.add(1, 'week').startOf('week');
    //  IF the next day is weekend choose next monday
    return isWeekend(nextDay) ? beginOfNextWeek : nextDay;
  };

  const [deliveryDate, setDeliveryDate] = useState(chooseNextDay);
  const [deliveryDateError, setDeliveryDateError] = useState(false);

  useEffect(() => {
    setOrderDetails((values) => ({
      ...values,
      deliveryDate: !deliveryDateError ? deliveryDate.format('DD/MM/YYYY') : ''
    }));
  }, [deliveryDate]);

  return (
    <Box>
      <Box
        sx={{
          marginBottom: 1
        }}
      >
        <Typography
          variant='h3'
          sx={{
            fontWeight: FONT_STYLE.semibold
          }}
        >
          {t('checkout.shipping')}
        </Typography>
        <Typography
          variant='small'
          sx={{
            marginTop: 1
          }}
        >
          {t('checkout.shippingText')}
        </Typography>
      </Box>

      <RadioGroup
        name='shipping-methods'
        value={orderDetails.shipping}
        onChange={({ target: { value } }) =>
          setOrderDetails((values) => ({
            ...values,
            shipping: value
          }))
        }
      >
        {Object.entries(SETTINGS.defaults.cart.checkout.shippingMethods).map(
          ([key, value], index) =>
            value.active && (
              <Box
                key={`shipping-method-${index}`}
                sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <FormControlLabel
                  title={t(value.title)}
                  label={t(value.title)}
                  control={<Radio />}
                  value={key}
                />
                {key === orderDetails.shipping &&
                  orderDetails.shipping === 'deliveryAtAddress' && (
                    <DeliveryAtAddress
                      role={role}
                      clientAddresses={clientAddresses}
                      orderDetails={orderDetails}
                      setOrderDetails={setOrderDetails}
                    />
                  )}
              </Box>
            )
        )}
      </RadioGroup>

      <Box marginTop={3}>
        <Typography
          variant='h4'
          sx={{
            fontWeight: FONT_STYLE.semibold
          }}
        >
          {t('checkout.deliveryDate')}
        </Typography>
        <DatePicker
          value={deliveryDate}
          minDate={dayjs().add(1, 'day')}
          maxDate={dayjs().add(6, 'month')}
          shouldDisableMonth={isWeekend}
          disableHighlightToday
          slots={{
            openPickerIcon: FiCalendar
          }}
          slotProps={{
            textField: {
              style: {
                marginTop: '16px'
              },
              helperText:
                deliveryDateError && t('checkout.deliveryDateErrorMessage')
            },
            openPickerButton: {
              color: 'primary',
              style: {
                marginRight: 0
              }
            }
          }}
          onChange={(newDate, { validationError }) => {
            !!validationError
              ? setDeliveryDateError(true)
              : !!deliveryDateError && setDeliveryDateError(false);
            setDeliveryDate(newDate);
          }}
        />
      </Box>
    </Box>
  );
};

export default React.memo(Shipping);

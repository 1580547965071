import { Button, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PAGES_SLUGS } from '../../../variables';

const UserMenu = ({
  isMenuOpen,
  setIsMenuOpen,
  userRef,
  accountLinks,
  handleLogout
}) => {
  const { t } = useTranslation();

  //  filter links of 'userMenu'
  const userMenu = accountLinks.filter((item) => item.userMenu);

  return (
    <Menu
      anchorEl={userRef.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      keepMounted
      open={isMenuOpen}
      onClose={() => setIsMenuOpen(false)}
    >
      {userMenu.map(({ title, url }) => {
        return (
          <li key={`userMenu-${url}`}>
            <MenuItem
              component={Link}
              to={`${PAGES_SLUGS.account}/${url}`}
              title={t(title)}
              onClick={() => setIsMenuOpen(false)}
            >
              {t(title)}
            </MenuItem>
          </li>
        );
      })}
      <MenuItem
        component={Button}
        title={t('app.logout')}
        onClick={handleLogout}
        sx={{
          width: '100%'
        }}
      >
        {t('app.logout')}
      </MenuItem>
    </Menu>
  );
};

export default UserMenu;

import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import { BsCheckCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const RegisterSuccess = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: { xs: '80vw', sm: '30vw' },
        marginTop: { xs: 2, sm: 5 },
        marginBottom: { xs: 2, sm: 5 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        gap: 2,
      }}
    >
      <IconContext.Provider value={{ style: { color: '#4FD290', margin: 1 } }}>
        <BsCheckCircle size={64} />
      </IconContext.Provider>

      <Typography marginBottom={2}>
        {t('newRegister.successMessage')}
      </Typography>

      <Button
        variant='contained'
        component={Link}
        to='/'
        title='Ir para a homepage'
      >
        Ir para a homepage
      </Button>
    </Box>
  );
};

export default RegisterSuccess;

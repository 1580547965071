/*
|--------------------------------
| compare differences between two objects
|--------------------------------
*/
export const compareTwoObjects = (object1, object2) => {
  var diffObj = Array.isArray(object2) ? [] : {};
  Object.getOwnPropertyNames(object2).forEach(function (prop) {
    if (typeof object2[prop] === 'object') {
      diffObj[prop] = compareTwoObjects(object1[prop], object2[prop]);
      // if it's an array with only length property => empty array => delete
      // or if it's an object with no own properties => delete
      if (
        (Array.isArray(diffObj[prop]) &&
          Object.getOwnPropertyNames(diffObj[prop]).length === 1) ||
        Object.getOwnPropertyNames(diffObj[prop]).length === 0
      ) {
        delete diffObj[prop];
      }
    } else if (object1[prop] !== object2[prop]) {
      diffObj[prop] = object2[prop];
    }
  });
  return diffObj;
};

export const objectsEqual = (o1, o2) =>
  typeof o1 === 'object' && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
    : o1 === o2;

export const arraysEqual = (a1, a2) =>
  a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

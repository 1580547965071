import styled from '@emotion/styled/macro';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsBox, BsDownload } from 'react-icons/bs';
import { FiShoppingCart } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { ProductCardContext } from '../../contexts/ProductCardContext';
import { imageOnError } from '../../helpers';
import { productDetailsInterface } from '../../helpers/productDetailsInterface';
import {
  FONT_STYLE,
  SETTINGS,
  THEME_COLORS,
  TRANSITION_DURATION
} from '../../variables';
import ProductBadge from '../ProductBadge';
import ProductVariants from '../ProductPage/ProductVariants/ProductVariants';

const Title = styled(Typography)(() => ({
  display: 'flex',
  color: 'black',
  fontWeight: FONT_STYLE.bold,
  textDecoration: 'none',
  transition: `color ${TRANSITION_DURATION}ms ease`,
  '&:hover': {
    color: THEME_COLORS.primary
  }
}));

const ProductCardList = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  border: 'none',
  borderBottom: `1px solid ${THEME_COLORS.lightGray}`,
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(4),
    flexDirection: 'row'
  }
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  alignSelf: 'flex-start',
  maxWidth: '100%',
  flex: 1,
  overflow: 'hidden',
  border: `1px solid ${THEME_COLORS.gray}`,
  borderRadius: theme.shape.borderRadius,
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '350px'
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '160px'
  }
}));

const Image = styled(CardMedia)(() => ({
  maxWidth: '100%',
  objectFit: 'contain',
  aspectRatio: '1'
}));

const Content = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  gap: theme.spacing(4),
  flex: 1,
  padding: '0 !important',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row'
  }
}));

const ContentLeft = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  flex: 1
}));

const ContentRight = styled(Box)(({ theme, userislogged }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: userislogged === 'false' && 'center',
  gap: theme.spacing(2),
  flex: 1,
  '& .MuiPaper-unstyled': {
    width: 'auto'
  },
  '& > .MuiButtonBase-root': {
    width: '100%'
  },
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-end',
    '& > .MuiButtonBase-root': {
      width: 'initial'
    }
  }
}));

const Brand = styled(Typography)(() => ({
  fontSize: '.75rem'
}));

export const HighlightedSpecs = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  fontSize: '0.85rem',
  fontWeight: FONT_STYLE.semibold,
  transition: `color ${TRANSITION_DURATION}ms ease`,
  '&[href]:hover': {
    color: THEME_COLORS.primary
  }
}));

export const EanCodes = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  color: THEME_COLORS.darkGray,
  marginTop: theme.spacing(1)
}));

export const ProductInformations = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2)
}));

const ProductListCard = ({
  product,
  userIsLogged,
  _showRevPrice,
  showStockAvailability = true
}) => {
  const { t } = useTranslation();

  const { addToCart, productsForCart, setProductsForCart } =
    useContext(ProductCardContext);

  const {
    reference,
    slug,
    eanUn,
    eanCx,
    title,
    brand,
    images,
    variants,
    taxes
  } = product;

  const productDetails = productDetailsInterface(product);

  return (
    <ProductCardList raised>
      <ImageContainer title={title} component={Link} to={slug}>
        {showStockAvailability && productDetails.outOfStock && (
          <ProductBadge
            variant='stock'
            sx={{
              position: 'absolute',
              top: 0,
              left: 0
            }}
          >
            {t('product.outOfStock')}
          </ProductBadge>
        )}
        <Image
          component='img'
          image={images?.[0]}
          onError={imageOnError}
          alt={title}
        />
      </ImageContainer>

      <Content>
        <ContentLeft>
          {SETTINGS.defaults.productListCard.brand && !!brand && (
            <Brand>{brand}</Brand>
          )}

          <Box>
            <Title component={Link} title={title} to={slug}>
              {title}
            </Title>
            <EanCodes>
              {!!eanUn && (
                <Typography variant='xxsmall'>{`EAN UN: ${eanUn}`}</Typography>
              )}
              {!!eanCx && (
                <Typography variant='xxsmall'>{`EAN CX: ${eanCx}`}</Typography>
              )}
            </EanCodes>
          </Box>

          <ProductInformations>
            <Typography variant='xsmall'>{`Ref: ${reference}`}</Typography>
            <Typography variant='xsmall'>{`IVA: ${taxes}%`}</Typography>
          </ProductInformations>

          {SETTINGS.defaults.productListCard.unitsPerPackage &&
            !!productDetails.itemsPerBox && (
              <HighlightedSpecs>
                <BsBox size={16} />
                {t('product.packagingCount', {
                  count: productDetails.itemsPerBox
                })}
              </HighlightedSpecs>
            )}

          <HighlightedSpecs
            component='a'
            href='/'
            title={t('product.technicalSheet')}
            download
          >
            <BsDownload size={16} /> {t('product.technicalSheet')}
          </HighlightedSpecs>
        </ContentLeft>

        <ContentRight userislogged={String(userIsLogged)}>
          {userIsLogged ? (
            <>
              <ProductVariants
                variants={variants}
                userIsLogged={userIsLogged}
                productsForCart={productsForCart}
                setProductsForCart={setProductsForCart}
              />
              <Typography variant='xxsmall'>
                {t('product.priceWithoutVat')}
              </Typography>
              <Button
                variant='contained'
                title={
                  !productDetails.outOfStock
                    ? t('cart.addToCart')
                    : t('product.outOfStock')
                }
                disabled={productDetails.outOfStock}
                onClick={() =>
                  addToCart(productsForCart?.[reference]?.products)
                }
                sx={{
                  gap: 1
                }}
              >
                {!productDetails.outOfStock ? (
                  <>
                    <FiShoppingCart size={16} /> {t('cart.addToCartShort')}
                  </>
                ) : (
                  t('product.outOfStock')
                )}
              </Button>
            </>
          ) : (
            <Button
              variant='contained'
              component={Link}
              to={slug}
              title={t('product.seeProduct')}
            >
              {t('product.seeProduct')}
            </Button>
          )}
        </ContentRight>
      </Content>
    </ProductCardList>
  );
};

export default ProductListCard;

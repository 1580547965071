import { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addProductsToCart } from '../api/checkout';
import ProductVariantsModal from '../components/ProductVariantsModal';
import { checkStockAvailableInCart } from '../helpers';
import { setCartState } from '../state/cartSlice';
import { PAGES_SLUGS, SETTINGS } from '../variables';
import { PopupNotificationContext } from './PopupNotificationContext';

export const ProductCardContext = createContext();

export const ProductCardProvider = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setNotificationProps } = useContext(PopupNotificationContext);

  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);
  const { id, selectedClient, customerDiscount } = user;
  const customerId = selectedClient?.id || id;

  const [isVariationsModalOpen, setIsVariationsModalOpen] = useState(false);
  const [productVariants, setProductVariants] = useState([]);
  const [productsForCart, setProductsForCart] = useState({});

  const addToCart = (products) => {
    if (!!!products) {
      setNotificationProps({
        isOpened: true,
        type: 'warning',
        message: t('cart.addQuantitiesBeforeAddToCart')
      });
      return;
    }

    if (!SETTINGS.defaults.orderWithoutStock) {
      const stockIsAvailable = checkStockAvailableInCart(
        products,
        cart.products,
        setNotificationProps
      );
      if (!stockIsAvailable) return;
    }

    addProductsToCart(products, customerId)
      .then(
        ({ cart_items, discounts, total_discount, taxes, subtotal, total }) => {
          dispatch(
            setCartState({
              products: cart_items,
              discount: total_discount,
              discounts: discounts,
              taxes: taxes,
              subtotal: subtotal,
              total: total
            })
          );

          setNotificationProps({
            isOpened: true,
            type: 'success',
            message: t('cart.productAddedToCartMessage')
          });
        }
      )
      .catch(
        ({
          response: {
            status,
            data: { msg }
          }
        }) => {
          if (status === 401) {
            navigate(PAGES_SLUGS.login, { replace: true });
          } else if (status === 400 || status > 401) {
            setNotificationProps({
              isOpened: true,
              type: 'error',
              message: msg
            });
          }
        }
      );
  };

  return (
    <ProductCardContext.Provider
      value={{
        setIsVariationsModalOpen,
        productVariants,
        setProductVariants,
        customerDiscount,
        productsForCart,
        setProductsForCart,
        addToCart
      }}
    >
      {children}
      {isVariationsModalOpen && (
        <ProductVariantsModal
          isVariationsModalOpen={isVariationsModalOpen}
          setIsVariationsModalOpen={setIsVariationsModalOpen}
          productsForCart={productsForCart}
          setProductsForCart={setProductsForCart}
          productVariants={productVariants}
          addToCart={addToCart}
        />
      )}
    </ProductCardContext.Provider>
  );
};

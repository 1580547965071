import { LoadingButton } from '@mui/lab';
import { Box, MenuItem, Select } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteShoppingList } from '../../../api/user';
import { PopupNotificationContext } from '../../../contexts/PopupNotificationContext';
import { setShoppingLists } from '../../../state/shoppingListsSlice';
import { PAGES_SLUGS } from '../../../variables';
import Modal, { ModalActions, ModalContent, ModalTitle } from '../../Modal';

const DeleteShoppingListModal = ({
  isOpen,
  setIsOpen,
  setIndex,
  shoppingLists,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setNotificationProps } = useContext(PopupNotificationContext);

  const [isLoading, setIsLoading] = useState(false);
  const [selectShoppingList, setSelectShoppingList] = useState(
    shoppingLists?.[0]?._id
  );

  useEffect(() => {
    setSelectShoppingList(shoppingLists?.[0]?._id);
  }, [shoppingLists]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    deleteShoppingList(selectShoppingList)
      .then((lists) => {
        setIsLoading(false);
        setIsOpen(false);
        dispatch(setShoppingLists(lists));
        setNotificationProps({
          isOpened: true,
          type: 'success',
          message: t('myAccount.shoppingLists.shoppingListDeletedMessage'),
        });
      })
      .catch(
        ({
          response: {
            data: { msg },
            status,
          },
        }) => {
          setIsLoading(false);
          if (status === 401) {
            navigate(PAGES_SLUGS.login, { replace: true });
          } else if (status === 400 || status > 401) {
            setNotificationProps({
              isOpened: true,
              type: 'error',
              message: msg,
            });
          }
        }
      );

    setIndex(0);
  };

  return (
    <Modal
      isOpened={isOpen}
      setIsOpened={() => setIsOpen(false)}
      muiComponentNativeProps={{
        PaperProps: {
          sx: {
            maxWidth: '400px',
          },
        },
      }}
    >
      <ModalTitle>
        {t('myAccount.shoppingLists.removeShoppingListModal')}
      </ModalTitle>
      <Box
        component='form'
        onSubmit={handleSubmit}
      >
        <ModalContent>
          <Select
            fullWidth
            value={selectShoppingList}
            onChange={(e) => setSelectShoppingList(e.target.value)}
          >
            {shoppingLists.map(({ name, _id }) => (
              <MenuItem
                key={_id}
                value={_id}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </ModalContent>
        <ModalActions>
          <LoadingButton
            variant='contained'
            type='submit'
            title={t('myAccount.shoppingLists.removeButton')}
            loading={isLoading}
          >
            {t('myAccount.shoppingLists.removeButton')}
          </LoadingButton>
        </ModalActions>
      </Box>
    </Modal>
  );
};

export default DeleteShoppingListModal;

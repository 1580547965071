import { Theme, Typography, styled } from '@mui/material';
import { THEME_COLORS } from '../variables';

const StockLabel = styled(Typography)(
  ({ theme, color }: { theme: Theme; color: string }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    fontSize: '0.85rem',
    color: color,
    '&::before': {
      content: '""',
      width: '8px',
      height: '8px',
      backgroundColor: color,
      borderRadius: '100%'
    }
  })
);

const StockAvailability = ({ stock }: { stock: number }) => {
  const reducedStock: boolean = stock >= 1 && stock <= 5;
  const outOfStock: boolean = stock <= 0;

  if (reducedStock) {
    return <StockLabel color={THEME_COLORS.yellow}>Stock Reduzido</StockLabel>;
  } else if (outOfStock) {
    return <StockLabel color={THEME_COLORS.red}>Esgotado</StockLabel>;
  } else {
    return <StockLabel color={THEME_COLORS.green}>Disponível</StockLabel>;
  }
};

export default StockAvailability;

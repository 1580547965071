import { LoadingButton } from '@mui/lab';
import { Box, TextField, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import { BsCheckCircle } from 'react-icons/bs';
import { sendContactForm } from '../../api';
import { PopupNotificationContext } from '../../contexts/PopupNotificationContext';
import { THEME_COLORS } from '../../variables';

const ContactForm = () => {
  const { t } = useTranslation();
  const { setNotificationProps } = useContext(PopupNotificationContext);

  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formData, setFormData] = useState({
    name: undefined,
    email: undefined,
    phone: undefined,
    message: undefined,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    sendContactForm(formData)
      .then(() => {
        setIsLoading(false);
        setShowConfirmation(true);
        setNotificationProps({
          isOpened: true,
          type: 'success',
          message: t('contacts.requestSubmittedSuccessfullyMessage'),
        });
      })
      .catch(({ response: { status, data } }) => {
        const errorMessage =
          data?.msg || data?.errors?.[0]?.nestedErrors?.[0]?.msg;
        if (status === 400 || status > 401) {
          setIsLoading(false);
          setNotificationProps({
            isOpened: true,
            type: 'error',
            message: errorMessage,
          });
        }
      });
  };

  const ConfirmationMessage = () => {
    return (
      <Box
        sx={{
          marginTop: { xs: 3, md: 4 },
        }}
      >
        <IconContext.Provider
          value={{
            style: { color: THEME_COLORS.lightGreen },
          }}
        >
          <BsCheckCircle size={50} />
        </IconContext.Provider>
        <Typography
          sx={{
            marginTop: 1,
            fontWeight: 500,
          }}
        >
          {t('contacts.requestSubmittedSuccessfullyMessage')}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
      }}
    >
      <Typography variant='h2'>{t('contacts.contactRequest')}</Typography>
      <Typography marginTop={2}>
        <Trans i18nKey='contacts.contactRequestText' />
      </Typography>

      {showConfirmation ? (
        <ConfirmationMessage />
      ) : (
        <Box
          component='form'
          onSubmit={handleSubmit}
          autoComplete='off'
          sx={{
            width: { md: '75%' },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginX: 'auto',
            marginTop: 1,
          }}
        >
          <TextField
            fullWidth
            type='text'
            name='name'
            required
            label={t('forms.name')}
            value={formData.name}
            onChange={handleChange}
            error={formData.name === ''}
            helperText={formData.name === '' && t('forms.mandatoryField')}
          />
          <TextField
            fullWidth
            type='email'
            name='email'
            required
            label={t('forms.email')}
            value={formData.email}
            onChange={handleChange}
            error={formData.email === ''}
            helperText={formData.email === '' && t('forms.mandatoryField')}
          />
          <TextField
            fullWidth
            type='phone'
            name='phone'
            required
            label={t('forms.contact')}
            value={formData.phone}
            onChange={handleChange}
            error={formData.phone === ''}
            helperText={formData.phone === '' && t('forms.mandatoryField')}
          />
          <TextField
            name='message'
            label={t('forms.message')}
            required
            multiline
            rows={3}
            defaultValue=''
            fullWidth
            value={formData.message}
            onChange={handleChange}
            error={formData.message === ''}
            helperText={formData.message === '' && t('forms.mandatoryField')}
          />
          <LoadingButton
            variant='contained'
            type='submit'
            title={t('contacts.submitRequest')}
            loading={isLoading}
            sx={{
              marginTop: 3,
            }}
          >
            {t('contacts.submitRequest')}
          </LoadingButton>
        </Box>
      )}
    </Box>
  );
};

export default ContactForm;

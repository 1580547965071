import { Box, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';

const ProductsListTitle = ({ title, productsTotal }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        flex: { md: '1' },
        display: 'flex',
        alignItems: 'baseline'
      }}
    >
      {!!title && (
        <Typography
          variant='h1'
          sx={{
            fontSize: '1.5rem'
          }}
        >
          {capitalize(title)}
        </Typography>
      )}
      {!!productsTotal && (
        <Typography variant='overline' marginLeft={1}>
          {t('productList.totalResults', { count: productsTotal })}
        </Typography>
      )}
    </Box>
  );
};

export default ProductsListTitle;

import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { imageOnError } from '../../helpers';
import ThumbnailsCarousel from '../Carousel/ThumbnailsCarousel';
import ImageZoomModal from '../ImageZoomModal';

const ProductImages = ({ images, title }) => {
  const { t } = useTranslation();
  const [shownImage, setShownImage] = useState(0);
  const [firstImageExists, setFirstImageExists] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [zoomImagePath, setZoomImagePath] = useState('');

  return (
    <>
      <Box
        sx={{
          width: { xs: '300px', md: '240px', lg: '400px' },
          // width: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
        gap={firstImageExists && images.length > 1 && 2.5}
      >
        <Button
          title={t('product.seeImage')}
          onClick={() => {
            setZoomImagePath(images[shownImage]);
            setModalIsOpen(true);
          }}
          sx={{
            padding: 0,
            '&:hover': {
              backgroundColor: 'transparent',
              cursor: 'zoom-in'
            }
          }}
        >
          <Box
            component='img'
            src={images[shownImage]}
            alt={title}
            onError={(e) => {
              imageOnError(e);
              setFirstImageExists(false);
            }}
            sx={{
              aspectRatio: '2/3',
              objectFit: 'contain',
              maxWidth: '100%'
            }}
          />
        </Button>

        {firstImageExists && images.length > 1 && (
          <ThumbnailsCarousel
            images={images}
            shownImage={shownImage}
            setShownImage={setShownImage}
          />
        )}
      </Box>
      <ImageZoomModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        imagePath={zoomImagePath}
      />
    </>
  );
};

export default ProductImages;

import { LoadingButton } from '@mui/lab';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { addUser } from '../../../api/user';
import { PAGES_SLUGS } from '../../../variables';
import Modal, { ModalActions, ModalContent, ModalTitle } from '../../Modal';

const NewUserModal = ({
  modalIsOpened,
  setModalIsOpened,
  setNotificationProps,
  setUsers,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [newUserData, setNewUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    setNewUserData((values) => ({ ...values, [name]: value }));
  };

  function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    addUser(newUserData)
      .then(({ users }) => {
        setUsers(users);
        setIsLoading(false);
        setModalIsOpened(false);
        setNotificationProps({
          isOpened: true,
          type: 'success',
          message: t('myAccount.users.userCreatedMessage'),
        });
      })
      .catch(({ response: { status, data } }) => {
        if (status === 401) {
          navigate(PAGES_SLUGS.login, { replace: true });
        } else if (status === 400 || status > 401) {
          setIsLoading(false);
          setNotificationProps({
            isOpened: true,
            type: 'error',
            message: data?.msg || data?.errors?.[0]?.msg,
          });
        }
      });
  }

  return (
    <Modal
      isOpened={modalIsOpened}
      setIsOpened={setModalIsOpened}
      muiComponentNativeProps={{
        onClose: () => setModalIsOpened(false),
        PaperProps: {
          sx: {
            width: '100%',
            maxWidth: '400px',
            padding: 4,
          },
        },
      }}
    >
      <ModalTitle>{t('myAccount.users.addNewUser')}</ModalTitle>
      <Box
        component='form'
        onSubmit={handleSubmit}
      >
        <ModalContent
          sx={{
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          <TextField
            fullWidth
            type='email'
            name='email'
            label={t('forms.email')}
            required
            value={newUserData.email || ''}
            onChange={handleChange}
            error={newUserData.email === ''}
            helperText={newUserData.email === '' && t('forms.mandatoryField')}
          />

          <TextField
            fullWidth
            required
            name='password'
            label={t('forms.password')}
            type={showPassword ? 'text' : 'password'}
            value={newUserData.password || ''}
            error={newUserData.password === ''}
            helperText={
              newUserData.password === '' && t('forms.mandatoryField')
            }
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <FiEye size={18} />
                    ) : (
                      <FiEyeOff size={18} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Typography
            sx={{
              width: '100%',
              fontSize: '0.8rem',
              marginTop: 1,
            }}
          >
            {t('forms.passwordRules')}
          </Typography>
        </ModalContent>
        <ModalActions>
          <LoadingButton
            variant='contained'
            type='submit'
            title={t('myAccount.users.addUser')}
            loading={isLoading}
            disabled={!newUserData.email || !newUserData.password}
          >
            {t('myAccount.users.addUser')}
          </LoadingButton>
        </ModalActions>
      </Box>
    </Modal>
  );
};

export default NewUserModal;

import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

const CompanyInfo = ({ data }) => {
  const { t } = useTranslation();
  const [XSMALL_DEVICES] = useOutletContext();
  const { name, street, zipCode, city, country, taxNumber } = data;
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: XSMALL_DEVICES && 'column',
          gap: { md: 2 },
        }}
      >
        <TextField
          label={t('forms.name')}
          value={name}
          disabled
          sx={{
            flex: 1,
          }}
        />

        <TextField
          label={t('forms.address')}
          value={street}
          disabled
          sx={{ flex: 1 }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: XSMALL_DEVICES && 'column',
          gap: { md: 2 },
        }}
      >
        <TextField
          label={t('forms.postalCode')}
          value={zipCode}
          disabled
          sx={{ flex: 1 }}
        />

        <TextField
          label={t('forms.city')}
          value={city}
          disabled
          sx={{ flex: 1 }}
        />

        <TextField
          label={t('forms.country')}
          value={country}
          disabled
          sx={{ flex: 1 }}
        />

        <TextField
          label={t('forms.vat')}
          value={taxNumber}
          disabled
          sx={{ flex: 1 }}
        />
      </Box>
    </>
  );
};

export default CompanyInfo;

import { Box, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import BrandsCard from '../BrandsCard';

const Brands = ({ data, carousel = false }) => {
  const { t } = useTranslation();
  const breakpoints = {
    0: {
      slidesPerView: 2
    },
    500: {
      slidesPerView: 3
    },
    700: {
      slidesPerView: 4
    },
    900: {
      slidesPerView: 5
    },
    1100: {
      slidesPerView: 6
    }
  };

  return (
    <Container sx={{ paddingY: { xs: 3, md: 5 } }}>
      <Typography
        variant='h2'
        sx={{
          fontSize: '1.7rem',
          textAlign: 'center'
        }}
      >
        {t('homepage.brands.title')}
      </Typography>
      <Box sx={{ marginTop: 4 }}>
        <Grid container>
          {carousel ? (
            <Swiper
              spaceBetween={24}
              breakpoints={breakpoints}
              modules={[Autoplay]}
              autoplay={{
                delay: 3000,
                pauseOnMouseEnter: true
              }}
              allowTouchMove={false}
            >
              {data.map((brand) => (
                <SwiperSlide key={`brands-carousel-${brand._id}`}>
                  <BrandsCard brand={brand} />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            data.map((brand) => (
              <Grid key={`brand-${brand._id}`} xs={6} sm={3} md={2}>
                <BrandsCard brand={brand} />
              </Grid>
            ))
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default Brands;

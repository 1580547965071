import { LoadingButton } from '@mui/lab';
import { Box, TextField } from '@mui/material';
import kebabCase from 'lodash/kebabCase';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addShoppingList } from '../../../api/user';
import { PopupNotificationContext } from '../../../contexts/PopupNotificationContext';
import { setShoppingLists } from '../../../state/shoppingListsSlice';
import { PAGES_SLUGS } from '../../../variables';
import Modal, { ModalActions, ModalContent, ModalTitle } from '../../Modal';

const CreateShoppingListModal = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setNotificationProps } = useContext(PopupNotificationContext);

  const [isLoading, setIsLoading] = useState(false);
  const [newShoppingListName, setNewShoppingListName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    addShoppingList(kebabCase(newShoppingListName))
      .then((lists) => {
        setIsLoading(false);
        setIsOpen(false);
        dispatch(setShoppingLists(lists));
        setNotificationProps({
          isOpened: true,
          type: 'success',
          message: t('myAccount.shoppingLists.newShoppingListAddedMessage'),
        });
      })
      .catch(
        ({
          response: {
            data: { msg },
            status,
          },
        }) => {
          setIsLoading(false);
          if (status === 401) {
            navigate(PAGES_SLUGS.login, { replace: true });
          } else if (status === 400 || status > 401) {
            setNotificationProps({
              isOpened: true,
              type: 'error',
              message: msg,
            });
          }
        }
      );
  };

  return (
    <Modal
      isOpened={isOpen}
      setIsOpened={() => setIsOpen(false)}
      muiComponentNativeProps={{
        PaperProps: {
          sx: {
            maxWidth: '400px',
          },
        },
      }}
    >
      <ModalTitle>{t('myAccount.shoppingLists.newShoppingList')}</ModalTitle>
      <Box
        component='form'
        onSubmit={handleSubmit}
      >
        <ModalContent>
          <TextField
            fullWidth
            required
            label='Nome'
            name='newShoppingListName'
            value={newShoppingListName}
            inputProps={{ pattern: '^[a-zA-Z][^$]+' }}
            onChange={({ target: { value } }) => setNewShoppingListName(value)}
            sx={{
              marginTop: 0,
            }}
          />
        </ModalContent>
        <ModalActions>
          <LoadingButton
            variant='contained'
            type='submit'
            title={t('myAccount.shoppingLists.addButton')}
            loading={isLoading}
            disabled={!!!newShoppingListName}
          >
            {t('myAccount.shoppingLists.addButton')}
          </LoadingButton>
        </ModalActions>
      </Box>
    </Modal>
  );
};

export default CreateShoppingListModal;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  products: [],
  taxes: [],
  subtotal: 0,
  total: 0,
  discounts: [],
  discount: 0,
  shipping: '',
  deliveryAtAddress: '',
  deliveryAtStore: '',
  shippingNotes: '',
  promotionalCodes: [],
};

const cartSlice = createSlice({
  name: 'cart',

  initialState: initialState,

  reducers: {
    setCartState: (state, action) => (state = { ...state, ...action.payload }),

    setCartId: (state, action) => {
      state.id = action.payload;
    },

    // real use case: reset cart after order completion
    setCart: (state, action) => {
      state.products = action.payload;
    },

    setCartSubtotal: (state, action) => {
      state.subtotal = action.payload;
    },

    setCartTotal: (state, action) => {
      state.total = action.payload;
    },

    setCartTaxes: (state, action) => {
      state.taxes = action.payload;
    },

    setDiscount: (state, action) => {
      state.discount = action.payload;
    },

    updateCart: (state, action) => {
      state.products = [...action.payload];
    },

    // Removes variant from cart
    removeFromCart: (state, action) => {
      const { reference } = action.payload;

      const cart = state.products;

      const foundProduct = cart.find(
        ({ reference: newReference }) => newReference === reference
      );

      const newQuantity = 0;

      const newProduct = { ...foundProduct, quantity: newQuantity };

      const updatedCart = cart.map((product) =>
        product.reference === reference ? newProduct : product
      );

      const newCart = updatedCart.filter(({ quantity }) => quantity !== 0);

      state.products = [...newCart];
    },

    // Removes quantity, or product, from cart
    removeQuantityFromCartItem: (state, action) => {
      state.products = [...action.payload];
      // const oldCart = state.products;

      // const products = action.payload;

      // const updatedProducts = oldCart.map((product) => {
      //   const foundProduct = products.find(
      //     ({ reference }) => reference === product.reference
      //   );

      //   const newProduct = { ...product };

      //   const newQuantity = foundProduct?.quantity || 0;

      //   newProduct.quantity -= newQuantity || 0;

      //   return newProduct;
      // });

      // const updatedCart = updatedProducts.filter(
      //   ({ quantity }) => quantity !== 0
      // );

      // state.products = [...updatedCart];
    },

    resetCartState: () => initialState,
  },
});

export const {
  setCartState,
  setCartId,
  setCart,
  setCartSubtotal,
  setCartTotal,
  setCartTaxes,
  setDiscount,
  updateCart,
  removeFromCart,
  removeQuantityFromCartItem,
  resetCartState,
} = cartSlice.actions;

export default cartSlice.reducer;

import {
  Badge,
  Box,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import { useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { FiShoppingCart, FiUser } from 'react-icons/fi';
import { TbShoppingBag } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../../../api/login';
import { resetCartState } from '../../../state/cartSlice';
import { setCategories } from '../../../state/categoriesSlice';
import { resetShoppingListsState } from '../../../state/shoppingListsSlice';
import { resetUserState } from '../../../state/userSlice';
import { IconButton, LocaleSelector } from '../../../styles';
import {
  ACCOUNT_LINKS,
  FONT_STYLE,
  PAGES_SLUGS,
  SETTINGS
} from '../../../variables';
import MenuMobile from '../MenuMobile';
import UserMenu from './UserMenu';
require('dayjs/locale/pt');
require('dayjs/locale/es');

const AccountLinks = () => {
  const {
    t,
    i18n: { changeLanguage, language }
  } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { products } = useSelector((state) => state.cart);
  const {
    role,
    personalInfo: { name: userName, email }
  } = useSelector((state) => state.user);

  const theme = useTheme();
  const MOBILE_DEVICES = useMediaQuery(theme.breakpoints.down('md'));

  const userRef = useRef(null);
  const [cookie, setCookie, removeCookie] = useCookies();
  const hasLogin = cookie.userToken;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(language);

  const updateLanguageCookie = (language) => {
    //  expires in 60 days
    const expiresInSeconds = parseInt(60) * 24 * 60 * 60;

    //  set user token
    setCookie('language', language, {
      path: '/',
      maxAge: expiresInSeconds,
      secure: true,
      sameSite: true
    });
  };

  const handleLanguage = (newLanguage) => {
    setCurrentLanguage(newLanguage);
    changeLanguage(newLanguage);
    updateLanguageCookie(newLanguage);
    dayjs.locale(newLanguage);
  };

  function handleLogout() {
    setIsMenuOpen(false);
    setIsLoading(true);

    logout()
      .then(() => {
        //  remove user token
        removeCookie('userToken', { path: '/' });

        //  reset states
        dispatch(resetUserState());
        dispatch(resetCartState());
        dispatch(resetShoppingListsState());
        dispatch(setCategories([]));

        setIsLoading(false);
        navigate('/');
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate(PAGES_SLUGS.login, { replace: true });
      });
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: { xs: 0, md: 1 },
        marginRight: { xs: -1, md: 0 }
      }}
    >
      {hasLogin && SETTINGS.functionalities.shoppingList && (
        <IconButton
          startIcon={<TbShoppingBag size={26} />}
          component={Link}
          to={`${PAGES_SLUGS.account}/${PAGES_SLUGS.myAccount.shoppingList}`}
          title={t('myAccount.shoppingLists.title')}
        />
      )}

      <IconButton
        startIcon={<FiShoppingCart size={24} />}
        component={Link}
        to={PAGES_SLUGS.cart}
        title={t('app.cart')}
        sx={{
          gap: { xs: 0.5, md: 1 }
        }}
      >
        {!!products.length && (
          <Badge
            badgeContent={products.reduce(
              (prev, item) => prev + item.quantity.unit + item.quantity.box,
              0
            )}
            sx={{ top: '-10px' }}
          />
        )}
      </IconButton>

      {SETTINGS.functionalities.myAccount &&
        (!hasLogin || isLoading ? (
          <IconButton
            startIcon={<FiUser size={24} />}
            component={Link}
            to='/login'
            title='Login'
          >
            <Typography
              sx={{
                display: { xs: 'none', md: 'block' },
                marginLeft: 1
              }}
            >
              {t('app.login')}
            </Typography>
          </IconButton>
        ) : (
          <>
            <IconButton
              startIcon={<FiUser size={24} />}
              ref={userRef}
              onClick={() => setIsMenuOpen(true)}
              title={t('app.myAccount')}
            >
              <Typography
                sx={{
                  display: { xs: 'none', md: 'block' },
                  fontWeight: FONT_STYLE.bold,
                  marginLeft: 1
                }}
              >
                {!!userName ? capitalize(userName.split(' ')[0]) : email}
              </Typography>
            </IconButton>
            <UserMenu
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
              userRef={userRef}
              accountLinks={ACCOUNT_LINKS?.[role]}
              handleLogout={handleLogout}
            />
          </>
        ))}

      {SETTINGS.functionalities.translations && (
        <LocaleSelector
          select
          name='locale'
          value={currentLanguage}
          onChange={({ target: { value } }) => handleLanguage(value)}
        >
          {SETTINGS.defaults.languageOptions.map((language) => (
            <MenuItem
              key={`locale-option-${language}`}
              value={language}
              disabled={language === currentLanguage}
              sx={{
                textTransform: 'uppercase'
              }}
            >
              {language}
            </MenuItem>
          ))}
        </LocaleSelector>
      )}

      {MOBILE_DEVICES && <MenuMobile />}
    </Box>
  );
};

export default AccountLinks;

import styled from '@emotion/styled/macro';
import { Box, Container, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Link } from 'react-router-dom';
import { FONT_STYLE, SETTINGS, TRANSITION_DURATION } from '../../variables';

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  lineHeight: '1.2',
  fontWeight: FONT_STYLE.bold,
  color: 'white',
  textTransform: 'uppercase',
  letterSpacing: '0.5px',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.7rem',
    lineHeight: '1.25'
  }
}));

const Image = styled(Box)(() => ({
  aspectRatio: '2/3',
  objectFit: 'cover',
  filter: 'brightness(80%)',
  transition: `filter ${TRANSITION_DURATION}ms ease`
}));

const SecondaryBannerBlock = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    [Image]: {
      filter: 'brightness(85%)'
    }
  }
}));

const ThreeHighlightedBanners = ({ data, language }) => {
  return (
    <Container sx={{ paddingY: { xs: 3, md: 5 } }}>
      <Grid2 container spacing={4}>
        {data.map(({ title, image, url }, index) => (
          <Grid2 key={`threeHightlightedBanners-${index}`} xs={12} sm={4}>
            <SecondaryBannerBlock
              component={Link}
              to={url}
              title={title[language]}
            >
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  paddingX: 2,
                  textAlign: 'center',
                  zIndex: 1
                }}
              >
                <Title>{title[language]}</Title>
              </Box>
              <Image
                component='img'
                src={
                  SETTINGS.development.sampleData.homepage
                    ? `${process.env.PUBLIC_URL}/sample-content/${image}`
                    : image
                }
                width='100%'
                alt={title[language]}
              />
            </SecondaryBannerBlock>
          </Grid2>
        ))}
      </Grid2>
    </Container>
  );
};

export default ThreeHighlightedBanners;

import { Box, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MainContainer } from '../styles';

const AboutUs = ({ props, language }) => {
  const { t } = useTranslation();
  const [pageContent] = useState(props);
  const { about } = pageContent;

  return (
    <MainContainer>
      <Container>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <Box
              sx={{
                textAlign: 'center'
              }}
            >
              <Typography variant='h1'>{t('aboutUs.title')}</Typography>
              <Typography marginTop={2}>{t('aboutUs.text')}</Typography>
            </Box>
          </Grid>
          <Grid container marginY={{ xs: 3, md: 6 }}>
            <Grid xs={12} md={6}>
              <Box
                component='img'
                src={about.image}
                alt={about.title[language]}
                sx={{ maxWidth: '100%' }}
              />
            </Grid>
            <Grid
              xs={12}
              md={5}
              mdOffset={1}
              sx={{
                display: 'grid',
                alignContent: 'center',
                gap: 1
              }}
            >
              {about.description[language] && (
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: about.description[language]
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default AboutUs;

import { Box, Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ pathArray }) => {
  return (
    <MuiBreadcrumbs aria-label='breadcrumb'>
      {pathArray.map((param, index) => {
        const isLastParam = pathArray.length - 1 === index;
        return (
          <Box
            key={`breadcrumb-item-${param}`}
            component={!isLastParam ? Link : 'span'}
            to={!isLastParam ? param.slug : null}
            title={param.title}
            sx={{
              fontSize: '0.9em',
              color: 'black',
              textDecoration: !isLastParam ? 'underline' : 'none'
            }}
          >
            {param.title}
          </Box>
        );
      })}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;

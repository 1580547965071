import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FiTrash2 } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteProductFromShoppingList } from '../../../api/user';
import { formatCurrency } from '../../../constants/utils';
import { PopupNotificationContext } from '../../../contexts/PopupNotificationContext';
import { productDetailsInterface } from '../../../helpers/productDetailsInterface';
import { setShoppingLists } from '../../../state/shoppingListsSlice';
import { BadgeDiscount, IconButton } from '../../../styles';
import { FONT_STYLE, PAGES_SLUGS, THEME_COLORS } from '../../../variables';
import SummaryProductInfo from '../../Cart/SummaryTable/SummaryProductInfo';

const ShoppingListProducts = ({ index, shoppingLists }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setNotificationProps } = useContext(PopupNotificationContext);

  const { role } = useSelector((state) => state.user);
  const isLimitedCustomer = role === 'limitedcustomer';

  const { _id, products } = shoppingLists[index];

  const handleDeleteProductFromShoppingList = (productId) => {
    deleteProductFromShoppingList(_id, productId)
      .then((lists) => {
        dispatch(setShoppingLists(lists));

        setNotificationProps({
          isOpened: true,
          type: 'success',
          message: t(
            'myAccount.shoppingLists.productRemovedFromShoppingListMessage'
          )
        });
      })
      .catch(
        ({
          response: {
            data: { msg },
            status
          }
        }) => {
          if (status === 401) {
            navigate(PAGES_SLUGS.login, { replace: true });
          } else if (status === 400 || status > 401) {
            setNotificationProps({
              isOpened: true,
              type: 'error',
              message: msg
            });
          }
        }
      );
  };

  return (
    <Box
      sx={{
        marginTop: 3
      }}
    >
      {shoppingLists?.[index]?.products.length !== 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ minWidth: { xs: '275px', md: 'initial' } }}>
                  {t('tables.details')}
                </TableCell>
                {!isLimitedCustomer && (
                  <>
                    <TableCell
                      sx={{ minWidth: { xs: '150px', md: 'initial' } }}
                    >
                      {t('tables.price')}*
                    </TableCell>
                  </>
                )}
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {Array.isArray(products) &&
                products.map((product) => {
                  const {
                    id,
                    reference,
                    slug,
                    images,
                    title,
                    brand,
                    saleAmount
                  } = product;
                  const productDetails = productDetailsInterface(product);

                  return (
                    <TableRow key={`shoppingList-product-${reference}`}>
                      <TableCell>
                        <SummaryProductInfo
                          reference={reference}
                          slug={slug}
                          images={images}
                          title={title}
                          brand={brand}
                          outOfStock={productDetails.outOfStock}
                        />
                      </TableCell>

                      {!isLimitedCustomer && (
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: 1
                            }}
                          >
                            <Typography
                              sx={{
                                textDecoration:
                                  productDetails.onSale && 'line-through'
                              }}
                            >
                              {formatCurrency(productDetails.price)}
                            </Typography>

                            {productDetails.onSale && (
                              <>
                                <Typography
                                  variant='small'
                                  sx={{
                                    fontWeight: FONT_STYLE.bold,
                                    color: THEME_COLORS.primary
                                  }}
                                >
                                  {formatCurrency(productDetails.salePrice)}
                                </Typography>
                                <BadgeDiscount>{`${saleAmount}%`}</BadgeDiscount>
                              </>
                            )}
                          </Box>
                        </TableCell>
                      )}

                      <TableCell>
                        <IconButton
                          title={t('tables.actions.remove')}
                          onClick={() =>
                            handleDeleteProductFromShoppingList(id)
                          }
                        >
                          <FiTrash2 size={18} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>
          {t('myAccount.shoppingLists.noProductsAssociated')}
        </Typography>
      )}
    </Box>
  );
};

export default ShoppingListProducts;

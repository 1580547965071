import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPage } from '../api';
import Loading from '../components/Loading';
import { PAGES_SLUGS } from '../variables';
import AboutUs from './AboutUs';
import Faqs from './Faqs';
import PressKit from './PressKit';
import TermsPage from './TermsPage';
import BudgetRequest from './BudgetRequest';

const InstitucionalPages = () => {
  const [cookie] = useCookies();
  const location = useLocation();
  const slugParam = location.pathname.slice(1);
  const navigate = useNavigate();

  const [page, setPage] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const slugs = {
    aboutUs: {
      backofficeSlug: 'quemsomos',
      finalSlug: PAGES_SLUGS.institutional.aboutUs
    },
    termsAndConditions: {
      backofficeSlug: 'termos',
      finalSlug: PAGES_SLUGS.institutional.termsAndConditions
    },
    privacyPolicy: {
      backofficeSlug: 'politica',
      finalSlug: PAGES_SLUGS.institutional.privacyPolicy
    },
    alternativeDisputeResolution: {
      backofficeSlug: '',
      finalSlug: PAGES_SLUGS.institutional.alternativeDisputeResolution
    },
    faqs: {
      backofficeSlug: '',
      finalSlug: PAGES_SLUGS.institutional.faqs
    },
    presskit: {
      backofficeSlug: 'presskit',
      finalSlug: PAGES_SLUGS.custom.pressKit
    },
    customize:{
      backofficeSlug: '',
      finalSlug: PAGES_SLUGS.custom.customize,
    }
  };

  //  GET 'backofficeSlug' through 'finalSlug' key
  const slugPageBo = Object.values(slugs).find(
    (slug) => slug.finalSlug === slugParam
  ).backofficeSlug;

  useEffect(() => {
    //  IF 'backofficeSlug' key is empty » page does not exist in BO
    if (!!!slugPageBo) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);

    //  GET BO existing page
    getPage(slugPageBo)
      .then((pageContent) => {
        setPage(pageContent);
        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        setIsLoading(false);
        // If page wasn't created in backoffce redirect as it makes no sense to
        // show a page without information to display
        if (status === 422) navigate(PAGES_SLUGS.notFound);
      });
  }, [slugPageBo, navigate]);

  if (isLoading) {
    return <Loading />;
  } else {
    switch (slugParam) {
      case slugs.aboutUs.finalSlug:
        return <AboutUs props={page} language={cookie.language} />;
      case slugs.termsAndConditions.finalSlug:
      case slugs.privacyPolicy.finalSlug:
        return <TermsPage props={page} language={cookie.language} />;
      case slugs.faqs.finalSlug:
        return <Faqs />;
      case slugs.presskit.finalSlug:
        return <PressKit props={page} language={cookie.language} />;
      case slugs.customize.finalSlug:
        return <BudgetRequest/>
      default:
        break;
    }
  }
};

export default InstitucionalPages;

import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import { BsCheckCircle } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { Link, useOutletContext } from 'react-router-dom';
import { setCartState } from '../state/cartSlice';
import { FONT_STYLE, PAGES_SLUGS, THEME_COLORS } from '../variables';

const OrderConfirmation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [_cart, user, _customerId] = useOutletContext();
  const isLimitedCustomer = user.role === 'limitedcustomer';

  useEffect(() => {
    window.localStorage.setItem('orderConfirmed', false);
    //  reset cart state
    dispatch(
      setCartState({
        cart: [],
        taxes: [],
        subtotal: 0,
        total: 0,
        discounts: [],
        discount: 0,
        shipping: '',
        deliveryAtAddress: '',
        deliveryAtStore: '',
        shippingNotes: ''
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Grid xs={12}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          marginTop: { xs: 5, md: 0 }
        }}
      >
        <IconContext.Provider
          value={{
            style: {
              color: isLimitedCustomer
                ? THEME_COLORS.orange
                : THEME_COLORS.lightGreen
            }
          }}
        >
          <BsCheckCircle size={80} />
        </IconContext.Provider>

        <Typography
          sx={{
            fontSize: '1.2rem',
            fontWeight: FONT_STYLE.bold,
            marginTop: { xs: 2, md: 3 }
          }}
        >
          {t('orderConfirmation.title')}
        </Typography>

        <Box marginTop={2.5}>
          {isLimitedCustomer ? (
            <>
              <Typography>
                <Trans i18nKey='orderConfirmation.suspendedOrderMessage' />
              </Typography>
            </>
          ) : (
            <Typography>{t('orderConfirmation.successMessage')}</Typography>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            marginY: { xs: 2, md: 4 }
          }}
        >
          <Button
            title={t('app.backToHomepageButton')}
            variant='outlined'
            component={Link}
            to='/'
          >
            {t('app.backToHomepageButton')}
          </Button>

          <Button
            title={t('orderConfirmation.ordersPageButton')}
            variant='contained'
            component={Link}
            to={`${PAGES_SLUGS.account}/${PAGES_SLUGS.myAccount.orders}`}
          >
            {t('orderConfirmation.ordersPageButton')}
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

export default OrderConfirmation;

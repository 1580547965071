export function slugify(text) {
  var from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
  var to = 'aaaaaeeeeeiiiiooooouuuunc------';
  for (var i = 0, len = from.length; i < len; i++) {
      text = text.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }
  return text
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters
      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/&/g, "-y-") // Replace & with ‘and’
      // Remove all non-word chars
      .replace(/[^\w\-]+/g, "")  // eslint-disable-line
      // Replace multiple - with single - 
      .replace(/\-\-+/g, "-"); // eslint-disable-line
}
import { LoadingButton } from '@mui/lab';
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PersonalInfo = ({
  data: { name, phone, email },
  handleChange,
  userRole,
  isLoading,
  userDataChanged,
}) => {
  const { t } = useTranslation();
  return (
    <Box marginTop={1}>
      {userRole !== 'limitedcustomer' && (
        <TextField
          fullWidth
          required
          type='text'
          name='name'
          label={t('forms.name')}
          value={name}
          onChange={handleChange}
          error={!name}
          helperText={!name && t('forms.mandatoryField')}
        />
      )}
      <TextField
        fullWidth
        required
        type='text'
        name='phone'
        label={t('forms.mobilePhone')}
        value={phone}
        onChange={handleChange}
        error={!phone}
        helperText={!phone && t('forms.mandatoryField')}
        disabled={userRole === 'limitedcustomer'}
      />
      <TextField
        fullWidth
        required
        type='email'
        name='email'
        label={t('forms.email')}
        value={email}
        onChange={handleChange}
        error={!email}
        helperText={!email && t('forms.mandatoryField')}
        disabled={userRole === 'limitedcustomer'}
      />

      {userRole !== 'limitedcustomer' && (
        <LoadingButton
          variant='contained'
          type='submit'
          title={t('forms.saveChangesButton')}
          disabled={
            !name || !phone || !email || !userDataChanged ? true : false
          }
          loading={isLoading}
          sx={{
            marginTop: 3,
          }}
        >
          {t('forms.saveChangesButton')}
        </LoadingButton>
      )}
    </Box>
  );
};

export default PersonalInfo;

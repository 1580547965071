import { Accordion, Box, Button, TextField } from '@mui/material';
import { Shadows, createTheme, styled } from '@mui/material/styles';
import { FONT_STYLE, THEME_COLORS } from './variables';

export const theme = createTheme({
  shadows: Array(25).fill('none') as Shadows,

  shape: {
    borderRadius: 8
  },

  palette: {
    primary: {
      main: '#000',
      dark: THEME_COLORS.primary
    },
    secondary: {
      main: '#fff',
      dark: '#fff'
    },
    text: {
      primary: '#000'
    },
    error: {
      main: THEME_COLORS.red
    },
    danger: {
      main: THEME_COLORS.red
    }
  },

  typography: {
    fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
    h1: {
      fontSize: '2rem',
      fontWeight: FONT_STYLE.bold,
      textTransform: 'uppercase'
    },
    h2: {
      fontSize: '1.6rem',
      fontWeight: FONT_STYLE.bold,
      textTransform: 'uppercase'
    },
    h3: {
      fontSize: '1.4rem',
      fontWeight: FONT_STYLE.bold
    },
    h4: {
      fontSize: '1rem',
      fontWeight: FONT_STYLE.bold
    },
    h5: {
      fontSize: '1rem',
      fontWeight: FONT_STYLE.semibold
    },
    overline: {
      lineHeight: 1,
      textTransform: 'none'
    },
    small: {
      fontSize: '0.9rem'
    },
    xsmall: {
      fontSize: '0.85rem'
    },
    xxsmall: {
      fontSize: '0.7rem'
    },
    link: {
      color: 'black',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    body1: {
      fontWeight: 'inherit'
    }
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: ({ breakpoints }) => ({
        html: {
          fontSize: '14px',
          [breakpoints.down(breakpoints.values.md)]: {
            fontSize: '13px'
          }
        }
      })
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
          }
        })
      }
    },
    MuiGrid2: {
      defaultProps: {
        spacing: { xs: 1, md: 2 }
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          small: 'p',
          xsmall: 'p'
        }
      },
      styleOverrides: {
        caption: ({ theme }) => ({
          color: theme.palette.text.primary
        })
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true // No more ripple, on the whole application 💣!
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'link' },
          style: {
            padding: 0,
            textDecoration: 'underline',
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }
        }
      ],
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: '0.85rem',
          lineHeight: 'inherit',
          fontWeight: FONT_STYLE.regular,
          textTransform: 'none',
          padding: `${theme.spacing(1.25)} ${theme.spacing(2.5)}`
        }),
        containedPrimary: {
          border: `1px solid transparent`,
          backgroundColor: THEME_COLORS.primary,
          '&:hover': {
            backgroundColor: THEME_COLORS.lightPrimary
          }
        },
        containedSecondary: {
          backgroundColor: THEME_COLORS.lightGray,
          color: 'black',
          '&:hover': {
            backgroundColor: THEME_COLORS.gray
            // color: 'white'
          }
        },
        outlinedPrimary: {
          borderColor: THEME_COLORS.primary,
          color: THEME_COLORS.primary,
          '&:hover': {
            borderColor: THEME_COLORS.primary,
            backgroundColor: THEME_COLORS.primary,
            color: 'white'
          }
        },
        sizeSmall: ({ theme }) => ({
          padding: `${theme.spacing(0.75)} ${theme.spacing(1.5)}`
        }),
        startIcon: {
          marginLeft: 0
        },
        endIcon: {
          marginRight: 0
        }
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: THEME_COLORS.primary
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        standard: {
          backgroundColor: THEME_COLORS.primary,
          color: '#fff'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginTop: theme.spacing(3)
        })
      }
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: THEME_COLORS.lightPrimary,
          '&.Mui-checked': {
            color: THEME_COLORS.primary
          }
        },
        track: {
          backgroundColor: THEME_COLORS.primary,
          '.Mui-checked.Mui-checked + &': {
            backgroundColor: THEME_COLORS.primary
          }
        }
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: ({ theme }) => ({
          margin: `${theme.spacing(1)} auto ${theme.spacing(5)} auto`,
          [theme.breakpoints.up(theme.breakpoints.values.md)]: {
            maxWidth: '75%',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(6)
          }
        })
      }
    },
    MuiStep: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-completed': {
            '.MuiStepLabel-label, .MuiStepIcon-root': {
              color: THEME_COLORS.primary
            }
          },
          '.Mui-active': {
            '&.MuiStepLabel-label, &.MuiStepIcon-root': {
              fontWeight: FONT_STYLE.bold,
              color: THEME_COLORS.primary
            }
          },
          '.MuiStepLabel-root': {
            '.MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
              marginTop: theme.spacing(1.5)
            }
          }
        })
      }
    },
    MuiTable: {
      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiTableCell-head': {
            fontWeight: FONT_STYLE.semibold,
            backgroundColor: THEME_COLORS.gray,
            textAlign: 'center',
            whiteSpace: 'nowrap',
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`
          },
          '.MuiTableCell-body': {
            textAlign: 'center'
          },
          '.MuiTableRow-root:last-child .MuiTableCell-root': {
            border: 0
          }
        })
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          border: `1px solid ${THEME_COLORS.gray}`,
          '&.MuiDialog-paper': {
            width: '100%',
            padding: theme.spacing(4)
          }
        }),
        unstyled: {
          border: 0,
          '.MuiTable-root .MuiTableCell-head': {
            backgroundColor: 'transparent'
          },
          '.MuiTable-root .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {}
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '.MuiFormLabel-root, .MuiOutlinedInput-root': {
            // fontSize: '1rem'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: '0 !important',
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              display: 'none'
            },
          '& input[type=number]': {
            MozAppearance: 'textfield'
          },
          '& .MuiInputBase-input': {
            height: '50px',
            padding: `${theme.spacing(2)} !important`,
            boxSizing: 'border-box',
            '&.MuiInputBase-inputSizeSmall': {
              height: '40px',
              padding: `${theme.spacing(1.5)} ${theme.spacing(2)} !important`
            },
            '&.MuiSelect-select': {
              paddingRight: `${theme.spacing(4)} !important`
            }
          }
        })
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginRight: theme.spacing(1)
        })
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: THEME_COLORS.darkGray
        }
      }
    },
    MuiSelect: {
      styleOverrides: {}
    },
    MuiPopover: {
      defaultProps: {
        disableScrollLock: true
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        // listbox: {
        //   display: 'flex',
        //   flexDirection: 'column',
        //   flexWrap: 'nowrap'
        // },
        option: {
          whiteSpace: 'initial'
        },
        popper: {
          '.MuiAutocomplete-listbox': {
            paddingTop: 0,
            paddingBottom: 0,
            '[aria-selected=true]': {
              pointerEvents: 'none'
            }
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: 0,
          borderRadius: '0 !important',
          borderBottom: '1px solid black',
          backgroundColor: 'transparent',
          '&::before': {
            display: 'none'
          },
          '&.Mui-expanded': {
            margin: 0
          },
          '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: '48px'
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: 0
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {}
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiList: {
      defaultProps: {
        disablePadding: true
      },
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiMenuItem-root': {
            fontSize: '0.95rem',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1)
          }
        })
      }
    },
    MuiStack: {
      defaultProps: {
        direction: 'row'
      },
      styleOverrides: {
        root: ({ theme }) => ({
          gap: theme.spacing(1),
          flexWrap: 'wrap',
          '.MuiChip-root': {}
        })
      }
    }
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1300,
      xl: 1536
    }
  }
});

export const MainContainer = styled(Box, { label: 'MainContainer' })(
  ({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    // overflow: 'hidden',
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5)
    }
  })
);

export const BadgeDiscount = styled(Box)(() => ({
  display: 'inline-flex',
  fontSize: 'inherit',
  fontWeight: FONT_STYLE.bold,
  color: THEME_COLORS.red
}));

export const BadgeDiscountOutlined = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  border: `1px solid ${THEME_COLORS.primary}`,
  borderRadius: theme.shape.borderRadius,
  padding: `${theme.spacing(0.2)} ${theme.spacing(1.25)}`,
  fontSize: '0.9rem',
  color: THEME_COLORS.primary
}));

export const IconButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  minWidth: 'initial',
  '.MuiButton-startIcon, .MuiButton-endIcon': {
    margin: 0
  }
}));

//  PRODUCT LIST
export const Filters = styled(Accordion)(({ theme }) => ({
  [`& .MuiAccordionSummary-root.Mui-expanded`]: {
    minHeight: '48px'
  },
  [`& .MuiAccordionSummary-content.Mui-expanded`]: {
    margin: 0
  },
  [`& .MuiAccordionDetails-root`]: {
    paddingTop: theme.spacing(2),
    [`.MuiFormGroup-root`]: {
      gap: theme.spacing(0.5)
    }
  }
}));

//  MAIN MENU
export const CategoryButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  fontSize: '0.85rem',
  color: 'white',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  borderRadius: 0,
  letterSpacing: '0.5px',
  textTransform: 'uppercase',
  '&.active::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '3px',
    backgroundColor: 'white'
  },
  [theme.breakpoints.down(theme.breakpoints.values.md)]: {
    fontSize: '0.85rem',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5)
  }
}));

export const LocaleSelector = styled(TextField)(({ theme }) => ({
  marginTop: 0,
  textTransform: 'uppercase',
  '& .MuiSelect-select.MuiSelect-outlined': {
    paddingTop: `${theme.spacing(1)} !important`,
    paddingBottom: `${theme.spacing(1)} !important`
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: THEME_COLORS.gray
  }
}));

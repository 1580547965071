import { Box, Container, styled, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getProducts } from '../api/products';
import Breadcrumbs from '../components/Breadcrumbs';
import ProductsList from '../components/ProductsList/ProductsList';
import ProductsListCategories from '../components/ProductsList/ProductsListCategories';
import ProductsListFilters from '../components/ProductsList/ProductsListFilters';
import ProductsListTitle from '../components/ProductsList/ProductsListTitle';
import ProductsListToolbar from '../components/ProductsList/ProductsListToolbar';
import { ProductsListContext } from '../contexts/ProductsListContext';
import { generateBreadcrumbsPath } from '../helpers';
import { MainContainer } from '../styles';
import { PAGES_SLUGS, SETTINGS, THEME_COLORS } from '../variables';

export const LeftSidebar = styled(Box)(({ theme, headerheight }) => ({
  position: 'sticky',
  top: `${headerheight + 40}px`,
  height: `calc(100vh - ${headerheight + 80}px)`,
  overflowY: 'auto',
  paddingRight: theme.spacing(2),
  // CHROME
  '::-webkit-scrollbar': {
    width: theme.spacing(1)
  },
  '::-webkit-scrollbar-track': {
    background: THEME_COLORS.lightGray
  },
  '::-webkit-scrollbar-thumb': {
    background: THEME_COLORS.gray
  },
  // FIREFOX
  scrollbarColor: THEME_COLORS.gray,
  scrollbarWidth: 'thin'
}));

const ProductsPage = () => {
  const {
    products,
    setProducts,
    filters,
    setFilters,
    pagination,
    setPagination,
    setIsLoading,
    getProductsTrigger,
    setGetProductsTrigger
  } = useContext(ProductsListContext);

  const navigate = useNavigate();
  const theme = useTheme();
  const TABLET_DEVICES = useMediaQuery(theme.breakpoints.down('lg'));
  const headerHeight = document.getElementsByTagName('header')[0]?.offsetHeight;

  const { categories } = useSelector((state) => state.categories);
  const { selectedClient } = useSelector((state) => state.user);

  const { collectionSlug, categorySlug, subcategorySlug } = useParams();

  const [pageTitle, setPageTitle] = useState('');
  const [breadcrumbsPath, setBreadcrumbsPath] = useState([]);

  useEffect(() => {
    const generatedPath = generateBreadcrumbsPath(categories, [
      collectionSlug,
      categorySlug,
      subcategorySlug
    ]);

    setBreadcrumbsPath(generatedPath);
    setPageTitle(generatedPath[generatedPath.length - 1].title);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionSlug, categorySlug, subcategorySlug]);

  useEffect(() => {
    setGetProductsTrigger(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient?.id]);

  useEffect(() => {
    if (!getProductsTrigger) return;
    setIsLoading(true);

    getProducts({
      searchParams: filters.active
    })
      .then(({ displayedProducts, displayedFilters, productsTotal }) => {
        //  set products & products total
        setProducts({
          list: displayedProducts,
          total: productsTotal
        });

        //  remove filters without options » sidebar filters
        //	remove 'brands' » sidebar filters
        //	IF 'categories' is present at url remove it » sidebar filters
        const filtersFiltered = displayedFilters.filter(
          (filter) =>
            filter.options &&
            filter.options.length !== 0 &&
            filter.id !== (collectionSlug && 'collection') &&
            filter.id !== (categorySlug && 'categories')
        );

        //  set filters
        setFilters((prevState) => ({
          ...prevState,
          list: filtersFiltered
        }));

        //  rounds a number UP to the nearest integer
        setPagination((prevState) => ({
          ...prevState,
          total: Math.ceil(productsTotal / pagination.itemsPerPage)
        }));

        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
        if (status === 400 || status > 401)
          navigate(PAGES_SLUGS.notFound, { replace: true });
      });

    setGetProductsTrigger(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProductsTrigger]);

  return (
    <MainContainer>
      <Container>
        <Grid container>
          {SETTINGS.defaults.productList.breadcrumbs && (
            <Grid
              xs={12}
              sx={{
                marginTop: -2,
                marginBottom: 2
              }}
            >
              <Breadcrumbs pathArray={breadcrumbsPath} />
            </Grid>
          )}
          {(SETTINGS.defaults.productList.filters ||
            SETTINGS.defaults.productList.categoriesList) &&
            !TABLET_DEVICES && (
              <Grid md={3}>
                <LeftSidebar headerheight={headerHeight}>
                  {SETTINGS.defaults.productList.filters &&
                    !!filters.list.length && <ProductsListFilters />}
                  {SETTINGS.defaults.productList.categoriesList && (
                    <ProductsListCategories />
                  )}
                </LeftSidebar>
              </Grid>
            )}
          <Grid
            xs={12}
            lg={
              SETTINGS.defaults.productList.filters ||
              SETTINGS.defaults.productList.categoriesList
                ? 8.5
                : 12
            }
            lgOffset={
              SETTINGS.defaults.productList.filters ||
              SETTINGS.defaults.productList.categoriesList
                ? 0.5
                : 0
            }
          >
            <Box sx={{ flex: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  marginBottom: { md: 3 },
                  minHeight: '40px'
                }}
              >
                <ProductsListTitle
                  title={pageTitle}
                  productsTotal={products.total}
                />
                <ProductsListToolbar />
              </Box>
              <ProductsList />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default ProductsPage;

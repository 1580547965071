import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

const SizeGuideTable = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: '170px' }}>Medidas</TableCell>
            <TableCell>#</TableCell>
            <TableCell>37</TableCell>
            <TableCell>38</TableCell>
            <TableCell>39</TableCell>
            <TableCell>40</TableCell>
            <TableCell>41</TableCell>
            <TableCell>42</TableCell>
            <TableCell>43</TableCell>
            <TableCell>44</TableCell>
            <TableCell>45</TableCell>
            <TableCell>46</TableCell>
            <TableCell>47</TableCell>
            <TableCell>48</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={`tableRow-${rowIndex}`}>
              {row.map((cell, cellIndex) => (
                <TableCell key={`tableCell-${cellIndex}`}>{cell}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SizeGuideTable;

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Chip,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VscClose } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addPromotionalCode, deletePromotionalCode } from '../../api/checkout';
import { PopupNotificationContext } from '../../contexts/PopupNotificationContext';
import { setCartState } from '../../state/cartSlice';
import { FONT_STYLE, PAGES_SLUGS } from '../../variables';

const PromotionalCode = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setNotificationProps } = useContext(PopupNotificationContext);

  const user = useSelector((state) => state.user);
  const customerId = user.selectedClient?.id || user.id;
  const { promotionalCodes } = useSelector((state) => state.cart);

  const [isLoading, setIsLoading] = useState(false);
  const [promotionalCode, setPromotionalCode] = useState(undefined);

  const handleDeletePromotionalCode = (code) => {
    deletePromotionalCode(code, customerId)
      .then(
        ({
          cart_items,
          voucher,
          discounts,
          total_discount,
          taxes,
          subtotal,
          total,
        }) => {
          dispatch(
            setCartState({
              products: cart_items,
              promotionalCodes: voucher,
              discount: total_discount,
              discounts: discounts,
              taxes: taxes,
              subtotal: subtotal,
              total: total,
            })
          );
          setNotificationProps({
            isOpened: true,
            type: 'success',
            message: t('cart.promotionalCodeRemovedMessage'),
          });
        }
      )
      .catch(({ response: { status, data } }) => {
        const errorMessage = data?.msg || data?.errors?.[0]?.msg;
        if (status === 401) {
          navigate(PAGES_SLUGS.login, { replace: true });
        } else if (status === 400 || status > 401) {
          setNotificationProps({
            isOpened: true,
            type: 'error',
            message: errorMessage,
          });
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    addPromotionalCode(promotionalCode, customerId)
      .then(
        ({
          cart_items,
          voucher,
          discounts,
          total_discount,
          taxes,
          subtotal,
          total,
        }) => {
          dispatch(
            setCartState({
              products: cart_items,
              promotionalCodes: voucher,
              discount: total_discount,
              discounts: discounts,
              taxes: taxes,
              subtotal: subtotal,
              total: total,
            })
          );
          setIsLoading(false);
          setNotificationProps({
            isOpened: true,
            type: 'success',
            message: t('cart.promotionalCodeAddedMessage'),
          });
        }
      )
      .catch(({ response: { status, data } }) => {
        const errorMessage = data?.msg || data?.errors?.[0]?.msg;
        if (status === 401) {
          navigate(PAGES_SLUGS.login, { replace: true });
        } else if (status === 400 || status > 401) {
          setIsLoading(false);
          setNotificationProps({
            isOpened: true,
            type: 'error',
            message: errorMessage,
          });
        }
      });
  };

  return !!promotionalCodes.length ? (
    <Box>
      <Typography fontWeight={FONT_STYLE.semibold}>
        {t('cart.promotionalCodeActive')}
      </Typography>
      <Stack marginTop={1}>
        {promotionalCodes.map((code) => (
          <Chip
            key={`promotionalCode-${code}`}
            label={code}
            variant='outlined'
            deleteIcon={<VscClose />}
            onDelete={() => handleDeletePromotionalCode(code)}
          />
        ))}
      </Stack>
    </Box>
  ) : (
    <Box
      component='form'
      onSubmit={handleSubmit}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <TextField
        name='promotionalCode'
        label={t('cart.promotionalCode')}
        type='text'
        onChange={({ target: { value } }) => setPromotionalCode(value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <LoadingButton
                type='submit'
                title={t('cart.apply')}
                loading={isLoading}
                disabled={!!!promotionalCode}
                sx={{
                  padding: 1,
                  fontWeight: FONT_STYLE.bold,
                }}
              >
                {t('cart.apply')}
              </LoadingButton>
            </InputAdornment>
          ),
        }}
        sx={{
          marginTop: 0,
        }}
      />
    </Box>
  );
};

export default PromotionalCode;

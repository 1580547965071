import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { FOOTER_MENUS } from '../../variables';
import { FooterLink, FooterTitles } from './Footer';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

const CustomerSupport = () => {
  const { t } = useTranslation();
  const [cookies] = useCookies(['userToken']);
  const userIsLogged = !!cookies.userToken;

  return (
    <Grid item xs={3}>
      <FooterTitles variant='h4'>{t('app.quickAccess')}</FooterTitles>
      {FOOTER_MENUS.menu3
        .filter((menu) => !(menu.loginRequired && !userIsLogged))
        .map((menuItem, index) => (
          <FooterLink
            key={`footer-menu-${menuItem.title}-${index}`}
            component={menuItem.internalLink ? Link : 'a'}
            to={menuItem.internalLink ? menuItem.url : null}
            href={!menuItem.internalLink ? menuItem.url : null}
            target={!menuItem.internalLink ? menuItem.target : null}
            title={t(menuItem.title)}
            variant='link'
            marginBottom={0.75}
          >
            {t(menuItem.title)}
          </FooterLink>
        ))}
    </Grid>
  );
};

export default CustomerSupport;

import { Box, styled } from '@mui/material';
import { THEME_COLORS } from '../variables';

const Badge = styled(Box)(({ theme, variant }) => ({
  fontSize: '0.85rem',
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(1.25),
  paddingRight: theme.spacing(1.25),
  backgroundColor:
    variant === 'stock'
      ? THEME_COLORS.gray
      : variant === 'new'
        ? THEME_COLORS.green
        : THEME_COLORS.red,
  borderRadius: theme.shape.borderRadius
}));

const ProductBadge = ({ sx, children, variant }) => {
  return (
    <Badge {...sx} variant={variant}>
      {children}
    </Badge>
  );
};

export default ProductBadge;

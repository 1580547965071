import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        margin: '20vh auto',
        textAlign: 'center',
      }}
    >
      <Typography
        variant='h2'
        sx={{
          fontSize: '7rem',
          marginBottom: 2,
        }}
      >
        404
      </Typography>
      <Typography
        variant='h1'
        sx={{
          fontSize: '1.5rem',
          marginBottom: 4,
        }}
      >
        {t('app.pageNotFound')}
      </Typography>
      <Button
        component={Link}
        to='/'
        title={t('app.continueShoppingButton')}
        variant='contained'
      >
        {t('app.continueShoppingButton')}
      </Button>
    </Box>
  );
};

export default NotFoundPage;

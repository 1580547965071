import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteAddress } from '../../../api/user';
import { PopupNotificationContext } from '../../../contexts/PopupNotificationContext';
import { setAddresses } from '../../../state/userSlice';
import { PAGES_SLUGS } from '../../../variables';

const AddressesList = ({
  addresses,
  setUpdateAddressModalIsOpen,
  setUpdateAddressModalData,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setNotificationProps } = useContext(PopupNotificationContext);

  const [isLoading, setIsLoading] = useState(
    addresses.reduce((acc, elm) => {
      acc[elm.id] = false;
      return acc;
    }, {})
  );

  const deleteExistingAddress = (id) => {
    setIsLoading((prev) => ({ ...prev, [id]: true }));

    deleteAddress(id)
      .then((addresses) => {
        setIsLoading((prev) => ({ ...prev, [id]: false }));
        dispatch(setAddresses(addresses));
        setNotificationProps({
          isOpened: true,
          type: 'success',
          message: t('myAccount.addresses.successMessage3'),
        });
      })
      .catch(({ response: { status } }) => {
        setIsLoading((prev) => ({ ...prev, [id]: false }));
        if (status === 401) {
          navigate(PAGES_SLUGS.login, { replace: true });
        } else if (status > 401) {
          setNotificationProps({
            isOpened: true,
            type: 'error',
            message: t('app.generalErrorMessage'),
          });
        }
      });
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)',
        },
        gap: { xs: 2, sm: 3 },
      }}
    >
      {addresses.map((address) => (
        <Card
          key={address.id}
          variant='outlined'
          sx={{
            display: 'grid',
            alignContent: 'space-between',
          }}
        >
          <CardContent
            sx={{
              paddingBottom: 1,
            }}
          >
            <Typography
              color='text.secondary'
              lineHeight={1.75}
              dangerouslySetInnerHTML={{
                __html: `${address.street} <br> ${address.zipCode} ${address.city} <br> ${address.country}`,
              }}
            />
          </CardContent>
          <CardActions>
            <Button
              size='small'
              title={t('myAccount.addresses.updateAddress')}
              onClick={() => {
                setUpdateAddressModalData(address);
                setUpdateAddressModalIsOpen(true);
              }}
              startIcon={<FiEdit size={14} />}
            >
              {t('myAccount.addresses.updateAddress')}
            </Button>
            <LoadingButton
              size='small'
              title={t('myAccount.addresses.removeAddress')}
              loading={isLoading[address.id]}
              onClick={() => deleteExistingAddress(address.id)}
              startIcon={<FiTrash2 size={14} />}
            >
              {t('myAccount.addresses.removeAddress')}
            </LoadingButton>
          </CardActions>
        </Card>
      ))}
    </Box>
  );
};

export default AddressesList;

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  styled
} from '@mui/material';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FONT_STYLE, SETTINGS } from '../../../variables';
import { TranslationsState } from '../../../state/translationsSlice';

export type TechnicalDetail = {
  id: string;
  title: string;
  url: string;
  value: string;
};

const TitleCell = styled(TableCell)(({ theme }: { theme: Theme }) => ({
  width: '30%',
  fontWeight: FONT_STYLE.bold,
  textAlign: 'left !important' as 'left',
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  borderBottom: 0
}));

const ContentCell = styled(TableCell)(({ theme }: { theme: Theme }) => ({
  textAlign: 'left !important' as 'left',
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  borderBottom: 0
}));

const TechnicalDetails = ({ data }) => {
  const { t } = useTranslation();
  const [cookie] = useCookies();
  const translations: TranslationsState[] = useSelector(
    (state: any) => state.translations
  );
  const isTranslatable = false;

  if (SETTINGS.functionalities.translations && isTranslatable) {
    //  translate 'title & value' params
    data = data.map((param: TechnicalDetail) =>
      translations.reduce(
        (acc: TechnicalDetail, translation: TranslationsState) => {
          translation.id === param.id &&
            (acc.title = translation[cookie.language]);
          translation.id === param.value &&
            (acc.value = translation[cookie.language]);
          return { ...param, ...acc };
        },
        {}
      )
    );
  }

  return (
    <Table>
      <TableBody>
        {data.length !== 0 ? (
          data.map(({ title, value }, index) => (
            <TableRow key={`technicalDetail-${index}`}>
              <TitleCell>{title}</TitleCell>
              <ContentCell>{value}</ContentCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <ContentCell>
              {t('product.noTechnicalDetailsAvailable')}
            </ContentCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default TechnicalDetails;

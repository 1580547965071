import {
  Collapse as CollapseMui,
  List,
  ListItemButton,
  ListItem as ListItemMui,
  styled
} from '@mui/material';
import { useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { FONT_STYLE, THEME_COLORS } from '../../variables';

const Collapse = styled(CollapseMui)(() => ({
  width: '100%'
}));

const ListItem = styled(ListItemMui)(({ theme }) => ({
  padding: 0,
  flexDirection: 'column',
  '& .MuiCollapse-root': {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2.5),
    borderLeft: `2px solid ${THEME_COLORS.gray}`
  }
}));

const ListLink = styled(ListItemButton)(({ theme }) => ({
  fontSize: '0.9rem',
  fontWeight: FONT_STYLE.regular,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  '&.active': {
    fontWeight: FONT_STYLE.bold,
    textDecoration: 'none !important'
  },
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline'
  }
}));

const ProductsListCategories = () => {
  const collections = useSelector((state) => state.categories.categories);
  const { collectionSlug } = useParams();

  const [isCategoryMenuOpened, setIsCategoryMenuOpened] = useState({});
  const [isSubcategoryMenuOpened, setIsSubcategoryMenuOpened] = useState({});

  useEffect(() => {
    if (!!collectionSlug) {
      const indexOfCollectionSlug = collections.findIndex(
        (collection) => collection.url === collectionSlug
      );
      setIsCategoryMenuOpened({
        [indexOfCollectionSlug]: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionSlug]);

  const SubcategoriesMenu = ({ subcategories, index }) => (
    <Collapse in={isSubcategoryMenuOpened[index]} timeout='auto' unmountOnExit>
      <List>
        {subcategories.map(({ title, url }) => (
          <ListItem key={`menu-subcategories-${title}`}>
            <ListLink component={NavLink} to={url} title={title}>
              {title}
            </ListLink>
          </ListItem>
        ))}
      </List>
    </Collapse>
  );

  const CategoriesMenu = ({ categories, index }) => (
    <Collapse in={isCategoryMenuOpened[index]} timeout='auto' unmountOnExit>
      <List>
        {categories.map(({ title, url, subcategories }, subcategoriesIndex) => (
          <ListItem key={`menu-categories-${title}`}>
            <ListLink
              component={NavLink}
              to={url}
              title={title}
              onClick={() =>
                !!subcategories.length &&
                setIsSubcategoryMenuOpened({
                  [subcategoriesIndex]:
                    !isSubcategoryMenuOpened[subcategoriesIndex]
                })
              }
            >
              {title}
              {!!subcategories.length &&
                (isSubcategoryMenuOpened[subcategoriesIndex] ? (
                  <FiChevronUp size={17} />
                ) : (
                  <FiChevronDown size={17} />
                ))}
            </ListLink>
            {!!subcategories.length && (
              <SubcategoriesMenu
                subcategories={subcategories}
                index={subcategoriesIndex}
              />
            )}
          </ListItem>
        ))}
      </List>
    </Collapse>
  );

  return (
    <Collapse in={true}>
      <List>
        {collections.map(({ title, url, categories }, categoriesIndex) => (
          <ListItem key={`menu-collections-${title}`}>
            <ListLink
              component={NavLink}
              to={`/${url}`}
              title={title}
              onClick={() =>
                !!categories.length &&
                setIsCategoryMenuOpened({
                  [categoriesIndex]: !isCategoryMenuOpened[categoriesIndex]
                })
              }
              sx={{
                fontWeight: FONT_STYLE.semibold
              }}
            >
              {title}
              {!!categories.length &&
                (isCategoryMenuOpened[categoriesIndex] ? (
                  <FiChevronUp size={17} />
                ) : (
                  <FiChevronDown size={17} />
                ))}
            </ListLink>
            {!!categories.length && (
              <CategoriesMenu categories={categories} index={categoriesIndex} />
            )}
          </ListItem>
        ))}
      </List>
    </Collapse>
  );
};

export default ProductsListCategories;

import { Dispatch, SetStateAction, createContext, useState } from 'react';
import PopupNotification from '../components/PopupNotification/PopupNotification';

type NotificationContext = {
  setNotificationProps: Dispatch<SetStateAction<NotificationProps>>;
};

type NotificationProps = {
  isOpened: boolean;
  type: string;
  message: string;
};

export const PopupNotificationContext =
  createContext<NotificationContext | null>(null);

export const PopupNotificationProvider = ({ children }) => {
  const [notificationProps, setNotificationProps] = useState<NotificationProps>(
    {
      isOpened: false,
      type: '',
      message: ''
    }
  );

  return (
    <PopupNotificationContext.Provider
      value={{
        setNotificationProps
      }}
    >
      {children}
      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </PopupNotificationContext.Provider>
  );
};

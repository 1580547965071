import styled from '@emotion/styled/macro';
import { Box, Container, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  THEME_COLORS,
  FONT_STYLE,
  TRANSITION_DURATION,
} from '../../variables';

const Image = styled(Box)(() => ({
  maxWidth: '70%',
  transform: 'scale(1)',
  transition: `transform ${TRANSITION_DURATION}ms ease`,
  willChange: 'transform',
}));

const Item = styled(Link)(() => ({
  aspectRatio: '1',
  '&:hover': {
    [Title]: {
      color: THEME_COLORS.primary,
    },
    [Image]: {
      transform: 'scale(1.05)',
    },
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  lineHeight: '1.2',
  fontWeight: FONT_STYLE.semibold,
  textTransform: 'uppercase',
  textAlign: 'center',
  marginTop: theme.spacing(3),
  transition: `color ${TRANSITION_DURATION}ms ease`,
  willChange: 'color',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.15rem',
    lineHeight: '1.25',
  },
}));

const ImageContainer = styled(Box)(() => ({
  backgroundColor: THEME_COLORS.gray,
  borderRadius: '100%',
  aspectRatio: '1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const HighlightedCategories = ({ categories, language }) => {
  const { t } = useTranslation();
  return (
    <Container sx={{ paddingY: { xs: 3, md: 5 } }}>
      <Typography
        variant='h2'
        sx={{
          fontSize: '1.7rem',
          textAlign: 'center',
        }}
      >
        {t('homepage.highlightedCategories.title')}
      </Typography>
      <Grid2
        container
        spacing={4}
        sx={{ marginTop: 4 }}
      >
        {categories.map(({ title, image, url }, index) => (
          <Grid2
            key={`highlightedCategory-${index}`}
            xs={6}
            sm={4}
            md={2}
          >
            <Item
              to={url}
              title={title[language]}
            >
              <ImageContainer>
                <Image
                  component='img'
                  src={image}
                  width='100%'
                  alt={title[language]}
                />
              </ImageContainer>
              <Title>{title[language]}</Title>
            </Item>
          </Grid2>
        ))}
      </Grid2>
    </Container>
  );
};

export default HighlightedCategories;

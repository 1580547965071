import { useEffect, useState } from 'react';
import { priceCalculations } from '../../../constants/utils';
import { SETTINGS } from '../../../variables';
import QuantitiesQuickAdd from '../../QuantitiesQuickAdd';
import ProductVariantsTable from './ProductVariantsTable';

const ProductVariants = ({
  variants,
  userIsLogged,
  updatedProductQuantity,
  setUpdatedProductQuantity,
  productsForCart,
  setProductsForCart
}) => {
  const [subtotalVariants, setSubtotalVariants] = useState({});

  useEffect(() => {
    if (updatedProductQuantity.length === 0) return;

    updatedProductQuantity.map((updatedChildProduct) => {
      const productIdentifier = updatedChildProduct.parentId
        ? 'parentId'
        : 'reference';
      const productAlreadyExists =
        productsForCart?.[updatedChildProduct[productIdentifier]];
      const updatedProductReference = updatedChildProduct[productIdentifier];

      //  if product exists
      if (productAlreadyExists) {
        const variantAlreadyExists = productAlreadyExists.products.find(
          (product) =>
            SETTINGS.defaults.productIdentifier === 'reference'
              ? product.reference === updatedChildProduct.reference
              : !!product.eanUn
                ? product.eanUn === updatedChildProduct.eanUn
                : product.eanCx === updatedChildProduct.eanCx
        );

        //  IF variant already exists
        if (variantAlreadyExists) {
          if (
            updatedChildProduct.quantity.unit === 0 &&
            updatedChildProduct.quantity.box === 0
          ) {
            //  DELETE whole object IF quantities (unit/box) === 0 AND it's the only product inside array
            const allVariantsWillHaveQuantityZero =
              productAlreadyExists.products
                .filter(
                  (variant) =>
                    variant.reference !== updatedChildProduct.reference
                )
                .every((variant) => variant.quantity.unit === 0);

            if (allVariantsWillHaveQuantityZero) {
              setProductsForCart((prevState) => {
                const { [updatedChildProduct[productIdentifier]]: _, ...rest } =
                  prevState;
                return rest;
              });
            } else {
              //  DELETE only object updated
              setProductsForCart((prevState) => ({
                ...prevState,
                [updatedProductReference]: {
                  ...prevState[updatedProductReference],
                  products: [
                    ...prevState[updatedProductReference].products.map(
                      (product) => ({
                        ...product,
                        quantity:
                          product.reference === updatedChildProduct.reference
                            ? updatedChildProduct.quantity
                            : product.quantity
                      })
                    )
                  ]
                }
              }));
            }
          } else {
            //  UPDATE products array quantities
            setProductsForCart((prevState) => ({
              ...prevState,
              [updatedProductReference]: {
                ...prevState[updatedProductReference],
                products: [
                  ...prevState[updatedProductReference].products.reduce(
                    (acc, product) => {
                      //  product with same 'product identifier'
                      (
                        SETTINGS.defaults.productIdentifier === 'reference'
                          ? product.reference === updatedChildProduct.reference
                          : !!product.eanUn
                            ? product.eanUn === updatedChildProduct.eanUn
                            : product.eanCx === updatedChildProduct.eanCx
                      )
                        ? //  IF product has quantities !== 0
                          (updatedChildProduct.quantity.unit !== 0 ||
                            updatedChildProduct.quantity.box !== 0) &&
                          acc.push({
                            ...product,
                            quantity: updatedChildProduct.quantity
                          })
                        : acc.push(product);
                      return acc;
                    },
                    []
                  )
                ]
              }
            }));
          }
        } else {
          //  ADD new 'EAN' product to array
          setProductsForCart((prevState) => ({
            ...prevState,
            [updatedProductReference]: {
              ...prevState[updatedProductReference],
              products: [
                ...prevState[updatedProductReference].products,
                { ...updatedChildProduct, isVariant: true }
              ]
            }
          }));
        }
      } else {
        const allVariantsFromReference = variants.find(
          (a) => a.parentId === updatedChildProduct.parentId
        );

        //  add new product reference object to base objects
        setProductsForCart((prevState) => ({
          ...prevState,
          [updatedProductReference]: {
            //  add all variants from reference including variants without quantities
            products: allVariantsFromReference.variants.map((variant) =>
              variant.reference === updatedChildProduct.reference
                ? { ...updatedChildProduct, isVariant: true }
                : { ...variant, isVariant: true }
            ),
            stockOriginal: updatedChildProduct.stock,
            stockAvailable: updatedChildProduct.stock,
            subtotal: {
              unit: 0,
              box: 0
            },
            totalUnits: 0,
            total: 0
          }
        }));
      }

      //  stockAvailable/totalUnits/totalBoxes/subtotal calculations
      setProductsForCart((prevState) => {
        const updatedProduct = prevState?.[updatedProductReference];

        //  IF product does not exist » RETURN
        if (!updatedProduct) return prevState;

        const unitProducts = updatedProduct.products.filter(
          ({ sellsUnit }) => sellsUnit
        );
        const boxProducts = updatedProduct.products.filter(
          ({ sellsBox }) => sellsBox
        );

        return {
          ...prevState,
          [updatedProductReference]: {
            ...updatedProduct,
            stockAvailable: updatedProduct.products.reduce(
              (acc, { sellsUnit, quantity, itemsPerBox }) =>
                sellsUnit
                  ? acc - quantity.unit
                  : acc - quantity.box * itemsPerBox,
              updatedProduct.stockOriginal
            ),
            totalUnits: updatedProduct.products.reduce(
              (acc, product) => acc + product.quantity.unit,
              0
            ),
            totalBoxes: updatedProduct.products.reduce(
              (acc, product) => acc + product.quantity.box,
              0
            ),
            subtotal: {
              unit: !!unitProducts.length
                ? unitProducts.reduce(
                    (acc, product) =>
                      priceCalculations(
                        acc +
                          (product.onSale ? product.salePrice : product.price) *
                            product.quantity.unit
                      ),
                    0
                  )
                : 0,
              box: !!boxProducts.length
                ? boxProducts.reduce(
                    (acc, product) =>
                      priceCalculations(
                        acc +
                          (product.onSale ? product.salePrice : product.price) *
                            product.quantity.unit
                      ),
                    0
                  )
                : 0
            }
          }
        };
      });

      //  total prices calculations
      setProductsForCart((prevState) => {
        //  IF product does not exist » RETURN
        if (!prevState?.[updatedProductReference]) return prevState;

        return {
          ...prevState,
          [updatedProductReference]: {
            ...prevState[updatedProductReference],
            total: priceCalculations(
              prevState[updatedProductReference].subtotal.unit +
                prevState[updatedProductReference].subtotal.box
            )
          }
        };
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedProductQuantity]);

  useEffect(() => {
    setSubtotalVariants(
      Object.entries(productsForCart).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value.total }),
        {}
      )
    );
  }, [productsForCart]);

  return (
    <>
      {SETTINGS.functionalities.variantsTable ? (
        <ProductVariantsTable
          variants={variants}
          userIsLogged={userIsLogged}
          setUpdatedProductQuantity={setUpdatedProductQuantity}
          subtotalVariants={subtotalVariants}
        />
      ) : (
        <QuantitiesQuickAdd
          variants={variants}
          setUpdatedProductQuantity={setUpdatedProductQuantity}
          subtotalUnit={
            productsForCart?.[variants[0].reference]?.subtotal?.unit || 0
          }
          subtotalBox={
            productsForCart?.[variants[0].reference]?.subtotal?.box || 0
          }
          stockAvailable={
            productsForCart?.[variants[0].reference]?.stockAvailable
          }
        />
      )}
    </>
  );
};

export default ProductVariants;

import styled from '@emotion/styled/macro';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BsDownload } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../../../constants/utils';
import {
  FONT_STYLE,
  PAGES_SLUGS,
  SETTINGS,
  THEME_COLORS
} from '../../../variables';
import { HighlightedSpecs as TechnicalSheetDownload } from '../../ProductListCard/ProductListCard';

const ResumeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: THEME_COLORS.lightGray,
  borderRadius: theme.shape.borderRadius
}));

const ProductResume = ({
  _userIsLogged,
  data: { totalUnits, totalBoxes, total },
  hasUnit = true,
  hasBox = false,
  outOfStock,
  technicalSheet
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <ResumeContainer
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          gap: 1,
          padding: 2,
          backgroundColor: THEME_COLORS.lightGray,
          borderRadius: theme.shape.borderRadius / 10
        }}
      >
        <Typography
          sx={{
            fontSize: '1.15rem',
            fontWeight: FONT_STYLE.bold,
            textTransform: 'uppercase'
          }}
        >
          {t('product.resume')}
        </Typography>

        {hasUnit && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1.5
            }}
          >
            <Typography variant='xsmall'>{t('product.totalUnits')}</Typography>
            <Typography
              component='span'
              sx={{
                fontSize: '1.4rem',
                fontWeight: FONT_STYLE.bold,
                color: THEME_COLORS.primary
              }}
            >
              {totalUnits}
            </Typography>
          </Box>
        )}
        {hasBox && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1.5
            }}
          >
            <Typography variant='xsmall'>{t('product.totalBoxes')}</Typography>
            <Typography
              component='span'
              sx={{
                fontSize: '1.4rem',
                fontWeight: FONT_STYLE.bold,
                color: THEME_COLORS.primary
              }}
            >
              {totalBoxes}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}
        >
          <Typography variant='xsmall'>{t('product.total')}*</Typography>
          <Typography
            component='span'
            sx={{
              fontSize: '1.5rem',
              fontWeight: FONT_STYLE.bold,
              color: THEME_COLORS.primary
            }}
          >
            {formatCurrency(total)}
          </Typography>
        </Box>

        <Typography variant='xxsmall'>
          {t('product.priceWithoutVat')}
        </Typography>

        {SETTINGS.defaults.productDetail.budgetRequestButton && (
          <Typography variant='xsmall' marginTop={!outOfStock && 1}>
            Gostaria de efetuar um pedido de orçamento para grandes quantidades?
            &nbsp;
            <Typography
              component={Link}
              to={PAGES_SLUGS.budgetRequest}
              title={t('pages.budgetRequest')}
              sx={{
                fontSize: 'inherit',
                color: THEME_COLORS.primary,
                fontWeight: FONT_STYLE.bold
              }}
            >
              Clique Aqui
            </Typography>
          </Typography>
        )}
      </ResumeContainer>

      {!!technicalSheet.length && (
        <Box marginTop={3}>
          <Typography variant='xxsmall'>
            Para mais informações sobre o produto, por favor consulte a ficha
            técnica.
          </Typography>
          <TechnicalSheetDownload
            component='a'
            href={technicalSheet}
            title={t('product.technicalSheet')}
            download
            target='_blank'
            sx={{
              marginTop: 2
            }}
          >
            <BsDownload size={16} /> {t('product.technicalSheet')}
          </TechnicalSheetDownload>
        </Box>
      )}
    </>
  );
};

export default ProductResume;

import { Box, MenuItem, Select, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { SETTINGS } from '../../variables';

const ProductsSortBy = ({ options }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const sortByParam = searchParams.get('sortBy');

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography
        variant='small'
        sx={{
          fontWeight: '700',
        }}
      >
        {t('productList.orderBy')}
      </Typography>
      <Select
        size='small'
        value={sortByParam || SETTINGS.defaults.productList.sortBy}
        onChange={({ target: { value } }) => {
          searchParams.set('sortBy', value);
          setSearchParams(searchParams);
        }}
        sx={{
          fontSize: '0.9rem',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
      >
        {options.map((type) => (
          <MenuItem
            key={`orderBy-option-${type.id}`}
            value={type.id}
            variant='small'
          >
            {t(type.text)}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default ProductsSortBy;

import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiFilter } from 'react-icons/fi';
import { IconButton } from '../../styles';
import { FONT_STYLE, SETTINGS } from '../../variables';
import Modal, { ModalContent } from '../Modal';
import ProductsListCategories from './ProductsListCategories';
import ProductsListFilters from './ProductsListFilters';

const ProductsFiltersModal = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Box>
      <IconButton
        startIcon={<FiFilter size={18} />}
        onClick={() => setIsModalOpen(true)}
        title={t('productList.filter')}
      >
        <Typography
          variant='small'
          sx={{
            fontWeight: FONT_STYLE.bold,
            marginLeft: 1
          }}
        >
          {t('productList.filter')}
        </Typography>
      </IconButton>

      <Modal
        isOpened={isModalOpen}
        setIsOpened={setIsModalOpen}
        muiComponentNativeProps={{
          PaperProps: {
            sx: {
              maxWidth: '440px'
            }
          }
        }}
      >
        <ModalContent>
          {SETTINGS.defaults.productList.filters && <ProductsListFilters />}
          {SETTINGS.defaults.productList.categoriesList && (
            <ProductsListCategories />
          )}
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ProductsFiltersModal;

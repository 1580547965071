import {
  Autocomplete,
  Box,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import { Link, createSearchParams, useNavigate } from 'react-router-dom';
import { getSearchedProducts } from '../../api/products';
import { FONT_STYLE, PAGES_SLUGS, THEME_COLORS } from '../../variables';
import { imageOnError } from '../../helpers';

const SearchBar = () => {
  const SEARCH_ID = 'search';
  const navigate = useNavigate();
  const theme = useTheme();

  const { t } = useTranslation();

  const [dropdownProducts, setDropdownProducts] = useState([]);
  const [shownProducts, setShownProducts] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const getOptionsDebounced = debounce(async (queryParam) => {
    await getSearchedProducts({
      searchParams: { query: queryParam, limit: 5 }
    }).then(({ products }) => setDropdownProducts(products));
  }, 500);

  useEffect(() => {
    const mappedProducts = dropdownProducts.map(
      ({ _id, reference, slug, title, images }) => ({
        id: _id,
        reference: reference,
        slug: slug,
        label: title,
        image: images?.[0]
      })
    );
    setShownProducts(mappedProducts);
  }, [dropdownProducts]);

  const handleInputChange = (e, newInputValue, reason) => {
    if (reason === 'reset') {
      setDropdownProducts([]);
      setInputValue('');
      return;
    }

    setInputValue(newInputValue);

    if (!newInputValue) {
      setDropdownProducts([]);
      return;
    }

    getOptionsDebounced(newInputValue);
  };

  const navigateToSearchPage = (id) => {
    if (id === SEARCH_ID && inputValue) {
      navigate({
        pathname: PAGES_SLUGS.search,
        search: createSearchParams({
          query: inputValue
        }).toString()
      });
    }
    setDropdownProducts([]);
    setInputValue('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') navigateToSearchPage(SEARCH_ID);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxWidth: { xs: '100%', md: '350px', lg: '400px' },
        marginTop: { xs: 1.5, md: 0 }
      }}
    >
      <Autocomplete
        disableClearable
        freeSolo
        fullWidth
        options={shownProducts}
        filterOptions={(options) => options}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('search.label')}
            onKeyPress={handleKeyPress}
            sx={{ color: 'black', marginTop: 0 }}
          />
        )}
        renderOption={(params, { id, reference, slug, label, image }) => (
          <MenuItem
            {...params}
            key={reference}
            component={Link}
            to={slug}
            title={label}
            sx={{
              gap: 2,
              width: '100%',
              fontWeight: id === SEARCH_ID ? 500 : 400
            }}
          >
            <Box
              component='img'
              src={image}
              alt={label}
              onError={(e) => {
                imageOnError(e);
              }}
              sx={{
                aspectRatio: '1',
                objectFit: 'contain',
                width: '48px',
                padding: theme.spacing(0.5),
                backgroundColor: 'white',
                borderRadius: theme.shape.borderRadius / 10,
                border: `1px solid ${THEME_COLORS.gray}`
              }}
            />
            <Box>
              <Typography fontWeight={FONT_STYLE.semibold}>{label}</Typography>
              <Typography
                variant='xsmall'
                sx={{ color: THEME_COLORS.darkGray }}
              >{`Ref: ${reference}`}</Typography>
            </Box>
          </MenuItem>
        )}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: THEME_COLORS.gray
          }
        }}
      />
      <IconButton
        onClick={() => navigateToSearchPage(SEARCH_ID)}
        title={t('search.button')}
        sx={{
          position: 'absolute',
          right: theme.spacing(1),
          color: 'black'
        }}
      >
        <FiSearch size={22} />
      </IconButton>
    </Box>
  );
};

export default SearchBar;

import { Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiShoppingCart } from 'react-icons/fi';
import Modal, { ModalActions, ModalContent, ModalTitle } from './Modal';
import ProductVariants from './ProductPage/ProductVariants/ProductVariants';

const ProductVariantsModal = ({
  isVariationsModalOpen,
  setIsVariationsModalOpen,
  productsForCart,
  setProductsForCart,
  productVariants,
  addToCart,
}) => {
  const { t } = useTranslation();
  const { reference } = productVariants[0];

  return (
    <Modal
      isOpened={isVariationsModalOpen}
      setIsOpened={setIsVariationsModalOpen}
      muiComponentNativeProps={{
        onClose: () => {
          setIsVariationsModalOpen(false);
          //  reset products
          setProductsForCart({});
        },
        PaperProps: {
          sx: {
            maxWidth: '440px',
          },
        },
      }}
    >
      <ModalTitle>{t('productList.quickOrder')}</ModalTitle>
      <ModalContent
        sx={{
          display: 'grid',
          gap: 1,
          marginBottom: 0,
          '& .MuiTable-root': {
            width: 'initial',
            '& .MuiTableCell-root': {
              textAlign: 'left',
              '&:first-of-type': {
                paddingLeft: 0,
              },
            },
          },
        }}
      >
        <ProductVariants
          variants={productVariants}
          productsForCart={productsForCart}
          setProductsForCart={setProductsForCart}
        />
        <Typography variant='xxsmall'>
          {t('product.priceWithoutVat')}
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          variant='contained'
          title={t('cart.addToCart')}
          onClick={() => addToCart(productsForCart[reference].products)}
          disabled={!productsForCart?.[reference]}
          sx={{
            width: '100%',
            maxWidth: '200px',
            gap: 1,
          }}
        >
          <FiShoppingCart size={16} />
          {t('cart.addToCartShort')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default React.memo(ProductVariantsModal);

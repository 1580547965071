import styled from '@emotion/styled/macro';
import { Box, Container, Typography, useTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Link } from 'react-router-dom';
import { FONT_STYLE, TRANSITION_DURATION } from '../../variables';

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  lineHeight: '1.2',
  fontWeight: FONT_STYLE.bold,
  color: 'white',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.6rem',
    lineHeight: '1.25',
  },
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  fontWeight: FONT_STYLE.regular,
  color: 'white',
  marginTop: theme.spacing(1.5),
}));

const Image = styled(Box)(() => ({
  aspectRatio: '16/9',
  objectFit: 'cover',
  filter: 'brightness(80%)',
  transition: `filter ${TRANSITION_DURATION}ms ease`,
}));

const SecondaryBannerBlock = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    [Image]: {
      filter: 'brightness(85%)',
    },
  },
}));

const SecondaryBanner = ({ data, language }) => {
  const theme = useTheme();

  return (
    <Container sx={{ paddingY: { xs: 3, md: 5 } }}>
      <Grid2
        container
        spacing={3}
      >
        {data.map(({ title, subtitle, image, url }, index) => (
          <Grid2
            key={`secundary-banner-${index}`}
            xs={12}
            sm={6}
          >
            <SecondaryBannerBlock
              component={Link}
              to={url}
              title={title[language]}
            >
              <Box
                sx={{
                  position: 'absolute',
                  left: theme.spacing(4),
                  maxWidth: '50%',
                  zIndex: 1,
                }}
              >
                <Title>{title[language]}</Title>
                <Subtitle>{subtitle[language]}</Subtitle>
              </Box>
              <Image
                component='img'
                src={image}
                width='100%'
                alt={title[language]}
              />
            </SecondaryBannerBlock>
          </Grid2>
        ))}
      </Grid2>
    </Container>
  );
};

export default SecondaryBanner;

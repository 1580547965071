import { Box, Container, Typography, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { BsWhatsapp } from 'react-icons/bs';
import { FiBox } from 'react-icons/fi';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { RiTruckLine } from 'react-icons/ri';
import { FONT_STYLE, THEME_COLORS } from '../../variables';

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: FONT_STYLE.bold,
  [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
    fontSize: '1.15rem'
  }
}));

const Description = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
    fontSize: '0.9rem'
  }
}));

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  color: THEME_COLORS.primary,
  marginBottom: theme.spacing(2)
}));

const DealsSection = () => {
  const { t } = useTranslation();

  const data = [
    {
      icon: <RiTruckLine size={32} />,
      title: t('homepage.dealsSection.quickShipping.title'),
      subtitle: t('homepage.dealsSection.quickShipping.subTitle')
    },
    {
      icon: <FiBox size={32} />,
      title: t('homepage.dealsSection.freeShipping.title'),
      subtitle: t('homepage.dealsSection.freeShipping.subTitle')
    },
    {
      icon: <HiOutlineUserGroup size={32} />,
      title: t('homepage.dealsSection.experiencedTeam.title'),
      subtitle: t('homepage.dealsSection.experiencedTeam.subTitle')
    },
    {
      icon: <BsWhatsapp size={30} />,
      title: t('homepage.dealsSection.customerSupport.title'),
      subtitle: t('homepage.dealsSection.customerSupport.subTitle')
    }
  ];
  return (
    <Box
      sx={{
        marginTop: 5,
        marginBottom: { xs: -4, md: -6 },
        // backgroundColor: THEME_COLORS.lightGray
      }}
    >
      <Container
        sx={{
          paddingY: { xs: 4, md: 6 }
        }}
      >
        <Grid container spacing={{ xs: 3, md: 4 }}>
          {data.map((section, index) => (
            <Grid key={`dealsSection-${index}`} xs={12} sm={6} md={3}>
              <Box
                sx={{
                  textAlign: 'center'
                }}
              >
                <IconContainer>{section.icon}</IconContainer>
                <Title>{section.title}</Title>
                <Description>
                  {data.length - 1 !== index ? (
                    section.subtitle
                  ) : (
                    //  last child
                    <Typography
                      component={'a'}
                      href={`https://api.whatsapp.com/send?phone=351255729900`}
                      title={section.subtitle}
                      target='_blank'
                      sx={{
                        color: 'black',
                        textDecoration: 'none'
                      }}
                    >
                      {section.subtitle}
                    </Typography>
                  )}
                </Description>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default DealsSection;

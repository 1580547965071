import { Box, Dialog, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { VscClose } from 'react-icons/vsc';
import { IconButton } from '../styles';
import { THEME_COLORS, FONT_STYLE } from '../variables';

export const ModalContainer = styled(Dialog)(() => ({
  '.MuiPaper-root:not(.MuiTableContainer-root)': {
    border: 'none',
  },
}));

export const ModalTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  fontWeight: FONT_STYLE.bold,
  textTransform: 'uppercase',
  marginBottom: theme.spacing(1.5),
}));

export const ModalContent = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
}));

export const ModalActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  paddingTop: theme.spacing(1.5),
}));

export const ModalClose = styled(IconButton)(() => ({
  width: '44px',
  height: '40px',
  position: 'absolute',
  top: 0,
  right: 0,
  padding: 0,
  borderRadius: '0 10px 0 70%',
  backgroundColor: THEME_COLORS.lightGray,
  '&:hover': {
    backgroundColor: THEME_COLORS.gray,
  },
}));

const Modal = (props) => {
  const { t } = useTranslation();
  const {
    children,
    isOpened = false,
    setIsOpened,
    hideCloseButton = false,
    muiComponentNativeProps,
  } = props;
  return (
    <ModalContainer
      open={isOpened}
      onClose={() => setIsOpened(false)}
      {...muiComponentNativeProps}
    >
      {!hideCloseButton && (
        <ModalClose
          title={t('app.closeButton')}
          onClick={() => setIsOpened(false)}
          startIcon={<VscClose size={22} />}
        />
      )}
      {children}
    </ModalContainer>
  );
};

export default React.memo(Modal);

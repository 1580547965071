import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BsDownload } from 'react-icons/bs';
import { FONT_STYLE } from '../../../variables';

const ContentCell = styled(TableCell)(({ theme }: { theme: Theme }) => ({
  textAlign: 'left !important' as 'left',
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  borderBottom: 0
}));

const TechnicalSheets = ({ data }: { data: string[] }) => {
  const { t } = useTranslation();

  return (
    <Table>
      <TableBody>
        {data.length !== 0 ? (
          <>
            <TableRow>
              <ContentCell>{t('product.technicalSheetsText')}</ContentCell>
            </TableRow>
            <TableRow>
              <ContentCell
                sx={{
                  display: 'grid',
                  justifyItems: 'flex-start',
                  gap: 1.5
                }}
              >
                {data.map((sheetUrl: string, index: number) => (
                  <Button
                    key={`technicalSheet-${index}`}
                    component='a'
                    href={sheetUrl}
                    title={`${t('product.technicalSheetsBtn')} ${index + 1}`}
                    download
                    target='_blank'
                    variant='contained'
                    color='secondary'
                    sx={{
                      fontWeight: FONT_STYLE.semibold,
                      gap: 1
                    }}
                  >
                    <BsDownload size={16} />{' '}
                    {`${t('product.technicalSheetsBtn')} ${index + 1}`}
                  </Button>
                ))}
              </ContentCell>
            </TableRow>
          </>
        ) : (
          <TableRow>
            <ContentCell>{t('product.noTechnicalSheetsAvailable')}</ContentCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default TechnicalSheets;

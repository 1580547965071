import { Alert, Snackbar } from '@mui/material';

const PopupNotification = ({
  props,
  setNotificationProps,
  notificationProps: { isOpened = false, type = '', message = '' },
  autoHideDuration = 7500
}) => {
  const handleClose = (reason) => {
    if (reason === 'clickaway') return;
    setNotificationProps({ isOpened: false });
  };

  return (
    <Snackbar
      {...props}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isOpened}
      onClose={handleClose}
      sx={{
        '& .MuiAlert-message': {
          display: 'flex',
          alignItems: 'center'
        }
      }}
    >
      {
        {
          success: (
            <Alert severity='success' onClose={handleClose}>
              {message}
            </Alert>
          ),
          warning: (
            <Alert severity='warning' onClose={handleClose}>
              {message}
            </Alert>
          ),
          error: (
            <Alert severity='error' onClose={handleClose}>
              {message}
            </Alert>
          )
        }[type]
      }
    </Snackbar>
  );
};

export default PopupNotification;

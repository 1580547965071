import axios from 'axios';
import { API_URL, SETTINGS } from '../variables';
import { getHeaders } from './login';

//  GET

export function getPage(name) {
  return axios
    .get(
      SETTINGS.development.sampleData.homepage && name === 'homepage'
        ? `${process.env.PUBLIC_URL}/sample-content/homepage.json`
        : `${API_URL}/pages/${name}`,
      getHeaders()
    )
    .then(({ data: { page } }) => page);
}

export function getCategories() {
  return axios
    .get(
      SETTINGS.development.sampleData.collections
        ? `${process.env.PUBLIC_URL}/sample-content/collections-tree.json`
        : `${API_URL}/categories`,
      getHeaders()
    )
    .then(({ data: { collections } }) => collections);
}

export function getSizeGuides() {
  return axios
    .get(`${process.env.PUBLIC_URL}/size-guide-tables.json`, getHeaders())
    .then(({ data }) => data);
}

export function getBrands(id) {
  return axios
    .get(id ? `${API_URL}/brands/${id}` : `${API_URL}/brands`, getHeaders())
    .then(({ data: { brands } }) => brands.filter((brand) => brand.status));
}

// POST

export function sendContactForm(requestData) {
  return axios
    .post(`${API_URL}/forms/contact`, requestData, getHeaders())
    .then(({ data }) => data);
}

export function sendBudgetRequest(requestData) {
  return axios
    .post(`${API_URL}/quotes/create`, requestData)
    .then(({ data }) => data);
}

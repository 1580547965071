import { AppBar, Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { SETTINGS, THEME_COLORS } from '../../variables';
import CompanyLogo from '../CompanyLogo';
import NotificationBar from '../NotificationBar/NotificationBar';
import AccountLinks from './AccountLinks/AccountLinks';
import ClientSelector from './ClientSelector';
import NavBar from './NavBar/NavBar';
import SearchBar from './SearchBar';

const Header = () => {
  const theme = useTheme();
  const MOBILE_DEVICES = useMediaQuery(theme.breakpoints.down('md'));

  const { role } = useSelector((state) => state.user);

  return (
    <>
      {SETTINGS.functionalities.notificationsBar && <NotificationBar />}
      <AppBar
        sx={{
          position: 'sticky',
          backgroundColor: 'white',
          border: 'none',
          borderBottom: {
            xs: `3px solid ${THEME_COLORS.primary}`,
            md: 'none'
          }
        }}
      >
        {role && role === 'seller' && <ClientSelector />}
        <Container>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
              paddingY: 1.5
            }}
          >
            <CompanyLogo />
            {MOBILE_DEVICES ? (
              <>
                <AccountLinks />
                <SearchBar />
              </>
            ) : (
              <>
                <SearchBar />
                <AccountLinks />
              </>
            )}
          </Box>
        </Container>
        {!MOBILE_DEVICES && <NavBar />}
      </AppBar>
    </>
  );
};

export default Header;

import { useState } from 'react';
import { GrCircleInformation } from 'react-icons/gr';
import { IconButton } from '../../styles';
import { THEME_COLORS } from '../../variables';
import Modal, { ModalContent } from '../Modal';

const TaxProductButton = ({ t }) => {
  const [modalIsOpened, setModalIsOpened] = useState(false);
  return (
    <>
      <IconButton
        startIcon={<GrCircleInformation size={20} />}
        onClick={() => setModalIsOpened(true)}
        title={t('app.informations')}
        sx={{
          marginLeft: 1,
          color: THEME_COLORS.red
        }}
      />
      <Modal
        isOpened={modalIsOpened}
        setIsOpened={() => setModalIsOpened(false)}
        muiComponentNativeProps={{
          PaperProps: {
            sx: {
              maxWidth: '500px'
            }
          }
        }}
      >
        <ModalContent>{t('product.taxProductDescription')}</ModalContent>
      </Modal>
    </>
  );
};

export default TaxProductButton;

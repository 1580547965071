import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { SwiperSlide } from 'swiper/react';
import { FONT_STYLE, SETTINGS } from '../../variables';
import Slider from '../Slider/Slider';

type Banner = {
  title: { [key: string]: string };
  description: { [key: string]: string };
  buttonTitle: { [key: string]: string };
  buttonUrl: string;
  image: { desktop: string; mobile: string };
};

const MainBanner = ({
  data,
  language
}: {
  data: Banner[];
  language: string;
}) => {
  const theme = useTheme();
  const DESKTOP_DEVICES = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Slider
      loop
      sx={{
        marginBottom: { xs: 3, md: 5 }
      }}
    >
      {data.map((banner, index) => {
        const { title, description, buttonTitle, buttonUrl, image } = banner;
        return (
          <SwiperSlide key={`home-slidebanner-${index}`}>
            <Card
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                border: 'none',
                borderRadius: 0,
                boxShadow: 'none'
              }}
            >
              {(image.desktop || image.mobile) && (
                <CardMedia
                  component='img'
                  image={
                    DESKTOP_DEVICES
                      ? SETTINGS.development.sampleData.homepage
                        ? `${process.env.PUBLIC_URL}/sample-content/${image.desktop}`
                        : image.desktop
                      : SETTINGS.development.sampleData.homepage
                        ? `${process.env.PUBLIC_URL}/sample-content/${image.mobile}`
                        : image.mobile
                  }
                  sx={{
                    filter: 'brightness(80%)',
                    height: { xs: '350px', md: '500px' }
                  }}
                />
              )}

              <Container
                sx={{
                  position: 'absolute',
                  left: '0',
                  right: '0',
                  paddingLeft: { md: 7, xl: 3 },
                  paddingRight: { md: 7, xl: 3 }
                }}
              >
                <Box>
                  {title[language] && (
                    <Typography
                      sx={{
                        fontSize: { xs: '1.5rem', md: '2rem' },
                        lineHeight: '1.15',
                        fontWeight: FONT_STYLE.bold,
                        color: 'white'
                      }}
                    >
                      {title[language]}
                    </Typography>
                  )}

                  {description[language] && (
                    <Typography
                      sx={{
                        fontSize: { xs: '0.9rem', md: '1rem' },
                        color: '#fff',
                        marginTop: 1.5
                      }}
                    >
                      {description[language]}
                    </Typography>
                  )}

                  {buttonTitle[language] && (
                    <Button
                      component='a'
                      href={buttonUrl}
                      title={buttonTitle[language]}
                      size='small'
                      sx={{
                        color: '#fff',
                        border: '2px solid #fff',
                        paddingX: 2.5,
                        marginTop: 3.5
                      }}
                    >
                      {buttonTitle[language]}
                    </Button>
                  )}
                </Box>
              </Container>
            </Card>
          </SwiperSlide>
        );
      })}
    </Slider>
  );
};

export default MainBanner;
